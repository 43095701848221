import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { Link, Tickets } from '../models';

import { AngularFireDatabase } from '@angular/fire/database';
import { BehaviorSubject } from 'rxjs';
import { ImagesService } from '../images.service';
import { LanguageService } from '../language.service';
import { Router } from '@angular/router';
import { interval } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { timer } from 'rxjs';

@Component({
  selector: 'app-tickets',
  templateUrl: './tickets.component.html',
  styleUrls: ['./tickets.component.css']
})
export class TicketsComponent implements OnInit, AfterViewInit {

  @Input() editingMode: boolean;
  @Input() editablePage;
  @Input() uiChanges: BehaviorSubject<boolean>;

  tickets: Tickets;
  loading = true;
  headerLoading = true;

  rulesAdded = 0;

  constructor(public languageService: LanguageService, private imagesService: ImagesService,
    private db: AngularFireDatabase, private router: Router) { }

  ngOnInit() {
    if (this.editablePage != null) {
      this.tickets = this.editablePage as Tickets;
      // this.tickets.links = this.tickets.links.filter(link => !link.hidden);
      this.loading = false;
    } else {
      const promisse = this.db.object('pages/tickets').valueChanges();
      promisse.subscribe((raw) => {
        this.tickets = raw as Tickets;
        this.tickets.links = this.tickets.links.filter(link => !link.hidden);
        this.loading = false;
      });
    }
  }

  ngAfterViewInit() {
    interval(100)
      .pipe(takeWhile(() => this.headerLoading))
      .subscribe(i => {
        if (this.imagesService.loading || this.loading) { return; }
        this.imagesService.loadHeader(this.imagesService.imagesResource[this.tickets.img_1.src]);
        this.headerLoading = false;
      });
    if (this.editingMode) {
      this.uiChanges.subscribe((changes) => {
        if (changes && this.editingMode) {
          this.imagesService.loadHeader(this.imagesService.imagesResource[this.tickets.img_1.src]);
          this.tickets = this.editablePage as Tickets;
          this.rulesAdded = 0;
        }
      });
    }
  }

  buttonClass(link: Link, index: number) {
    if (link == null || index == null) { return; }
    if (this.imagesService.imagesResource === undefined) {
      timer(1000).subscribe(() => {
        this.buttonClass(link, index);
      });
      return;
    }
    const sheet = window.document.styleSheets[0] as CSSStyleSheet;
    if (this.rulesAdded === this.tickets.links.length) {
      return link.style + ' ticketsButton' + index;
    }
    if (this.editingMode) {
      if (sheet.cssRules) {
        for (let i = 0; i < sheet.cssRules.length; i++) {
          if (sheet.cssRules[i].cssText.includes('.ticketsButton' + index + '')) {
            sheet.deleteRule(i);
          }
        }
      } else {
        for (let i = 0; i < sheet.rules.length; i++) {
          if ((sheet.rules[i] as any).includes('.ticketsButton' + index + '')) {
            sheet.removeRule(i);
          }
        }
      }
    }
    if (sheet.cssRules) {
      // eslint-disable-next-line max-len
      sheet.insertRule('.ticketsButton' + index + ':before { background: url(\'' + this.imagesService.imagesResource[link.image.src] + '\'); background-repeat: no-repeat; background-position: 50% 0; -ms-background-size: cover; -o-background-size: cover; -moz-background-size: cover; -webkit-background-size: cover; background-size: cover; }');
    } else {
      // eslint-disable-next-line max-len
      sheet.addRule('.ticketsButton' + index + ':before { background: url(\'' + this.imagesService.imagesResource[link.image.src] + '\'); background-repeat: no-repeat; background-position: 50% 0; -ms-background-size: cover; -o-background-size: cover; -moz-background-size: cover; -webkit-background-size: cover; background-size: cover; }');
    }
    this.rulesAdded = this.rulesAdded + 1;
    return link.style + ' ticketsButton' + index;
  }

  navigate(path: string) {
    if (path != null) {
      if (path.indexOf('http') > -1) {
        window.location.href = path;
      } else {
        this.router.navigateByUrl('/' + path);
      }
    }
  }
}
