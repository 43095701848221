<div
  *ngIf="!loading && !languageService.loading"
  [class.editingPadding]="editingMode"
>
  <div
    class="section header headerImage"
    [appEditableImage]="editingMode"
    [editRoute]="'img_1'"
    [image]="tickets.img_1"
  ></div>
  <div class="section p20 hAuto txtCenter">
    <!-- <div [ngClass]="tickets.title.style" class="underline" [appEditableText]="editingMode" [element]="tickets.title" [editRoute]="'title'">
            <span>{{languageService.stringsResource[tickets.title.content]}}</span>
        </div> -->
    <br />
    <button
      *ngFor="let link of tickets.links; let i = index"
      [ngClass]="buttonClass(link, i)"
      (click)="!editingMode && navigate(link.url)"
      [appEditableButton]="editingMode"
      [link]="link"
      [editRoute]="'links.' + i"
    >
      <span
        >{{ languageService.stringsResource[link.content] }}
        <div style="font-size: 0.5em; font-weight: 100;">-BUY TICKETS-</div>
      </span>
    </button>
  </div>
</div>
