<app-loading *ngIf="headerLoading || gallery == null"></app-loading>
<div *ngIf="!headerLoading && !languageService.loading && gallery != null" [class.editingPadding]="editingMode" style="overflow:hidden;">
  <div class="section header headerImage" [appScrollableHeader]="true" [appEditableImage]="editingMode" [image]="gallery.img_1"
    [editRoute]="'img_1'" [position]="'translate(-50%, -35vh)'">
  </div>
  <div class="white" [class.active]="selectedImage != null">
    <div class="p20 txtCenter">
      <div class="title1 underline">PHOTOS 2017</div>
    </div>
    <div *ngFor="let subGallery of gallery.galleries; let pIndex = index;">
        <div class="p20 sbt">
            <div class="title2">{{subGallery.title}}</div>
          </div>
        <div class="galleries">
          <div *ngFor="let gal of subGallery.galleries; let i = index;" (click)="navigate(gal.link)" [id]="'subGal'+pIndex+'-'+i">
            <div class="galBG" [style.background-image]="subGalleryImage(gal)"></div>
            <div class="galTitle">{{gal.title}}</div>
          </div>
        </div>
    </div>
  </div>
</div>
