import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'filter', pure: false })
export class FilterPipe implements PipeTransform {
    transform(data: ({ [_: string]: any } | string)[], query: string, fields?: string[]): any[] {
        if (data === undefined) {
            return [];
        }
        if (query === undefined) {
            return data;
        }
        query = query.toLocaleLowerCase();
        return data.filter(value => {
          if (typeof value === 'string') {
            return value.toLocaleLowerCase().indexOf(query) > -1;
          } else {
            if (!fields) {
                fields = Object.keys(value);
            }
            return fields.map(field => {
                const attribute = value[field];
                if (attribute) {
                    if (typeof attribute === 'string') {
                        return attribute.toLocaleLowerCase().indexOf(query) > -1;
                    } else {
                        return JSON.stringify(attribute).toLocaleLowerCase().indexOf(query) > -1;
                    }
                }
            }).reduce((prev, current) => prev || current, false);
          }
        });
    }
}
