<!-- Conversion Pixel - MFW_CNV - DO NOT MODIFY -->
<img src="https://secure.adnxs.com/px?id=1112584&t=2" width="1" height="1" />
<!-- End of Conversion Pixel -->
<!--
Start of Floodlight Tag: Please do not remove
Activity name of this tag: MFS_RTG_Image
URL of the webpage where the tag is expected to be placed: 
This tag must be placed between the <body> and </body> tags, as close as possible to the opening tag.
Creation Date: 05/06/2019
-->
<script type="text/javascript">
  var axel = Math.random() + "";
  var a = axel * 10000000000000;
  document.write(
    '<img src="https://ad.doubleclick.net/ddm/activity/src=9374656;type=invmedia;cat=mfs_r00;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;ord=' +
    a + '?" width="1" height="1" alt=""/>');

</script>
<noscript>
  <img
    src="https://ad.doubleclick.net/ddm/activity/src=9374656;type=invmedia;cat=mfs_r00;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;ord=1?"
    width="1" height="1" alt="" />
</noscript>
<!-- End of Floodlight Tag: Please do not remove -->
<app-loading *ngIf="loading || imagesLoading"></app-loading>
<div *ngIf="!loading && !imagesLoading">
  <div class="section bgImg" [appEditableImage]="editingMode" [editRoute]="'img_1'" [image]="thanku.img_1"
    [position]="'translate(-50%, -1000%)'">
  </div>
  <div class="box1">
    <div class="title1" [innerHTML]="languageService.stringsResource[thanku.title.content]"
      [appEditableText]="editingMode" [editRoute]="'title'" [element]="thanku.title" [negative]="true">
    </div>
    <div class="division"></div>
    <div class="text-center title3" [innerHTML]="languageService.stringsResource[thanku.body.content]"
      [appEditableText]="editingMode" [editRoute]="'body'" [element]="thanku.body" [negative]="true">
    </div>
  </div>
</div>
