import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { LanguageService } from '../language.service';
import { ImagesService } from '../images.service';
import { BehaviorSubject } from 'rxjs';
import { interval } from 'rxjs';
import { takeWhile } from 'rxjs/operators';

@Component({
  selector: 'app-thank-you',
  templateUrl: './thank-you.component.html',
  styleUrls: ['./thank-you.component.scss'],
})
export class ThankYouComponent implements OnInit, AfterViewInit {
  @Input() editingMode: boolean;
  @Input() editablePage;
  @Input() uiChanges: BehaviorSubject<boolean>;

  thanku: any;
  loading = true;
  imagesLoading = true;

  constructor(private db: AngularFireDatabase, public languageService: LanguageService, public imagesService: ImagesService) {}

  ngOnInit() {
    if (this.editablePage != null) {
      this.thanku = this.editablePage;
    } else {
      this.db
        .object('/components/thanku')
        .valueChanges()
        .subscribe(nf => {
          if (nf != null) {
            this.thanku = nf;
            this.loading = false;
          }
        });
    }
  }

  ngAfterViewInit() {
    interval(100)
      .pipe(takeWhile(() => this.imagesLoading))
      .subscribe(i => {
        if (this.imagesService.loading || this.loading) {
          return;
        }
        this.loadImages();
        this.imagesLoading = false;
      });
    if (this.editingMode) {
      this.uiChanges.subscribe(changes => {
        if (changes && this.editingMode) {
          this.loadImages();
        }
      });
    }
  }

  loadImages() {
    const sheet = window.document.styleSheets[0] as CSSStyleSheet;
    if (sheet.cssRules) {
      for (let i = 0; i < sheet.cssRules.length; i++) {
        if (sheet.cssRules[i].cssText.includes('.bgImg')) {
          sheet.deleteRule(i);
        }
      }
    } else {
      for (let i = 0; i < sheet.rules.length; i++) {
        if ((sheet.rules[i] as any).includes('.bgImg')) {
          sheet.removeRule(i);
        }
      }
    }
    if (sheet.cssRules) {
      // eslint-disable-next-line max-len
      sheet.insertRule(
        '.bgImg { background: url(\'' +
          this.imagesService.imagesResource[this.thanku.img_1.src] +
          // eslint-disable-next-line max-len
          '\') no-repeat center center fixed; -webkit-background-size: cover; -moz-background-size: cover; -o-background-size: cover; background-size: cover; }'
      );
    } else {
      // eslint-disable-next-line max-len
      sheet.addRule(
        '.bgImg { background: url(\'' +
          this.imagesService.imagesResource[this.thanku.img_1.src] +
          // eslint-disable-next-line max-len
          '\') no-repeat center center fixed; -webkit-background-size: cover; -moz-background-size: cover; -o-background-size: cover; background-size: cover; }'
      );
    }
  }
}
