<app-loading *ngIf="loading"></app-loading>
<div
  *ngIf="!loading && !hide"
  [class.editingNav]="editingMode"
  [class.editingPadding]="editingMode"
>
  <div
    style="    position: fixed;
  z-index: 1000;
  width: 100%;"
  >
    <div class="navbar" [class.absolute]="absolute">
      <div id="lang" [hidden]="editingMode">
        <p (click)="changeLang('es')">ES</p>
        |
        <p (click)="changeLang('en')">EN</p>
      </div>
      <img
        *ngIf="!white"
        (click)="router.navigateByUrl('/')"
        src="./assets/MIAFW-Logo-white-.png"
        alt=""
      />
      <img
        *ngIf="white"
        (click)="router.navigateByUrl('/')"
        src="./assets/MIAFW-Logo-black-.png"
        alt=""
      />
      <nav id="primary_nav_wrap">
        <ul>
          <li *ngFor="let nav of navContent.navs">
            <a (click)="navigate(nav.url)" class="clickable">{{
              languageService.stringsResource[nav.title]
            }}</a>
            <ul *ngIf="nav.menu != null">
              <li *ngFor="let inner of nav.menu">
                <a (click)="navigate(inner.url)" class="clickable">{{
                  languageService.stringsResource[inner.title]
                }}</a>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</div>
