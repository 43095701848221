<app-loading *ngIf="loading || imagesLoading"></app-loading>
<div *ngIf="!loading && !imagesLoading" [class.editingPadding]="editingMode">
  <div class="section bgImg header2 headerImage" [appEditableImage]="editingMode" [editRoute]="'img_1'"
    [image]="tvcontent.img_1" [position]="'translate(-50%, -1400%)'">
  </div>
  <div class="main-title" [ngClass]="tvcontent.title.style">{{languageService.stringsResource[tvcontent.title.content]}}
  </div>
  <div class="session-wrapper">
    <div class="steps-guide" [class.selectItem]="0 === selectedIndex" [class.unselectItem]="0 !== selectedIndex">
      <div class="tvShow imgG" *ngFor="let sli of tvcontent.slider; let i = index " [ngClass]="'img'+i+'bg'"
        [appEditableImage]="editingMode" [editRoute]="'slider.'+i" [image]="sli">
        <div class="cont">
          <div class="bgimgd txt2">{{languageService.stringsResource[sli.body.content]}}</div>
        </div>
      </div>
    </div>
    <div class="steps-guide" [class.selectItem]="1 === selectedIndex" [class.unselectItem]="1 !== selectedIndex">
      <div class="tvShow imgG" *ngFor="let sli of tvcontent.slider2; let i = index " [ngClass]="'img2'+i+'bg'"
        [appEditableImage]="editingMode" [editRoute]="'slider.'+i" [image]="sli">
        <div class="cont">
          <div class="bgimgd txt2">{{languageService.stringsResource[sli.body.content]}}</div>
        </div>
      </div>
    </div>
    <div class="steps-action">
      <div class="navigator" [class.selectNavigator]=" 0 === selectedIndex" (click)="goIndexNavigator(0)">
      </div>
      <div class="navigator" [class.selectNavigator]=" 1 === selectedIndex" (click)="goIndexNavigator(1)">
      </div>
    </div>
  </div>
</div>
