<app-loading *ngIf="headerLoading || gallery == null"></app-loading>
<div *ngIf="!headerLoading && !languageService.loading && gallery != null" [class.editingPadding]="editingMode" style="overflow:hidden;">
  <div
    class="section header headerImage"
    [appScrollableHeader]="true"
    [appEditableImage]="editingMode"
    [image]="gallery.img_1"
    [editRoute]="'img_1'"
    [position]="'translate(-50%, -35vh)'"
  >
  </div>
  <div class="white" [class.active]="selectedVideo != null">
    <div class="p20 txtCenter">
      <div
        [ngClass]="gallery.title.style"
        class="underline"
        [appEditableText]="editingMode"
        [element]="gallery.title"
        [editRoute]="'title'"
        >{{ languageService.stringsResource[gallery.title.content] }}</div
      >
      <!-- <button class="addPlaylist" *ngIf="editingMode && editor != null" (click)="playlists()">CONFIGURE PLAYLIST</button> -->
    </div>
    <div
      class="galleries"
      [appEditableVideoGallery]="editingMode"
      [editRoute]="'/galleries/' + (gallery.galleries == null ? 0 : gallery.galleries.length)"
      [component]="self"
      [galleries]="gallery.galleries == null ? 0 : gallery.galleries.length"
    >
      <div *ngFor="let gal of gallery.galleries; let i = index" (click)="navigateGallery(gal, '/galleries/' + i)" [id]="'subGal' + i">
        <div class="galBG" [style.background-image]="subGalleryImage(gal)"></div>
        <div class="galTitle">{{ languageService.stringsResource[gal.title.content] }}</div>
      </div>
    </div>
    <div class="txtCenter">
      <!-- <div [ngClass]="gallery.subtitle.style" [appEditableText]="editingMode" [element]="gallery.subtitle" [editRoute]="'subtitle'">{{languageService.stringsResource[gallery.subtitle.content]}}</div> -->
      <!-- <div *ngIf="editingMode" class="addImages" [class.disabled]="currentUpload" (click)="(currentUpload == null) && uploadImages()">UPLOAD VIDEOS</div> -->
      <input name="newImgs" (change)="detectFiles($event)" style="display:none;" type="file" id="newImgs" multiple />
      <!-- *ngIf="editingMode && currentUpload" -->
      <div *ngIf="editingMode && currentUpload != null" class="progress" [ngStyle]="{ width: currentUpload?.progress + '%' }"></div>
      <div *ngIf="editingMode && currentUpload != null" class="progress-text"> {{ (currentUpload?.progress | number: '1.0-0') + '%' }}</div>
    </div>
    <div class="gallery">
      <!-- <img *ngFor="let video of gallery.videos; let i = index" class="clickable" (click)="!editingMode && openVideo(i)" [src]="imagesService.imagesResource[image.src]"
            alt="" [appEditableImage]="editingMode" [image]="image" [editRoute]="'images.'+i" [deletable]="true"> -->
      <!-- <video *ngFor="let video of gallery.videos; let i = index" class="clickable" (click)="!editingMode && openVideo(i)" [src]="video.url"
        [appEditableVideo]="editingMode" [video]="video" [editRoute]="'videos.'+i"></video> -->
      <!-- video bc -->
      <div *ngFor="let video of gallery.videos; let i = index">
        <video
          [attr.data-video-id]="video.id"
          data-account="2339105022001"
          data-player="default"
          data-embed="default"
          data-application-id
          class="video-js"
          controls
        ></video>
        <div class="titContainer">
          <div
            [ngClass]="video.title.style"
            [appEditableText]="editingMode"
            [editRoute]="'videos.' + i + '.title'"
            [element]="video.title"
            style="font-family: 'Century Gothic', sans-serif;
            font-size: 1.5em;
            text-transform: uppercase;"
            >{{
              languageService.stringsResource[video.title.content]
                ? languageService.stringsResource[video.title.content]
                : video.title.content
            }}</div
          >
          
        </div>
        <!-- <script src="//players.brightcove.net/2339105022001/default_default/index.min.js"></script> -->
      </div>
      <app-script-hack
        async
        src="//players.brightcove.net/2339105022001/default_default/index.min.js"
        type="text/javascript"
      ></app-script-hack>
      <!-- end video bc -->
    </div>
    <div class="bigImage" [class.active]="selectedImage != null">
      <button class="close clickable" (click)="closeImage()">X</button>
      <div class="arrow left"></div>
      <div class="leftClick clickable" (click)="previousImage()"></div>
      <img *ngIf="selectedImage != null" [src]="selectedImage" alt="" />
      <div class="arrow right"></div>
      <div class="rightClick clickable" (click)="nextImage()"></div>
    </div>
  </div>
</div>
