<div class="section bgA bgImg" [appEditableImage]="editingMode" [editRoute]="'img_1'" [image]="about.img_1"
  [position]="'translate(-50%, -1400%)'">
</div>

<div class="bgImg flex-container">
  <div class="cont-1">
    <div class="txt title1">Summit</div>
    <div class="division"></div>
    <div class="smalltext txt1"></div>
  </div>
  <div id="eventbrite-widget-container-59696550991" class="cont-2"></div>
</div>
