import { Injectable } from '@angular/core';

@Injectable()
export class PasswordService {

  private _pattern = /[a-zA-Z0-9_\-\+\.\!\@\*\#\$\%]/;

  constructor() { }

  private _getRandomByte() {
    if (window.crypto && window.crypto.getRandomValues) {
      const result = new Uint8Array(1);
      window.crypto.getRandomValues(result);
      return result[0];
    } else if (window.crypto && window.crypto.getRandomValues) {
      const result = new Uint8Array(1);
      window.crypto.getRandomValues(result);
      return result[0];
    } else {
      return Math.floor(Math.random() * 256);
    }
  }

  public generate(length: number): string {
    return Array.apply(null, { 'length': length })
      .map(function () {
        let result;
        while (true) {
          result = String.fromCharCode(this._getRandomByte());
          if (this._pattern.test(result)) {
            return result;
          }
        }
      }, this)
      .join('');
  }
}
