<div>
  <link rel="stylesheet" href="https://use.typekit.net/kxr5yzh.css" />

  <app-sidebar></app-sidebar>
  <div class="video-space" [class.-selected]="videoSpace">
    <div class="action-space">
      <button class="btn-icon -big" (click)="videoSpace = false">
        <img src="/assets/Landing/Close.svg" alt="" />
      </button>
    </div>
    <iframe
      *ngIf="videoSpace"
      [src]="currentVideoSpace | safe"
      frameborder="0"
      allow="autoplay; fullscreen"
      allowfullscreen
    ></iframe>
  </div>
  <div class="body-wrapper">
    <div class="events-carrousel-space">
      <div class="prev-video-wrapper">
        <iframe
          class="prev-video"
          [src]="events[currentVideo].video | safe"
          frameborder="0"
          allow="autoplay; fullscreen"
          allowfullscreen
        ></iframe>
      </div>
      <div class="event-title">
        <h1 class="-regular">More than a week</h1>
        <p class="-subtitle">{{ events[currentVideo].name }}</p>
        <button
          class="btn-1"
          *ngIf="events[currentVideo].link !== ''"
          (click)="navigate(events[currentVideo].link)"
        >
          {{ events[currentVideo].linkText }}
          <div class="icon-space">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 103.48 104.29">
              <g id="Layer_2" data-name="Layer 2">
                <g id="Layer_1-2" data-name="Layer 1">
                  <polygon
                    points="72.48 0 0.48 0 0.48 31 50.66 31 0 81.66 22.63 104.28 72.48 54.43 72.48 103 103.48 103 103.48 0 72.48 0"
                  />
                </g>
              </g>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 103.48 104.29">
              <g id="Layer_2" data-name="Layer 2">
                <g id="Layer_1-2" data-name="Layer 1">
                  <polygon
                    points="72.48 0 0.48 0 0.48 31 50.66 31 0 81.66 22.63 104.28 72.48 54.43 72.48 103 103.48 103 103.48 0 72.48 0"
                  />
                </g>
              </g>
            </svg>
          </div>
        </button>
      </div>
      <div class="events-carrousel-wrapper">
        <div class="controls">
          <button
            class="btn-icon"
            (click)="carrouselChangedByUser = true; scrollLeft()"
          >
            <img src="/assets/Landing/Greater-Than.svg" alt="" />
          </button>
          <button
            class="btn-icon"
            (click)="carrouselChangedByUser = true; scrollRight()"
          >
            <img src="/assets/Landing/Less-Than.svg" alt="" />
          </button>
        </div>
        <div #carrouselWrapper id="events-carrousel">
          <div
            class="event-wrapper"
            *ngFor="let event of events; let i = index"
            (click)="currentVideo = i; resetVideoInterval()"
          >
            <div class="event" [class.-selected]="i === currentVideo">
              <div class="title">
                <h2 class="-regular">{{ event.name }}</h2>
                <p *ngIf="event.dates !== ''">{{ event.dates }}</p>
              </div>
              <button
                *ngIf="event.link !== ''"
                class="btn-1"
                [class.-black]="i === currentVideo"
                (click)="navigate(event.link)"
              >
                {{ event.linkText }}
                <div class="icon-space">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 103.48 104.29"
                  >
                    <g id="Layer_2" data-name="Layer 2">
                      <g id="Layer_1-2" data-name="Layer 1">
                        <polygon
                          points="72.48 0 0.48 0 0.48 31 50.66 31 0 81.66 22.63 104.28 72.48 54.43 72.48 103 103.48 103 103.48 0 72.48 0"
                        />
                      </g>
                    </g>
                  </svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 103.48 104.29"
                  >
                    <g id="Layer_2" data-name="Layer 2">
                      <g id="Layer_1-2" data-name="Layer 1">
                        <polygon
                          points="72.48 0 0.48 0 0.48 31 50.66 31 0 81.66 22.63 104.28 72.48 54.43 72.48 103 103.48 103 103.48 0 72.48 0"
                        />
                      </g>
                    </g>
                  </svg>
                </div>
              </button>
            </div>
            <div class="event-progress"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="events-recap-space">
      <h1 class="-regular">Relive the events</h1>
      <div class="events-banner">
        <div class="events-list">
          <p class="-subtitle">Influencers Brunch</p>
          <p class="-subtitle">Closing Party</p>
          <p class="-subtitle">Benefit Gala</p>
          <p class="-subtitle">MIAFW @ Art Week Miami</p>
          <p class="-subtitle">MIAFW Summit</p>
          <p class="-subtitle">Designers Dinner</p>
          <p class="-subtitle">Kick-off Party</p>
          <p class="-subtitle">Press Conference</p>
          <p class="-subtitle">100 Hours Nonstop</p>
        </div>
        <div class="events-list">
          <p class="-subtitle">Influencers Brunch</p>
          <p class="-subtitle">Closing Party</p>
          <p class="-subtitle">Benefit Gala</p>
          <p class="-subtitle">MIAFW @ Art Week Miami</p>
          <p class="-subtitle">MIAFW Summit</p>
          <p class="-subtitle">Designers Dinner</p>
          <p class="-subtitle">Kick-off Party</p>
          <p class="-subtitle">Press Conference</p>
          <p class="-subtitle">100 Hours Nonstop</p>
        </div>
        <div class="events-list">
          <p class="-subtitle">Influencers Brunch</p>
          <p class="-subtitle">Closing Party</p>
          <p class="-subtitle">Benefit Gala</p>
          <p class="-subtitle">MIAFW @ Art Week Miami</p>
          <p class="-subtitle">MIAFW Summit</p>
          <p class="-subtitle">Designers Dinner</p>
          <p class="-subtitle">Kick-off Party</p>
          <p class="-subtitle">Press Conference</p>
          <p class="-subtitle">100 Hours Nonstop</p>
        </div>
      </div>
      <div class="post-list">
        <div
          class="post"
          (click)="
            navigate(
              'https://www.youtube.com/watch?v=ddvRN0eg9_0&list=PLsFCLXOqTbqlJ5ywDww1xrJLyrwq7oGDf'
            )
          "
        >
          <div class="post-brief">
            <div class="img-space">
              <img src="/assets/Landing/Events/100.png" alt="" />
            </div>
            <h2 class="-regular">100 Hours Nonstop - “Volver a vivir…Unidos!”</h2>
          </div>
          <button class="btn-1">
            Watch the event
            <div class="icon-space">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 103.48 104.29"
              >
                <g id="Layer_2" data-name="Layer 2">
                  <g id="Layer_1-2" data-name="Layer 1">
                    <polygon
                      points="72.48 0 0.48 0 0.48 31 50.66 31 0 81.66 22.63 104.28 72.48 54.43 72.48 103 103.48 103 103.48 0 72.48 0"
                    />
                  </g>
                </g>
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 103.48 104.29"
              >
                <g id="Layer_2" data-name="Layer 2">
                  <g id="Layer_1-2" data-name="Layer 1">
                    <polygon
                      points="72.48 0 0.48 0 0.48 31 50.66 31 0 81.66 22.63 104.28 72.48 54.43 72.48 103 103.48 103 103.48 0 72.48 0"
                    />
                  </g>
                </g>
              </svg>
            </div>
          </button>
        </div>
        <div
          class="post"
          (click)="currentVideoSpace = '430223106'; videoSpace = true"
        >
          <div class="post-brief">
            <div class="img-space">
              <img src="/assets/Landing/Events/Backstage.jpg" alt="" />
            </div>
            <h2 class="-regular">“Backstage 2019” - TV Special</h2>
          </div>
          <button class="btn-1">
            Watch promo
            <div class="icon-space">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 103.48 104.29"
              >
                <g id="Layer_2" data-name="Layer 2">
                  <g id="Layer_1-2" data-name="Layer 1">
                    <polygon
                      points="72.48 0 0.48 0 0.48 31 50.66 31 0 81.66 22.63 104.28 72.48 54.43 72.48 103 103.48 103 103.48 0 72.48 0"
                    />
                  </g>
                </g>
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 103.48 104.29"
              >
                <g id="Layer_2" data-name="Layer 2">
                  <g id="Layer_1-2" data-name="Layer 1">
                    <polygon
                      points="72.48 0 0.48 0 0.48 31 50.66 31 0 81.66 22.63 104.28 72.48 54.43 72.48 103 103.48 103 103.48 0 72.48 0"
                    />
                  </g>
                </g>
              </svg>
            </div>
          </button>
        </div>
        <div
          class="post"
          (click)="currentVideoSpace = '430223077'; videoSpace = true"
        >
          <div class="post-brief">
            <div class="img-space">
              <img src="/assets/Landing/Events/FirstFaces.jpg" alt="" />
            </div>
            <h2 class="-regular">“First Faces 2019” - TV Special</h2>
          </div>
          <button class="btn-1">
            Watch promo
            <div class="icon-space">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 103.48 104.29"
              >
                <g id="Layer_2" data-name="Layer 2">
                  <g id="Layer_1-2" data-name="Layer 1">
                    <polygon
                      points="72.48 0 0.48 0 0.48 31 50.66 31 0 81.66 22.63 104.28 72.48 54.43 72.48 103 103.48 103 103.48 0 72.48 0"
                    />
                  </g>
                </g>
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 103.48 104.29"
              >
                <g id="Layer_2" data-name="Layer 2">
                  <g id="Layer_1-2" data-name="Layer 1">
                    <polygon
                      points="72.48 0 0.48 0 0.48 31 50.66 31 0 81.66 22.63 104.28 72.48 54.43 72.48 103 103.48 103 103.48 0 72.48 0"
                    />
                  </g>
                </g>
              </svg>
            </div>
          </button>
        </div>
        <div
          class="post"
          (click)="currentVideoSpace = '430223060'; videoSpace = true"
        >
          <div class="post-brief">
            <div class="img-space">
              <img src="/assets/Landing/Events/Influencers.jpg" alt="" />
            </div>
            <h2 class="-regular">Influencers Brunch</h2>
          </div>
          <button class="btn-1">
            Watch video
            <div class="icon-space">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 103.48 104.29"
              >
                <g id="Layer_2" data-name="Layer 2">
                  <g id="Layer_1-2" data-name="Layer 1">
                    <polygon
                      points="72.48 0 0.48 0 0.48 31 50.66 31 0 81.66 22.63 104.28 72.48 54.43 72.48 103 103.48 103 103.48 0 72.48 0"
                    />
                  </g>
                </g>
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 103.48 104.29"
              >
                <g id="Layer_2" data-name="Layer 2">
                  <g id="Layer_1-2" data-name="Layer 1">
                    <polygon
                      points="72.48 0 0.48 0 0.48 31 50.66 31 0 81.66 22.63 104.28 72.48 54.43 72.48 103 103.48 103 103.48 0 72.48 0"
                    />
                  </g>
                </g>
              </svg>
            </div>
          </button>
        </div>
        <div
          class="post"
          *ngIf="morePosts"
          (click)="currentVideoSpace = '430223014'; videoSpace = true"
        >
          <div class="post-brief">
            <div class="img-space">
              <img src="/assets/Landing/Events/Closing.jpg" alt="" />
            </div>
            <h2 class="-regular">Closing Party</h2>
          </div>
          <button class="btn-1">
            Watch video
            <div class="icon-space">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 103.48 104.29"
              >
                <g id="Layer_2" data-name="Layer 2">
                  <g id="Layer_1-2" data-name="Layer 1">
                    <polygon
                      points="72.48 0 0.48 0 0.48 31 50.66 31 0 81.66 22.63 104.28 72.48 54.43 72.48 103 103.48 103 103.48 0 72.48 0"
                    />
                  </g>
                </g>
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 103.48 104.29"
              >
                <g id="Layer_2" data-name="Layer 2">
                  <g id="Layer_1-2" data-name="Layer 1">
                    <polygon
                      points="72.48 0 0.48 0 0.48 31 50.66 31 0 81.66 22.63 104.28 72.48 54.43 72.48 103 103.48 103 103.48 0 72.48 0"
                    />
                  </g>
                </g>
              </svg>
            </div>
          </button>
        </div>
        <div
          class="post"
          *ngIf="morePosts"
          (click)="currentVideoSpace = '430222950'; videoSpace = true"
        >
          <div class="post-brief">
            <div class="img-space">
              <img src="/assets/Landing/Events/Summit.jpg" alt="" />
            </div>
            <h2 class="-regular">Miami Fashion Week Summit</h2>
          </div>
          <button class="btn-1">
            Watch video
            <div class="icon-space">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 103.48 104.29"
              >
                <g id="Layer_2" data-name="Layer 2">
                  <g id="Layer_1-2" data-name="Layer 1">
                    <polygon
                      points="72.48 0 0.48 0 0.48 31 50.66 31 0 81.66 22.63 104.28 72.48 54.43 72.48 103 103.48 103 103.48 0 72.48 0"
                    />
                  </g>
                </g>
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 103.48 104.29"
              >
                <g id="Layer_2" data-name="Layer 2">
                  <g id="Layer_1-2" data-name="Layer 1">
                    <polygon
                      points="72.48 0 0.48 0 0.48 31 50.66 31 0 81.66 22.63 104.28 72.48 54.43 72.48 103 103.48 103 103.48 0 72.48 0"
                    />
                  </g>
                </g>
              </svg>
            </div>
          </button>
        </div>
        <div
          class="post"
          *ngIf="morePosts"
          (click)="currentVideoSpace = '430223030'; videoSpace = true"
        >
          <div class="post-brief">
            <div class="img-space">
              <img src="/assets/Landing/Events/Press.jpg" alt="" />
            </div>
            <h2 class="-regular">Press Conference</h2>
          </div>
          <button class="btn-1">
            Watch video
            <div class="icon-space">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 103.48 104.29"
              >
                <g id="Layer_2" data-name="Layer 2">
                  <g id="Layer_1-2" data-name="Layer 1">
                    <polygon
                      points="72.48 0 0.48 0 0.48 31 50.66 31 0 81.66 22.63 104.28 72.48 54.43 72.48 103 103.48 103 103.48 0 72.48 0"
                    />
                  </g>
                </g>
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 103.48 104.29"
              >
                <g id="Layer_2" data-name="Layer 2">
                  <g id="Layer_1-2" data-name="Layer 1">
                    <polygon
                      points="72.48 0 0.48 0 0.48 31 50.66 31 0 81.66 22.63 104.28 72.48 54.43 72.48 103 103.48 103 103.48 0 72.48 0"
                    />
                  </g>
                </g>
              </svg>
            </div>
          </button>
        </div>
        <div
          class="post"
          *ngIf="morePosts"
          (click)="currentVideoSpace = '430223688'; videoSpace = true"
        >
          <div class="post-brief">
            <div class="img-space">
              <img src="/assets/Landing/Events/Kickoff.jpg" alt="" />
            </div>
            <h2 class="-regular">Kick-off Party</h2>
          </div>
          <button class="btn-1">
            Watch video
            <div class="icon-space">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 103.48 104.29"
              >
                <g id="Layer_2" data-name="Layer 2">
                  <g id="Layer_1-2" data-name="Layer 1">
                    <polygon
                      points="72.48 0 0.48 0 0.48 31 50.66 31 0 81.66 22.63 104.28 72.48 54.43 72.48 103 103.48 103 103.48 0 72.48 0"
                    />
                  </g>
                </g>
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 103.48 104.29"
              >
                <g id="Layer_2" data-name="Layer 2">
                  <g id="Layer_1-2" data-name="Layer 1">
                    <polygon
                      points="72.48 0 0.48 0 0.48 31 50.66 31 0 81.66 22.63 104.28 72.48 54.43 72.48 103 103.48 103 103.48 0 72.48 0"
                    />
                  </g>
                </g>
              </svg>
            </div>
          </button>
        </div>
        <div
          class="post"
          *ngIf="morePosts"
          (click)="currentVideoSpace = '430222890'; videoSpace = true"
        >
          <div class="post-brief">
            <div class="img-space">
              <img src="/assets/Landing/Events/DesignersDinner.jpg" alt="" />
            </div>
            <h2 class="-regular">Designers Dinner</h2>
          </div>
          <button class="btn-1">
            Watch video
            <div class="icon-space">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 103.48 104.29"
              >
                <g id="Layer_2" data-name="Layer 2">
                  <g id="Layer_1-2" data-name="Layer 1">
                    <polygon
                      points="72.48 0 0.48 0 0.48 31 50.66 31 0 81.66 22.63 104.28 72.48 54.43 72.48 103 103.48 103 103.48 0 72.48 0"
                    />
                  </g>
                </g>
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 103.48 104.29"
              >
                <g id="Layer_2" data-name="Layer 2">
                  <g id="Layer_1-2" data-name="Layer 1">
                    <polygon
                      points="72.48 0 0.48 0 0.48 31 50.66 31 0 81.66 22.63 104.28 72.48 54.43 72.48 103 103.48 103 103.48 0 72.48 0"
                    />
                  </g>
                </g>
              </svg>
            </div>
          </button>
        </div>
        <div
          class="post"
          *ngIf="morePosts"
          (click)="currentVideoSpace = '430222926'; videoSpace = true"
        >
          <div class="post-brief">
            <div class="img-space">
              <img src="/assets/Landing/Events/BenefitGala.jpg" alt="" />
            </div>
            <h2 class="-regular">Benefit Gala</h2>
          </div>
          <button class="btn-1">
            Watch video
            <div class="icon-space">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 103.48 104.29"
              >
                <g id="Layer_2" data-name="Layer 2">
                  <g id="Layer_1-2" data-name="Layer 1">
                    <polygon
                      points="72.48 0 0.48 0 0.48 31 50.66 31 0 81.66 22.63 104.28 72.48 54.43 72.48 103 103.48 103 103.48 0 72.48 0"
                    />
                  </g>
                </g>
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 103.48 104.29"
              >
                <g id="Layer_2" data-name="Layer 2">
                  <g id="Layer_1-2" data-name="Layer 1">
                    <polygon
                      points="72.48 0 0.48 0 0.48 31 50.66 31 0 81.66 22.63 104.28 72.48 54.43 72.48 103 103.48 103 103.48 0 72.48 0"
                    />
                  </g>
                </g>
              </svg>
            </div>
          </button>
        </div>
        <div
          class="post"
          *ngIf="morePosts"
          (click)="currentVideoSpace = '430222872'; videoSpace = true"
        >
          <div class="post-brief">
            <div class="img-space">
              <img src="/assets/Landing/Events/Art.jpg" alt="" />
            </div>
            <h2 class="-regular">MIAFW at Art Week</h2>
          </div>
          <button class="btn-1">
            Watch video
            <div class="icon-space">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 103.48 104.29"
              >
                <g id="Layer_2" data-name="Layer 2">
                  <g id="Layer_1-2" data-name="Layer 1">
                    <polygon
                      points="72.48 0 0.48 0 0.48 31 50.66 31 0 81.66 22.63 104.28 72.48 54.43 72.48 103 103.48 103 103.48 0 72.48 0"
                    />
                  </g>
                </g>
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 103.48 104.29"
              >
                <g id="Layer_2" data-name="Layer 2">
                  <g id="Layer_1-2" data-name="Layer 1">
                    <polygon
                      points="72.48 0 0.48 0 0.48 31 50.66 31 0 81.66 22.63 104.28 72.48 54.43 72.48 103 103.48 103 103.48 0 72.48 0"
                    />
                  </g>
                </g>
              </svg>
            </div>
          </button>
        </div>
      </div>
      <button class="btn-2" (click)="morePosts = !morePosts">
        Watch <span *ngIf="!morePosts" style="margin-left: 0.5vw;"> more</span
        ><span *ngIf="morePosts" style="margin-left: 0.5vw;"> less</span>
        <div class="icon-space" *ngIf="!morePosts">
          <img src="/assets/Landing/Plus.svg" alt="" />
          <img src="/assets/Landing/Plus.svg" alt="" />
        </div>
        <div class="icon-space" *ngIf="morePosts">
          <img src="/assets/Landing/Less.svg" alt="" />
          <img src="/assets/Landing/Less.svg" alt="" />
        </div>
      </button>
    </div>
    <div class="creators-space white-section">
      <h1 class="-regular">Meet the creators</h1>
      <div class="creator" (click)="navigate('designer/2019/0')">
        <img src="/assets/Landing/Designers/Agatha.jpg" alt="" />
        <img src="/assets/Landing/Designers/Agatha-2.jpg" alt="" />
        <h2 class="-light">Agatha Ruiz de la Prada</h2>
      </div>
      <div class="creator" (click)="navigate('designer/2019/1')">
        <img src="/assets/Landing/Designers/Arcadio.jpg" alt="" />
        <img src="/assets/Landing/Designers/Arcadio-2.jpg" alt="" />
        <h2 class="-light">Arcadio Díaz</h2>
      </div>
      <div class="creator" (click)="navigate('designer/2019/2')">
        <img src="/assets/Landing/Designers/Daniella.jpg" alt="" />
        <img src="/assets/Landing/Designers/Daniella-2.jpg" alt="" />
        <h2 class="-light">Daniella Batlle</h2>
      </div>
      <div class="creator" (click)="navigate('designer/2019/3')">
        <img src="/assets/Landing/Designers/Fernando.jpg" t alt="" />
        <img src="/assets/Landing/Designers/Fernando-2.jpg" alt="" />
        <h2 class="-light">Fernando Alberto</h2>
      </div>
      <div class="creator" (click)="navigate('designer/2019/4')">
        <img src="/assets/Landing/Designers/Giannina.png" alt="" />
        <img src="/assets/Landing/Designers/Giannina-2.jpg" alt="" />
        <h2 class="-light">Giannina Azar</h2>
      </div>
      <div class="creator" (click)="navigate('designer/2019/5')">
        <img src="/assets/Landing/Designers/Glory.jpg" alt="" />
        <img src="/assets/Landing/Designers/Glory-2.jpg" alt="" />
        <h2 class="-light">Glory Ang</h2>
      </div>
      <div class="creator" (click)="navigate('designer/2019/6')">
        <img src="/assets/Landing/Designers/Herminas.jpg" alt="" />
        <img src="/assets/Landing/Designers/Herminas-2.jpg" alt="" />
        <h2 class="-light">Herminas Reea</h2>
      </div>
      <div class="creator" (click)="navigate('designer/2019/7')">
        <img src="/assets/Landing/Designers/Jacqueline.jpg" alt="" />
        <img src="/assets/Landing/Designers/Jacqueline-2.jpg" alt="" />
        <h2 class="-light">Jacqueline Then</h2>
      </div>
      <div class="creator" (click)="navigate('designer/2019/8')">
        <img src="/assets/Landing/Designers/Jenny.jpg" alt="" />
        <img src="/assets/Landing/Designers/Jenny-2.jpg" alt="" />
        <h2 class="-light">Jenny Polanco</h2>
      </div>
      <!-- <div class="creator">
        <img src="/assets/Landing/Designers/Monica.jpg" alt="" />
        <img src="/assets/Landing/Designers/Monica-2.jpg" alt="" />
        <h2 class="-light">Monica Varela</h2>
      </div> -->
      <div class="creator" (click)="navigate('designer/2019/10')">
        <img src="/assets/Landing/Designers/Rene.jpg" alt="" />
        <img src="/assets/Landing/Designers/Rene-2.jpg" alt="" />
        <h2 class="-light">Rene by RR</h2>
      </div>
      <div class="creator" (click)="navigate('designer/2019/11')">
        <img src="/assets/Landing/Designers/Vero.jpg" alt="" />
        <img src="/assets/Landing/Designers/Vero-2.jpg" alt="" />
        <h2 class="-light">Vero Díaz</h2>
      </div>
    </div>
    <div class="banner-space">
      <div class="banner-img"></div>
      <h1 class="-regular">MIAFW: The Experience</h1>
      <p class="-subtitle">
        Presents you with the opportunity to receive fashion’s most
        scene-and-be-seen invitation. With packages available at various levels,
        guests will have access to a well-deserved and glamourous VIP
        experience.
      </p>
      <button class="btn-1" (click)="navigate('experiences')">
        Learn more
        <div class="icon-space">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 103.48 104.29">
            <g id="Layer_2" data-name="Layer 2">
              <g id="Layer_1-2" data-name="Layer 1">
                <polygon
                  points="72.48 0 0.48 0 0.48 31 50.66 31 0 81.66 22.63 104.28 72.48 54.43 72.48 103 103.48 103 103.48 0 72.48 0"
                />
              </g>
            </g>
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 103.48 104.29">
            <g id="Layer_2" data-name="Layer 2">
              <g id="Layer_1-2" data-name="Layer 1">
                <polygon
                  points="72.48 0 0.48 0 0.48 31 50.66 31 0 81.66 22.63 104.28 72.48 54.43 72.48 103 103.48 103 103.48 0 72.48 0"
                />
              </g>
            </g>
          </svg>
        </div>
      </button>
    </div>
    <!-- <div class="partners-space">
      <h1 class="-regular">Our Partners</h1>
      <div class="events-carrousel-wrapper">
        <div class="controls">
          <button class="btn-icon">
            <img src="/assets/Landing/Greater-Than.svg" alt="" />
          </button>
          <button class="btn-icon">
            <img src="/assets/Landing/Less-Than.svg" alt="" />
          </button>
        </div>
        <div class="partners-carrousel">
          <div class="partner">
            <img src="/assets/Landing/Partners/Telemundo.png" alt="" />
          </div>
          <div class="partner">
            <img src="/assets/Landing/Partners/E.png" alt="" />
          </div>
          <div class="partner">
            <img src="/assets/Landing/Partners/Wordpress.png" alt="" />
          </div>
          <div class="partner">
            <img src="/assets/Landing/Partners/East-Miami.svg" alt="" />
          </div>
          <div class="partner">
            <img src="/assets/Landing/Partners/MFI.png" alt="" />
          </div>
          <div class="partner">
            <img src="/assets/Landing/Partners/Saks.svg" u alt="" />
          </div>
        </div>
      </div>
    </div> -->
    <div class="broadcast-space white-section">
      <div class="broadcast-title">
        <h1 class="-regular">Broadcasted over 20 countries</h1>
        <p class="-subtitle">
          Miami Fashion Week produces three TV specials annually, in addition to
          an everyday livestream that keeps all the audience in sync with the
          event
        </p>
      </div>
      <div class="broadcast-img-space">
        <img src="/assets/Landing/Broadcast.jpg" alt="" />
      </div>
      <div class="attrs-space">
        <div class="attr-wrapper">
          <div class="attr-title">
            <h2 class="-regular">Backstage<br>MIAFW </h2>
            <p class="-regular">17 Million Viewers</p>
            <p>
              A sneak peek into the behind the scenes of the action of one of
              the world’s most exciting Resort Collection fashion show. You will
              see exclusive footage, preparations, nerves and tense moments
              minutes before the event
            </p>
          </div>
          <button
            class="btn-1 -black"
            (click)="currentVideoSpace = '430175371'; videoSpace = true"
          >
            Watch promo
            <div class="icon-space">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 103.48 104.29"
              >
                <g id="Layer_2" data-name="Layer 2">
                  <g id="Layer_1-2" data-name="Layer 1">
                    <polygon
                      points="72.48 0 0.48 0 0.48 31 50.66 31 0 81.66 22.63 104.28 72.48 54.43 72.48 103 103.48 103 103.48 0 72.48 0"
                    />
                  </g>
                </g>
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 103.48 104.29"
              >
                <g id="Layer_2" data-name="Layer 2">
                  <g id="Layer_1-2" data-name="Layer 1">
                    <polygon
                      points="72.48 0 0.48 0 0.48 31 50.66 31 0 81.66 22.63 104.28 72.48 54.43 72.48 103 103.48 103 103.48 0 72.48 0"
                    />
                  </g>
                </g>
              </svg>
            </div>
          </button>
        </div>
        <div class="attr-wrapper">
          <div class="attr-title">
            <h2 class="-regular">First<br>Faces</h2>
            <p class="-regular">12 Million Viewers</p>
            <p>
              An engaging program that reveals how a future Top Model is born.
              This show includes the ins-and-outs of the modeling world and the
              tensions, nervousness and excitement backstage at Miami Fashion
              Week
            </p>
          </div>
          <button
            class="btn-1 -black"
            (click)="currentVideoSpace = '430175444'; videoSpace = true"
          >
            Watch promo
            <div class="icon-space">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 103.48 104.29"
              >
                <g id="Layer_2" data-name="Layer 2">
                  <g id="Layer_1-2" data-name="Layer 1">
                    <polygon
                      points="72.48 0 0.48 0 0.48 31 50.66 31 0 81.66 22.63 104.28 72.48 54.43 72.48 103 103.48 103 103.48 0 72.48 0"
                    />
                  </g>
                </g>
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 103.48 104.29"
              >
                <g id="Layer_2" data-name="Layer 2">
                  <g id="Layer_1-2" data-name="Layer 1">
                    <polygon
                      points="72.48 0 0.48 0 0.48 31 50.66 31 0 81.66 22.63 104.28 72.48 54.43 72.48 103 103.48 103 103.48 0 72.48 0"
                    />
                  </g>
                </g>
              </svg>
            </div>
          </button>
        </div>
        <div class="attr-wrapper">
          <div class="attr-title">
            <h2 class="-regular">Designers Staging<br>the Runway</h2>
            <p class="-regular">5 Million Viewers</p>
            <p>
              An 8 episode special dedicated to show step by step the process of
              a designer in MIAFW. From the model selection, to runways and
              personal interviews of the designers that were present in MIAFW
              2017.
            </p>
          </div>
          <button
            class="btn-1 -black"
            (click)="currentVideoSpace = '430175405'; videoSpace = true"
          >
            Watch promo
            <div class="icon-space">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 103.48 104.29"
              >
                <g id="Layer_2" data-name="Layer 2">
                  <g id="Layer_1-2" data-name="Layer 1">
                    <polygon
                      points="72.48 0 0.48 0 0.48 31 50.66 31 0 81.66 22.63 104.28 72.48 54.43 72.48 103 103.48 103 103.48 0 72.48 0"
                    />
                  </g>
                </g>
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 103.48 104.29"
              >
                <g id="Layer_2" data-name="Layer 2">
                  <g id="Layer_1-2" data-name="Layer 1">
                    <polygon
                      points="72.48 0 0.48 0 0.48 31 50.66 31 0 81.66 22.63 104.28 72.48 54.43 72.48 103 103.48 103 103.48 0 72.48 0"
                    />
                  </g>
                </g>
              </svg>
            </div>
          </button>
        </div>
      </div>
    </div>
    <div class="stage-space white-section">
      <div class="stage-title">
        <h1 class="-regular">Designed to surprise</h1>
        <p class="-subtitle">
          This never-before-seen Runway is designed to surprise you, every time
          with an innovative twist.
        </p>
      </div>
      <div class="stage-video-space">
        <button class="btn-icon" (click)="playVideo(430171700)" *ngIf="designedToSurpriseVideo === -1">
          <img src="/assets/Landing/Play-button.svg" alt="">
        </button>
        <iframe #runwayPlayer
          src="https://player.vimeo.com/video/430171700?autoplay=0&title=0&byline=0&portrait=0"
          frameborder="0"
          allow="autoplay; fullscreen"
          allowfullscreen
        ></iframe>
      </div>
      <div class="attrs-space -two">
        <div class="attr-wrapper">
          <div class="attr-title">
            <h2 class="-regular">
              A runway that pushes the boundaries of innovation
            </h2>
            <p>
              Miami Fashion Week is a pioneer in the fashion industry, being the
              first content driven runway in the world
            </p>
          </div>
          <button
            class="btn-1 -black"
            [class.-pause]="designedToSurpriseVideo === 430171700"
            (click)="playVideo(430171700)"
          >
            <span *ngIf="designedToSurpriseVideo === 430171755 ||  designedToSurpriseVideo === -1">Play now</span>
            <span *ngIf="designedToSurpriseVideo === 430171700">Now playing</span>
            <div class="icon-space">
              <img src="/assets/Landing/Play.svg" alt="" />
              <img src="/assets/Landing/Pause.svg" alt="" />
            </div>
          </button>
        </div>
        <div class="attr-wrapper">
          <div class="attr-title">
            <h2 class="-regular">A big stage for big work, designed by Sergio Salerni</h2>
            <p>
              Our runway shows are directed by Sergio Salerni, runway director
              for the biggest fashion houses such as Fendi, Chanel, among others
            </p>
          </div>
          <button
            class="btn-1 -black"
            [class.-pause]="designedToSurpriseVideo === 430171755"
            (click)="playVideo(430171755)"
          >
            <span *ngIf="designedToSurpriseVideo === 430171700 ||  designedToSurpriseVideo === -1">Play now</span>
            <span *ngIf="designedToSurpriseVideo === 430171755">Now playing</span>
            <div class="icon-space">
              <img src="/assets/Landing/Play.svg" alt="" />
              <img src="/assets/Landing/Pause.svg" alt="" />
            </div>
          </button>
        </div>
      </div>
    </div>
    <div class="banner-space">
      <div class="banner-img -bepart"></div>
      <h1 class="-regular">Be part of the show</h1>
      <p class="-subtitle">
        Don't miss the opportunity to share and mingle among Miami's top
        fashionistas, best designers, fashion icons and amazing parties.
      </p>
      <div class="banner-buttons-space">
        <div>
          <button class="btn-1" (click)="navigate('new-sponsors')">
            Sponsors
            <div class="icon-space">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 103.48 104.29"
              >
                <g id="Layer_2" data-name="Layer 2">
                  <g id="Layer_1-2" data-name="Layer 1">
                    <polygon
                      points="72.48 0 0.48 0 0.48 31 50.66 31 0 81.66 22.63 104.28 72.48 54.43 72.48 103 103.48 103 103.48 0 72.48 0"
                    />
                  </g>
                </g>
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 103.48 104.29"
              >
                <g id="Layer_2" data-name="Layer 2">
                  <g id="Layer_1-2" data-name="Layer 1">
                    <polygon
                      points="72.48 0 0.48 0 0.48 31 50.66 31 0 81.66 22.63 104.28 72.48 54.43 72.48 103 103.48 103 103.48 0 72.48 0"
                    />
                  </g>
                </g>
              </svg>
            </div>
          </button>
        </div>
        <div>
          <button class="btn-1" (click)="navigate('designers')">
            Designers
            <div class="icon-space">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 103.48 104.29"
              >
                <g id="Layer_2" data-name="Layer 2">
                  <g id="Layer_1-2" data-name="Layer 1">
                    <polygon
                      points="72.48 0 0.48 0 0.48 31 50.66 31 0 81.66 22.63 104.28 72.48 54.43 72.48 103 103.48 103 103.48 0 72.48 0"
                    />
                  </g>
                </g>
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 103.48 104.29"
              >
                <g id="Layer_2" data-name="Layer 2">
                  <g id="Layer_1-2" data-name="Layer 1">
                    <polygon
                      points="72.48 0 0.48 0 0.48 31 50.66 31 0 81.66 22.63 104.28 72.48 54.43 72.48 103 103.48 103 103.48 0 72.48 0"
                    />
                  </g>
                </g>
              </svg>
            </div>
          </button>
        </div>
        <div>
          <button class="btn-1" (click)="navigate('models')">
            Models
            <div class="icon-space">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 103.48 104.29"
              >
                <g id="Layer_2" data-name="Layer 2">
                  <g id="Layer_1-2" data-name="Layer 1">
                    <polygon
                      points="72.48 0 0.48 0 0.48 31 50.66 31 0 81.66 22.63 104.28 72.48 54.43 72.48 103 103.48 103 103.48 0 72.48 0"
                    />
                  </g>
                </g>
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 103.48 104.29"
              >
                <g id="Layer_2" data-name="Layer 2">
                  <g id="Layer_1-2" data-name="Layer 1">
                    <polygon
                      points="72.48 0 0.48 0 0.48 31 50.66 31 0 81.66 22.63 104.28 72.48 54.43 72.48 103 103.48 103 103.48 0 72.48 0"
                    />
                  </g>
                </g>
              </svg>
            </div>
          </button>
        </div>
        <div>
          <button class="btn-1" (click)="navigate('press')">
            Press
            <div class="icon-space">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 103.48 104.29"
              >
                <g id="Layer_2" data-name="Layer 2">
                  <g id="Layer_1-2" data-name="Layer 1">
                    <polygon
                      points="72.48 0 0.48 0 0.48 31 50.66 31 0 81.66 22.63 104.28 72.48 54.43 72.48 103 103.48 103 103.48 0 72.48 0"
                    />
                  </g>
                </g>
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 103.48 104.29"
              >
                <g id="Layer_2" data-name="Layer 2">
                  <g id="Layer_1-2" data-name="Layer 1">
                    <polygon
                      points="72.48 0 0.48 0 0.48 31 50.66 31 0 81.66 22.63 104.28 72.48 54.43 72.48 103 103.48 103 103.48 0 72.48 0"
                    />
                  </g>
                </g>
              </svg>
            </div>
          </button>
        </div>
      </div>
    </div>
    <!-- <div class="events-recap-space">
      <h1 class="-regular">Latest fashion news</h1>
      <div class="events-banner">
        <div class="events-list">
          <p class="-subtitle">Fashion</p>
          <p class="-subtitle">Future</p>
          <p class="-subtitle">Miami</p>
          <p class="-subtitle">Gala</p>
          <p class="-subtitle">Impact</p>
          <p class="-subtitle">Runways</p>
          <p class="-subtitle">Antonio Banderas</p>
          <p class="-subtitle">Designers</p>
          <p class="-subtitle">Lifestyle</p>
          <p class="-subtitle">Trends</p>
        </div>
        <div class="events-list">
          <p class="-subtitle">Fashion</p>
          <p class="-subtitle">Future</p>
          <p class="-subtitle">Miami</p>
          <p class="-subtitle">Gala</p>
          <p class="-subtitle">Impact</p>
          <p class="-subtitle">Runways</p>
          <p class="-subtitle">Antonio Banderas</p>
          <p class="-subtitle">Designers</p>
          <p class="-subtitle">Lifestyle</p>
          <p class="-subtitle">Trends</p>
        </div>
        <div class="events-list">
          <p class="-subtitle">Fashion</p>
          <p class="-subtitle">Future</p>
          <p class="-subtitle">Miami</p>
          <p class="-subtitle">Gala</p>
          <p class="-subtitle">Impact</p>
          <p class="-subtitle">Runways</p>
          <p class="-subtitle">Antonio Banderas</p>
          <p class="-subtitle">Designers</p>
          <p class="-subtitle">Lifestyle</p>
          <p class="-subtitle">Trends</p>
        </div>
      </div>
      <div class="post-list">
        <div class="post">
          <div class="post-brief">
            <div class="img-space">
              <img src="/assets/Landing/Backstage.png" alt="" />
            </div>
            <h2 class="-regular">
              Antonio Banderas thinks sustainability is the future of fahsion
            </h2>
            <p>The Daily Front Row</p>
          </div>
          <button class="btn-1">
            Read more
            <div class="icon-space">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 103.48 104.29"
              >
                <g id="Layer_2" data-name="Layer 2">
                  <g id="Layer_1-2" data-name="Layer 1">
                    <polygon
                      points="72.48 0 0.48 0 0.48 31 50.66 31 0 81.66 22.63 104.28 72.48 54.43 72.48 103 103.48 103 103.48 0 72.48 0"
                    />
                  </g>
                </g>
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 103.48 104.29"
              >
                <g id="Layer_2" data-name="Layer 2">
                  <g id="Layer_1-2" data-name="Layer 1">
                    <polygon
                      points="72.48 0 0.48 0 0.48 31 50.66 31 0 81.66 22.63 104.28 72.48 54.43 72.48 103 103.48 103 103.48 0 72.48 0"
                    />
                  </g>
                </g>
              </svg>
            </div>
          </button>
        </div>
        <div class="post">
          <div class="post-brief">
            <div class="img-space">
              <img src="/assets/Landing/First-Faces.png" alt="" />
            </div>
            <h2 class="-regular">
              Antonio Banderas On The Impact And Future Of Miami Fashion Week
            </h2>
            <p>Haute Living</p>
          </div>
          <button class="btn-1">
            Read more
            <div class="icon-space">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 103.48 104.29"
              >
                <g id="Layer_2" data-name="Layer 2">
                  <g id="Layer_1-2" data-name="Layer 1">
                    <polygon
                      points="72.48 0 0.48 0 0.48 31 50.66 31 0 81.66 22.63 104.28 72.48 54.43 72.48 103 103.48 103 103.48 0 72.48 0"
                    />
                  </g>
                </g>
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 103.48 104.29"
              >
                <g id="Layer_2" data-name="Layer 2">
                  <g id="Layer_1-2" data-name="Layer 1">
                    <polygon
                      points="72.48 0 0.48 0 0.48 31 50.66 31 0 81.66 22.63 104.28 72.48 54.43 72.48 103 103.48 103 103.48 0 72.48 0"
                    />
                  </g>
                </g>
              </svg>
            </div>
          </button>
        </div>
        <div class="post">
          <div class="post-brief">
            <div class="img-space">
              <img src="/assets/Landing/Influencers-Brunch.png" alt="" />
            </div>
            <h2 class="-regular">Top 3 Runways from Milan Fashion Week</h2>
            <p>MIAFW</p>
          </div>
          <button class="btn-1">
            Read more
            <div class="icon-space">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 103.48 104.29"
              >
                <g id="Layer_2" data-name="Layer 2">
                  <g id="Layer_1-2" data-name="Layer 1">
                    <polygon
                      points="72.48 0 0.48 0 0.48 31 50.66 31 0 81.66 22.63 104.28 72.48 54.43 72.48 103 103.48 103 103.48 0 72.48 0"
                    />
                  </g>
                </g>
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 103.48 104.29"
              >
                <g id="Layer_2" data-name="Layer 2">
                  <g id="Layer_1-2" data-name="Layer 1">
                    <polygon
                      points="72.48 0 0.48 0 0.48 31 50.66 31 0 81.66 22.63 104.28 72.48 54.43 72.48 103 103.48 103 103.48 0 72.48 0"
                    />
                  </g>
                </g>
              </svg>
            </div>
          </button>
        </div>
        <div class="post">
          <div class="post-brief">
            <div class="img-space">
              <img src="/assets/Landing/Closing-Party.png" alt="" />
            </div>
            <h2 class="-regular">Closing Party</h2>
          </div>
          <button class="btn-1">
            Read more
            <div class="icon-space">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 103.48 104.29"
              >
                <g id="Layer_2" data-name="Layer 2">
                  <g id="Layer_1-2" data-name="Layer 1">
                    <polygon
                      points="72.48 0 0.48 0 0.48 31 50.66 31 0 81.66 22.63 104.28 72.48 54.43 72.48 103 103.48 103 103.48 0 72.48 0"
                    />
                  </g>
                </g>
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 103.48 104.29"
              >
                <g id="Layer_2" data-name="Layer 2">
                  <g id="Layer_1-2" data-name="Layer 1">
                    <polygon
                      points="72.48 0 0.48 0 0.48 31 50.66 31 0 81.66 22.63 104.28 72.48 54.43 72.48 103 103.48 103 103.48 0 72.48 0"
                    />
                  </g>
                </g>
              </svg>
            </div>
          </button>
        </div>
      </div>
      <div class="btn-2">
        Watch more
        <div class="icon-space">
          <img src="/assets/Landing/Plus.svg" alt="" />
          <img src="/assets/Landing/Plus.svg" alt="" />
        </div>
      </div>
    </div> -->
    <div class="new-footer">
      <img src="/assets/Landing/Logo-White.png" alt="" />
      <div class="footer-content-wrapper">
        <a routerLink="" class="-regular -subtitle" (click)="navigate('about')"
          >About Us</a
        >
        <a
          routerLink=""
          class="-regular -subtitle"
          (click)="navigate('contact')"
          >Contact Us</a
        >
        <div class="social-networks-wrapper">
          <a
            class="link-icon"
            href="https://twitter.com/miamifashionwk/"
            target="_blank"
          >
            <img src="/assets/Landing/SocialNetworks/Twitter.svg" alt="" />
          </a>
          <a
            class="link-icon"
            href="https://www.facebook.com/MiamiFashionWeek/"
            target="_blank"
          >
            <img src="/assets/Landing/SocialNetworks/Facebook.svg" alt="" />
          </a>
          <a
            class="link-icon"
            href="https://www.instagram.com/miamifashionweek/"
            target="_blank"
          >
            <img src="/assets/Landing/SocialNetworks/Instagram.svg" alt="" />
          </a>
          <a
            class="link-icon"
            href="https://www.youtube.com/user/MiamiFashionWeek/"
            target="_blank"
          >
            <img src="/assets/Landing/SocialNetworks/Youtube.svg" alt="" />
          </a>
        </div>
        <p>
          © 2020 MFW Events. Inc All right reserved.<br />Miami Fashion Week is
          registered service mark of MFW Events. Inc.
        </p>
      </div>
    </div>
  </div>
</div>
