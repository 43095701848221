import { BehaviorSubject } from 'rxjs';
import { CarrouselPage } from '../models';
import { Injectable } from '@angular/core';

@Injectable()
export class CarrouselImageService {
  public currentCarrousel: BehaviorSubject<CarrouselPage> = new BehaviorSubject(null);

  constructor() { }
}
