import { Directive, HostListener, ElementRef, Input, Inject, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Directive({
    selector: '[appScrollableHeader]'
})
export class ScrollableHeaderDirective {

    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input('appScrollableHeader') isHeader: boolean;

    isScrolled = false;

   constructor(private elRef: ElementRef, @Inject(DOCUMENT) private document: Document, private renderer: Renderer2) {
   }

   @HostListener('window:scroll', ['$event'])
   onScrollEvent($event) {
    const window = $event.currentTarget;
    const number = window.pageYOffset || this.document.documentElement.scrollTop || this.document.body.scrollTop || 0;
    if (number >= (window.innerHeight * 0.6)) {
        if (this.isHeader && !this.isScrolled) {
            this.renderer.addClass(this.elRef.nativeElement, 'scrolled');
            this.isScrolled = true;
            const css = document.getElementsByClassName('navbar');
            if (css !== undefined && css[0] !== undefined) { css[0].classList.add('scrolled'); }
        }
    } else {
        if (this.isHeader && this.isScrolled) {
            this.renderer.removeClass(this.elRef.nativeElement, 'scrolled');
            this.isScrolled = false;
            const css = document.getElementsByClassName('navbar');
            if (css !== undefined && css[0] !== undefined) { css[0].classList.remove('scrolled'); }

        }
    }
   }
}
