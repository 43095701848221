import { AbanderasComponent } from './abanderas/abanderas.component';
import { AboutComponent } from './about/about.component';
import { AlertComponent } from './alert/alert.component';
import { ArtWeekComponent } from './art-week/art-week.component';
import { BlogComponent } from './blog/blog.component';
import { BlogListComponent } from './blog/list/list.component';
import { BuyExperienceComponent } from './buy-experience/buy-experience.component';
import { BuyGalaComponent } from './buy-gala/buy-gala.component';
import { BuyRunwaysComponent } from './buy-runways/buy-runways.component';
import { BuySummitComponent } from './buy-summit/buy-summit.component';
import { CarrouselpageComponent } from './carrouselpage/carrouselpage.component';
import { CarrouselpageSmallComponent } from './carruselpage-small/carruselpage-small.component';
import { CommonModule } from '@angular/common';
import { ContactComponent } from './contact/contact.component';
import { CountDownDirective } from './directives/countdown.directive';
import { DesignersComponent } from './designers/designers.component';
import { DesignersLandingComponent } from './designers/landing/designers-landing.component';
import { DesignersProfileComponent } from './designers/profile/designers-profile.component';
import { EditableButtonDirective } from './directives/editableButton.directive';
import { EditableDayDirective } from './directives/editableDay.directive';
import { EditableEventDirective } from './directives/editableEvent.directive';
import { EditableGalleryDirective } from './directives/editableGallery.directive';
import { EditableImageDirective } from './directives/editableImage.directive';
import { EditableInputDirective } from './directives/editableInput.directive';
import { EditableTextDirective } from './directives/editableText.directive';
import { EditableVideoDirective } from './directives/editableVideo.directive';
import { EditableVideoGalleryDirective } from './directives/editableVideoGallery.directive';
import { EventsComponent } from './events/events.component';
import { ExperiencesComponent } from './experiences/experiences.component';
import { FilterPipe } from './pipes/filter.pipe';
import { FirstFacesComponent } from './first-faces/first-faces.component';
import { FirstFacesTVComponent } from './first-faces/tv/first-faces-tv.component';
import { FirstFacesVotesComponent } from './first-faces/votes/first-faces-votes.component';
import { FooterComponent } from './footer/footer.component';
import { FormsModule } from '@angular/forms';
import { Gallery2017Component } from './gallery2017/gallery2017.component';
import { GalleryComponent } from './gallery/gallery.component';
import { HomeComponent } from './home/home.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { LoadingComponent } from './loading/loading.component';
import { MainModalComponent } from './main-modal/main-modal.component';
import { MiafwbashComponent } from './miafwbash/miafwbash.component';
import { ModelsComponent } from './models/models.component';
import { NavbarComponent } from './navbar/navbar.component';
import { NewsComponent } from './news/news.component';
import { NgModule } from '@angular/core';
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';
import { NgxPageScrollModule } from 'ngx-page-scroll';
import { NotnullPipe } from './notnull.pipe';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { PostComponent } from './blog/post/post.component';
import { PressComponent } from './press/press.component';
import { PressregComponent } from './pressreg/pressreg.component';
import { Question } from './question-base';
import { ReactiveFormsModule } from '@angular/forms';
import { RegistrationComponent } from './registration/registration.component';
import { RunwaysComponent } from './runways/runways.component';
import { SafePipe } from './safe.pipe';
import { ScheduleComponent } from './schedule/schedule.component';
import { ScriptHackComponent } from './other/scripting/scripthack.component';
import { ScrollableHeaderDirective } from './directives/scrollableHeader.directive';
import { SidebarComponent } from './sidebar/sidebar.component';
import { SponsorsComponent } from './sponsors/sponsors.component';
import { SponsorsregComponent } from './sponsorsreg/sponsorsreg.component';
import { StreamingComponent } from './streaming/streaming.component';
import { ThankYouComponent } from './thank-you/thank-you.component';
import { TicketsComponent } from './tickets/tickets.component';
import { TvContentComponent } from './tvcontent/tvcontent.component';
import { TvSeriesComponent } from './tvseries/tvseries.component';
import { VideoGalleryComponent } from './gallery/videogallery.component';
import { VolunteersComponent } from './volunteers/volunteers.component';
import { IvyCarouselModule } from 'angular-responsive-carousel';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    NgxPageScrollCoreModule.forRoot({}),
    NgxPageScrollModule,
    LazyLoadImageModule,
    PdfViewerModule,
    IvyCarouselModule
  ],
  declarations: [
    SafePipe,
    SidebarComponent,
    NavbarComponent,
    HomeComponent,
    LoadingComponent,
    MainModalComponent,
    AboutComponent,
    FirstFacesComponent,
    FirstFacesVotesComponent,
    FirstFacesTVComponent,
    ScheduleComponent,
    RunwaysComponent,
    ArtWeekComponent,
    AbanderasComponent,
    MiafwbashComponent,
    CarrouselpageComponent,
    CarrouselpageSmallComponent,
    StreamingComponent,
    ExperiencesComponent,
    TicketsComponent,
    SponsorsComponent,
    FooterComponent,
    RegistrationComponent,
    TvContentComponent,
    EventsComponent,
    DesignersComponent,
    ModelsComponent,
    SponsorsregComponent,
    PressregComponent,
    GalleryComponent,
    Gallery2017Component,
    VideoGalleryComponent,
    BlogComponent,
    PressComponent,
    PostComponent,
    BlogListComponent,
    VolunteersComponent,
    DesignersLandingComponent,
    ContactComponent,
    EditableButtonDirective,
    EditableTextDirective,
    EditableImageDirective,
    EditableVideoDirective,
    EditableInputDirective,
    EditableEventDirective,
    EditableDayDirective,
    ScrollableHeaderDirective,
    EditableGalleryDirective,
    EditableVideoGalleryDirective,
    CountDownDirective,
    PageNotFoundComponent,
    BuyExperienceComponent,
    BuyGalaComponent,
    BuyRunwaysComponent,
    BuySummitComponent,
    ThankYouComponent,
    ScriptHackComponent,
    TvSeriesComponent,
    DesignersProfileComponent,
    AlertComponent,
    NotnullPipe,
    FilterPipe,
    NewsComponent
  ],
  exports: [
    SidebarComponent,
    NavbarComponent,
    HomeComponent,
    LoadingComponent,
    MainModalComponent,
    AboutComponent,
    FirstFacesComponent,
    FirstFacesVotesComponent,
    FirstFacesTVComponent,
    ScheduleComponent,
    RunwaysComponent,
    ArtWeekComponent,
    AbanderasComponent,
    MiafwbashComponent,
    CarrouselpageComponent,
    CarrouselpageSmallComponent,
    StreamingComponent,
    TicketsComponent,
    ExperiencesComponent,
    SponsorsComponent,
    FooterComponent,
    RegistrationComponent,
    EventsComponent,
    DesignersComponent,
    ModelsComponent,
    SponsorsregComponent,
    PressregComponent,
    GalleryComponent,
    Gallery2017Component,
    VideoGalleryComponent,
    BlogComponent,
    PressComponent,
    PostComponent,
    BlogListComponent,
    VolunteersComponent,
    DesignersLandingComponent,
    ContactComponent,
    EditableButtonDirective,
    EditableTextDirective,
    EditableImageDirective,
    EditableVideoDirective,
    EditableInputDirective,
    EditableEventDirective,
    EditableDayDirective,
    ScrollableHeaderDirective,
    EditableGalleryDirective,
    EditableVideoGalleryDirective,
    CountDownDirective,
    PageNotFoundComponent,
    BuyExperienceComponent,
    BuySummitComponent,
    BuyGalaComponent,
    BuyRunwaysComponent,
    ThankYouComponent,
    ScriptHackComponent,
    TvSeriesComponent,
    DesignersProfileComponent,
    AlertComponent,
    NotnullPipe,
    FilterPipe,
    NewsComponent
  ],
})
export class SharedModule { }
