<div
  class="section header headerImage"
  [appEditableImage]="editingMode"
  [editRoute]="'img_1'"
  [image]="registration.img_1"
></div>
<div *ngIf="!submited">
  <div class="form p20 txtCenter">
    <div
      [ngClass]="registration.title.style"
      [innerHTML]="languageService.stringsResource[registration.title.content]"
      class="underline"
      [appEditableText]="editingMode"
      [editRoute]="'title'"
      [element]="registration.title"
    ></div>
    <br /><br />
    <div
      *ngIf="registration.subtitle != undefined"
      [ngClass]="registration.subtitle.style"
      [innerHTML]="
        languageService.stringsResource[registration.subtitle.content]
      "
      [appEditableText]="editingMode"
      [editRoute]="'subtitle'"
      [element]="registration.subtitle"
    ></div>
    <br /><br />
    <div *ngIf="error" class="title3" style="color: red;">{{ error }}</div>
    <div
      *ngIf="currentUpload != null"
      class="progress"
      [ngStyle]="{ width: currentUpload?.progress + '%' }"
    ></div>
    <div *ngIf="currentUpload != null" class="progress-text">
      {{ (currentUpload?.progress | number: "1.0-0") + "%" }}
    </div>
    <form *ngIf="form != null" (ngSubmit)="validate()" [formGroup]="form">
      <div class="col" *ngIf="registration.leftForm != null">
        <div
          *ngFor="let input of registration.leftForm; let i = index"
          [appEditableInput]="editingMode"
          [editRoute]="'leftForm.' + i"
          [question]="input"
          [left]="true"
        >
          <label
            *ngIf="input.type != 'submit'"
            [attr.for]="input.key"
            [class.clickable]="editingMode"
            >{{ languageService.stringsResource[input.label] }}</label
          >
          <input
            *ngIf="
              input.type == 'text' ||
              input.type == 'email' ||
              input.type == 'number'
            "
            [class.clickable]="editingMode"
            [type]="input.type"
            [formControlName]="input.key"
            required
          />
          <textarea
            *ngIf="input.type == 'textarea'"
            [class.clickable]="editingMode"
            [formControlName]="input.key"
            cols="30"
            rows="10"
            required
          ></textarea>
          <button
            *ngIf="input.type == 'upload'"
            [class.clickable]="editingMode"
            (click)="upload(input.key)"
            class="upload"
            type="button"
          >
            {{ languageService.stringsResource["formUpload"] }}
          </button>
          <input
            *ngIf="input.type == 'upload'"
            [id]="input.key"
            [formControlName]="input.key"
            (change)="detectFiles($event)"
            style="display:none;"
            type="file"
            accept="pdf|jpeg|jpg|png|tiff|apng|gif"
          />
          <button
            *ngIf="input.type == 'bool'"
            (click)="
              !editingMode && (form.value[input.key] = !form.value[input.key])
            "
            class="yesno"
            [class.active]="form.value[input.key]"
            type="button"
          >
            {{
              form.value[input.key]
                ? languageService.stringsResource["formyes"]
                : languageService.stringsResource["formno"]
            }}
          </button>
          <button
            *ngIf="input.type == 'submit'"
            [disabled]="!form.valid || currentUpload != null"
          >
            {{ languageService.stringsResource["formSubmit"] }}
          </button>
          <br />
        </div>
      </div>
      <div class="col" *ngIf="registration.rightForm != null">
        <div
          *ngFor="let input of registration.rightForm; let i = index"
          [appEditableInput]="editingMode"
          [editRoute]="'rightForm.' + i"
          [question]="input"
          [left]="false"
        >
          <label [attr.for]="input.key">{{
            languageService.stringsResource[input.label]
          }}</label>
          <input
            *ngIf="
              input.type == 'text' ||
              input.type == 'email' ||
              input.type == 'number'
            "
            [type]="input.type"
            [formControlName]="input.key"
            min="0"
            step="0.1"
            required
          />
          <textarea
            *ngIf="input.type == 'textarea'"
            [formControlName]="input.key"
            cols="30"
            rows="10"
            required
          ></textarea>
          <button
            *ngIf="input.type == 'upload'"
            (click)="upload(input.key)"
            class="upload"
            [class.done]="uploads[input.key] != null"
            type="button"
          >
            {{ languageService.stringsResource["formUpload"] }}
          </button>
          <input
            *ngIf="input.type == 'upload'"
            [id]="input.key"
            [formControlName]="input.key"
            (change)="detectFiles($event)"
            style="display:none;"
            type="file"
            accept="pdf|jpeg|jpg|png|tiff|apng|gif"
          />
          <button
            *ngIf="input.type == 'bool'"
            (click)="
              !editingMode && (form.value[input.key] = !form.value[input.key])
            "
            class="yesno"
            [class.active]="form.value[input.key]"
            type="button"
          >
            {{
              form.value[input.key]
                ? languageService.stringsResource["formyes"]
                : languageService.stringsResource["formno"]
            }}
          </button>
          <button
            *ngIf="input.type == 'submit'"
            [disabled]="currentUpload != null"
          >
            {{ languageService.stringsResource["formSubmit"] }}
          </button>
          <br />
        </div>
      </div>
    </form>
  </div>
</div>
<div *ngIf="submited || editingMode">
  <div class="form p20 txtCenter">
    <div *ngIf="editingMode" class="txt1">REGISTRATION CONFIRMATION TEXT:</div>
    <div
      [ngClass]="registration.confirmation.style"
      class="underline"
      [innerHTML]="
        languageService.stringsResource[registration.confirmation.content]
      "
      [appEditableText]="editingMode"
      [editRoute]="'confirmation'"
      [element]="registration.confirmation"
    ></div>
  </div>
</div>
