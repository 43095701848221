import { DomSanitizer } from '@angular/platform-browser';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'safe'
})
export class SafePipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) {}
  transform(videoID) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(
      'https://player.vimeo.com/video/' + videoID + '?autoplay=1&loop=1&autopause=0'
    );
  }

}
