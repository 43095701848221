import { BehaviorSubject } from 'rxjs';
import { CarrouselPage } from './models';
import { Injectable } from '@angular/core';

@Injectable()
export class CarrouselserviceService {

  public currentCarrousel: BehaviorSubject<CarrouselPage> = new BehaviorSubject(null);

  constructor() { }

}
