import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { LanguageService } from '../../language.service';
import { AngularFireDatabase } from '@angular/fire/database';
import { ImagesService } from '../../images.service';
import { Router } from '@angular/router';
import { NavbarService } from '../../navbar.service';
import { interval } from 'rxjs';
import { takeWhile } from 'rxjs/operators';

@Component({
  selector: 'app-first-faces-tv',
  templateUrl: './first-faces-tv.component.html',
  styleUrls: ['./first-faces-tv.component.css']
})
export class FirstFacesTVComponent implements OnInit, AfterViewInit, OnDestroy {

  loading = true;
  imagesLoading = true;

  constructor(public languageService: LanguageService, public imagesService: ImagesService, private db: AngularFireDatabase,
    private router: Router, private navSvc: NavbarService) {
     }

  ngOnInit() {
    this.navSvc.white.next(true);
  }

  ngOnDestroy() {
    this.navSvc.white.next(false);
  }

  ngAfterViewInit() {
    interval(100).pipe(
      takeWhile(() => this.imagesLoading))
      .subscribe(i => {
        if (this.imagesService.loading || this.loading) { return; }
        // this.imagesService.loadHeader(this.imagesService.imagesResource[this.firstfaces.img_2.src]);
        this.imagesLoading = false;
      });
  }

  navigate(url: string) {
    this.router.navigateByUrl(url);
  }

}
