<div class="modalBG active" (click)="accept()">
</div>
<div class="modalContent active">
  <div>
      <div class="title">{{title}}</div>
      <br>
      <div class="textEdit txt1">{{body}}</div>
      <button *ngIf="acceptTitle" class="preview" (click)="accept()">{{acceptTitle}}</button>
      <button *ngIf="cancelTitle" class="preview" (click)="accept()">{{cancelTitle}}</button>
  </div>
  <!-- <div class="close" (click)="accept()"></div> -->
</div>