import { Component, AfterViewInit } from '@angular/core';
import { EditorService } from './dashboard/editor.service';
import { NavbarService } from './navbar.service';
import { AngularFireDatabase } from '@angular/fire/database';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements AfterViewInit {
  title = 'app';

  editingMode = false;
  noNavbarMode = false;
  activeStream = false;

  constructor(private editor: EditorService, private nonav: NavbarService, private db: AngularFireDatabase) {
    this.editor.editingModeEnabled.subscribe(editing => {
      this.editingMode = editing;
    });
    this.nonav.noNav.subscribe(noNavbar => {
      this.noNavbarMode = noNavbar;
      console.log(noNavbar);
      
    });

    this.loadScale(document, window);
  }

  ngAfterViewInit() {
    const promisse = this.db
      .object<any>('temp/streaming')
      .valueChanges()
      .subscribe(stream => {
        this.activeStream = stream;
      });
  }

  loadScale(doc, win) {
    const docEl = doc.documentElement,
      recalc = function() {
        const clientWidth = docEl.clientWidth;
        if (!clientWidth) {
          return;
        }

        docEl.style.fontSize = clientWidth + 'px';
        docEl.style.display = 'none';
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        docEl.clientWidth; // Force relayout - important to new Android devices
        docEl.style.display = '';
      };

    // Abort if browser does not support addEventListener
    if (!doc.addEventListener) {
      return;
    }

    // Test rem support
    const div = doc.createElement('div');
    div.setAttribute('style', 'font-size: 1rem');

    // Abort if browser does not recognize rem
    if (div.style.fontSize !== '1rem') {
      return;
    }

    win.addEventListener('resize', recalc, false);
    doc.addEventListener('DOMContentLoaded', recalc, false);
  }
}
