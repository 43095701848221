<div class="sidebar" [class.-selected]="menuOpen">
  <img src="/assets/Landing/Logo-White.png" class="logo" alt="" />
  <button class="hamb-menu" (click)="menuOpen = !menuOpen" [class.-selected]="menuOpen">
    <div class="hamb-bar"></div>
    <div class="hamb-bar"></div>
    <div class="hamb-bar"></div>
  </button>
  <div></div>
</div>
<div class="menu-space" [class.-selected]="menuOpen">
  <div class="menu-col">
    <button class="-regular" (click)="menuOpen = !menuOpen">Home</button>
    <button class="-regular" [class.-selected]="menuCol === 'events'" (click)="menuCol = 'events'">
      The events
    </button>
    <div class="menu-col -sub" *ngIf="menuCol === 'events'">
      <button class="-light" (click)="navigate('event/benefit-gala')">
        MIAFW Benefit Gala
      </button>
      <button class="-light" (click)="navigate('event/miami-week-summit')">
        Miami Fashion Week Summit
      </button>
      <button class="-light" (click)="navigate('event/firstfaces')">
        First Faces
      </button>
      <button class="-light" (click)="navigate('event/experiences')">
        MIAFW: The Experience
      </button>
      <button class="-light" (click)="navigate('event/artweek')">Art Week</button>
      <button class="-light" (click)="navigate('event/influencers-brunch')">
        Influencers Brunch
      </button>
      <button class="-light" (click)="navigate('event/closing-party')">
        Closing Party
      </button>
      <button class="-light" (click)="navigate('event/designers-dinner')">
        Designers Dinner
      </button>
      <button class="-light" (click)="navigate('event/kick-off-party')">
        Kick-off Party
      </button>
      <button class="-light" (click)="navigate('event/press-conference')">
        Press Conference
      </button>
    </div>
    <button class="-regular" (click)="navigate('designers')">
      Designers
    </button>
    <button class="-regular" [class.-selected]="menuCol === 'gallery'" (click)="menuCol = 'gallery'">
      Gallery
    </button>
    <div class="menu-col -sub" *ngIf="menuCol === 'gallery'">
      <button class="-light" (click)="navigate('gallery/all')">Photos</button>
      <button class="-light" (click)="navigate('video')">Videos</button>
    </div>
    <!-- <button class="-regular">Tickets</button> -->

    <button class="-regular" [class.-selected]="menuCol === 'press'" (click)="menuCol = 'press'">
      Press
    </button>
    <!-- <button class="-regular" (click)="navigate('pressblog')">Press</button> -->


    <button class="-regular" [class.-selected]="menuCol === 'contact'" (click)="menuCol = 'contact'">
      Contact
    </button>
    <div class="menu-col -sub" *ngIf="menuCol === 'contact'">
      <button class="-light" (click)="navigate('designers')">
        Designers
      </button>
      <button class="-light" (click)="navigate('models')">Models</button>
      <button class="-light" (click)="navigate('new-sponsors')">
        Sponsors
      </button>
      <button class="-light" (click)="navigate('volunteers')">
        Volunteers
      </button>
      <button class="-light" (click)="navigate('press')">Press</button>
    </div>
  </div>
  <div class="menu-col" *ngIf="menuCol === 'home'"></div>
  <div class="menu-col" *ngIf="menuCol === 'events'">
    <button class="-light" (click)="navigate('event/benefit-gala')">
      MIAFW Benefit Gala
    </button>
    <button class="-light" (click)="navigate('event/miami-week-summit')">
      Miami Fashion Week Summit
    </button>
    <button class="-light" (click)="navigate('event/firstfaces')">
      First Faces
    </button>
    <button class="-light" (click)="navigate('event/experiences')">
      MIAFW: The Experience
    </button>
    <button class="-light" (click)="navigate('event/artweek')">Art Week</button>
    <button class="-light" (click)="navigate('event/influencers-brunch')">
      Influencers Brunch
    </button>
    <button class="-light" (click)="navigate('event/closing-party')">
      Closing Party
    </button>
    <button class="-light" (click)="navigate('event/designers-dinner')">
      Designers Dinner
    </button>
    <button class="-light" (click)="navigate('event/kick-off-party')">
      Kick-off Party
    </button>
    <button class="-light" (click)="navigate('event/press-conference')">
      Press Conference
    </button>
  </div>
  <div class="menu-col" *ngIf="menuCol === 'contact'">
    <button class="-light" (click)="navigate('designers')">Designers</button>
    <button class="-light" (click)="navigate('models')">Models</button>
    <button class="-light" (click)="navigate('new-sponsors')">
      Sponsors
    </button>
    <button class="-light" (click)="navigate('volunteers')">
      Volunteers
    </button>
    <button class="-light" (click)="navigate('press')">Press</button>
  </div>
  <div class="menu-col" *ngIf="menuCol === 'gallery'">
    <button class="-light" (click)="navigate('gallery/all')">Photos</button>
    <button class="-light" (click)="navigate('video')">Videos</button>
  </div>
  <div class="menu-col -sub" *ngIf="menuCol === 'press'">
    <button class="-light" (click)="navigate('news')">Latest news</button>
    <button class="-light" (click)="navigate('pressblog')">Press Releases</button>
  </div>
  <div class="menu-col -expanded">
    <div class="lang-wrapper">
      <button (click)="languageService.changeLang('en')" class="-regular" [class.-selected]="currentLang === 'en'">
        En
      </button>
      <div class="division"></div>
      <button (click)="languageService.changeLang('es')" class="-regular" [class.-selected]="currentLang === 'es'">
        Es
      </button>
    </div>
    <div class="social-networks-wrapper -small">
      <a class="link-icon" href="https://twitter.com/miamifashionwk/" target="_blank">
        <img src="/assets/Landing/SocialNetworks/Twitter.svg" alt="" />
      </a>
      <a class="link-icon" href="https://www.facebook.com/MiamiFashionWeek/" target="_blank">
        <img src="/assets/Landing/SocialNetworks/Facebook.svg" alt="" />
      </a>
      <a class="link-icon" href="https://www.instagram.com/miamifashionweek/" target="_blank">
        <img src="/assets/Landing/SocialNetworks/Instagram.svg" alt="" />
      </a>
      <a class="link-icon" href="https://www.youtube.com/user/MiamiFashionWeek/" target="_blank">
        <img src="/assets/Landing/SocialNetworks/Youtube.svg" alt="" />
      </a>
    </div>
  </div>
</div>