import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { EditorService } from '../dashboard/editor.service';

@Directive({
    selector: '[appEditableButton]'
})
export class EditableButtonDirective {
    constructor(private el: ElementRef, private editor: EditorService) {
    }

    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input('appEditableButton') active: boolean;
    @Input() editRoute: string;
    @Input() link: any;
    @Input() hidden: boolean;

    @HostListener('click', ['$event'])
    public onClick(event: any): void {
        if (this.active) {
            event.stopPropagation();
            this.editor.startButtonEdit.next({ link: this.link, route: this.editRoute, hidden: this.hidden });
        }
    }
}
