import { Question } from './question-base';

/* eslint-disable @typescript-eslint/naming-convention */
export class NavBar {
  logo: string;
  logo_darkz: string;
  navs: Array<NavItem>;
}

export class NavItem {
  menu: Array<NavItem>;
  title: string;
  url: string;
  hidden?: boolean;
  order?: number;
}

export class Box {
  body: Element;
  title: Element;
  button: Element;
}

export class Element {
  content: string;
  style: string;
}

export class Image {
  src: string;
}

export class Video {
  url: string;
  title: string;
}

export class Link {
  content: string;
  url: string;
  image: Image;
  style: string;
  description: Experience;
  hidden?: boolean;
}

export class Landing {
  box1: Box;
  box2: Box;
  box3: Box;
  box4: Box;
  img_1: Image;
  img_2: Image;
  img_3: Image;
  img_4: Image;
  img_5: Image;
  img_6: Image;
  liveURL: string;
}

export class About {
  box1: Box;
  img_1: Image;
}

export class Schedule {
  days: Array<Day>;
  title: Element;
  img_1: Image;
}

export class Day {
  title: Element;
  events: Array<Event>;
}

export class Event {
  tickets: Link;
  time: string;
  title: string;
  type: string;
}

// miafw_bash, antonio_banderas, runways
export class CarrouselPage {
  path: string;
  body: Element;
  images: Array<Image>;
  first_carrousel: Array<Image>;
  img_1: Image;
  links: Array<Link>;
  title: Element;
  activeDescription: boolean;
}

// registration_designer, registration_models, registration_sponsors
export class Registration {
  leftForm: Array<Question>;
  rightForm: Array<Question>;
  title: Element;
  img_1: Image;
  path: string;
  confirmation: string;
}

export class Tickets {
  img_1: Image;
  links: Array<Link>;
  title: Element;
}

export class Experiences {
  img_1: Image;
  links: Array<Link>;
  title: Element;
}

export class Experience {
  content: string;
}

export class Sponsor {
  img_color: string;
  img_gray: string;
  name: string;
  url: string;
}

export class Sponsors {
  title: Element;
  mainTitle: Element;
  mediaTitle: Element;
  otherTitle: Element;
  officialTitle: Element;
  img_1: Image;
  main: Array<Sponsor>;
  media: Array<Sponsor>;
  other: Array<Sponsor>;
  official: Array<Sponsor>;
}

export class Gallery {
  title: Element;
  subtitle: Element;
  galleries: Array<Gallery>;
  images: Array<Image>;
  img_1: Image;
  path: string;
}

export class VideoGallery {
  title: Element;
  subtitle: Element;
  galleries: Array<VideoGallery>;
  videos: Array<Video>;
  img_1: Image;
  path: string;
}

// Dynamic Forms
export class QuestionBase<T> {
  value: T;
  key: string;
  label: string;
  required: boolean;
  order: number;
  controlType: string;

  constructor(
    options: {
      value?: T;
      key?: string;
      label?: string;
      required?: boolean;
      order?: number;
      controlType?: string;
    } = {}
  ) {
    this.value = options.value;
    this.key = options.key || '';
    this.label = options.label || '';
    this.required = !!options.required;
    this.order = options.order === undefined ? 1 : options.order;
    this.controlType = options.controlType || '';
  }
}

// Dashboard

export class PageBrief {
  title: string;
  title2: string;
  image: string;
  path: string;
}
