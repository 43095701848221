<app-loading *ngIf="loading || imagesLoading || loadingCategories > 0"></app-loading>
<div *ngIf="!loading && !imagesLoading && loadingCategories <= 0" [class.editingPadding]="editingMode" [class.noscroll]="pause">
  <div class="section header headerImage" [appScrollableHeader]="true" [appEditableImage]="editingMode" [editRoute]="'img_1'"
    [image]="blog.img_1">
  </div>
  <div class="blog" [class.editing]="editingMode">
    <div class="col-container">
      <div class="col m12">
        <div class="cat">
          <div class="cat-fill"></div>
          <div [ngClass]="blog.sections[0].title.style" [appEditableText]="editingMode" [editRoute]="'sections.0.title'"
            [element]="blog.sections[0].title">{{languageService.stringsResource[blog.sections[0].title.content]}}</div>
          <div class="cat-fill"></div>
        </div>
        <div class="post latest" *ngFor="let latePost of latest; let i = index;">
          <div class="avatar s125" id="latest1" [ngClass]="loadBGImage(latePost, 'latest', i)"></div>
          <div class="date">{{latePost.date | date:'dd/MM/yy'}}</div>
          <a (click)="openPost(latePost, $event)" href="" class="blogLink">
            <div class="title1" [innerHTML]="latePost.title.rendered"></div>
          </a>
          <div class="txt2" [innerHTML]="latePost.excerpt.rendered + '<a href=\''+encodeURIComponent(latePost.slug).split('?').join('%3F')+'\' class=\'blogLink\'>Read More &#9656;</a>'">

          </div>
        </div>
        <button class="vm" (click)="viewMore('all')">
          VIEW MORE
        </button>
        <img (click)="navigate(blog.img_2.link)" [src]="imagesService.imagesResource[blog.img_2.src]" alt="" class="banner"
          [appEditableImage]="editingMode" [editRoute]="'img_2'" [image]="blog.img_2">
      </div>
      <div class="col">
        <div class="cat">
          <div class="cat-fill"></div>
          <div [ngClass]="blog.sections[1].title.style" [appEditableText]="editingMode" [editRoute]="'sections.1.title'"
            [element]="blog.sections[1].title">{{languageService.stringsResource[blog.sections[1].title.content]}}</div>
          <div class="cat-fill"></div>
        </div>
        <div *ngFor="let dressed of categorized[blog.sections[1].category[languageService.currentLanguage]]; let i = index;"
          class="post dressed" [ngClass]="loadBGImage(dressed, 'dressed', i)" (click)="openPost(dressed)">
          <div class="white-footer" id="dressed1" [innerHTML]="dressed.title.rendered">
          </div>
        </div>
        <button class="vm" (click)="viewMore(blog.sections[1].category[languageService.currentLanguage])">
          VIEW MORE
        </button>
        <!-- <img [src]="imagesService.imagesResource[blog.img_3.src]" alt="" class="banner" [appEditableImage]="editingMode" [editRoute]="'img_3'" [image]="blog.img_3"> -->
      </div>
    </div>
    <div class="col-container">
      <div class="col m12">
        <div class="cat">
          <div class="cat-fill"></div>
          <div [ngClass]="blog.sections[2].title.style" [appEditableText]="editingMode" [editRoute]="'sections.2.title'"
            [element]="blog.sections[2].title">{{languageService.stringsResource[blog.sections[2].title.content]}}</div>
          <div class="cat-fill"></div>
        </div>
        <div *ngFor="let style of categorized[blog.sections[2].category[languageService.currentLanguage]]; let i = index;"
          class="post style">
          <div class="avatar s150" [ngClass]="loadBGImage(style, 'style', i)" id="style1"></div>
          <a class="blogLink" (click)="openPost(style, $event)" href="">
            <div class="title3" [innerHTML]="style.title.rendered"></div>
          </a>
          <div class="txt2" [innerHTML]="style.excerpt.rendered + '<a href=\''+encodeURIComponent(style.slug).split('?').join('%3F')+'\' class=\'blogLink\'>Read More &#9656;</a>'">
          </div>
        </div>
        <button class="vm" (click)="viewMore(blog.sections[2].category[languageService.currentLanguage])">
          VIEW MORE
        </button>
        <!-- <img [src]="imagesService.imagesResource[blog.img_4.src]" alt="" class="banner" [appEditableImage]="editingMode" [editRoute]="'img_4'" [image]="blog.img_4"> -->
      </div>
      <div class="col">
        <div class="cat">
          <div class="cat-fill"></div>
          <div [ngClass]="blog.sections[3].title.style" [appEditableText]="editingMode" [editRoute]="'sections.3.title'"
            [element]="blog.sections[3].title">{{languageService.stringsResource[blog.sections[3].title.content]}}</div>
          <div class="cat-fill"></div>
        </div>
        <div *ngFor="let trend of categorized[blog.sections[3].category[languageService.currentLanguage]]; let i = index;"
          class="post trends" (click)="openPost(trend)">
          <img [src]="trend.thumbnail" alt="">
          <div class="white-footer">
            <div class="title1" [innerHTML]="trend.title.rendered"></div>
            <div class="txt2" [innerHTML]="trend.excerpt.rendered"></div>
          </div>
        </div>
        <!-- <img [src]="imagesService.imagesResource[blog.img_5.src]" alt="" class="banner" [appEditableImage]="editingMode" [editRoute]="'img_5'" [image]="blog.img_5"> -->
      </div>
    </div>
    <div class="col-container">
      <div class="col m6">
        <div class="cat">
          <div class="cat-fill"></div>
          <div [ngClass]="blog.sections[4].title.style" [appEditableText]="editingMode" [editRoute]="'sections.4.title'"
            [element]="blog.sections[4].title">{{languageService.stringsResource[blog.sections[4].title.content]}}</div>
          <div class="cat-fill"></div>
        </div>
        <div *ngFor="let blogPost of categorized[blog.sections[4].category[languageService.currentLanguage]]; let i = index;"
          class="post blogger">
          <a (click)="openPost(blogPost, $event)" href="" class="blogLink">
            <div class="title3" [innerHTML]="blogPost.title.rendered"></div>
          </a>
          <div class="date">{{blogPost.date | date:'dd/MM/yy'}}</div>
          <div class="txt2" [innerHTML]="blogPost.excerpt.rendered"></div>
          <a [href]="encodeURIComponent(blogPost.slug).split('?').join('%3F')" class="blogLink">Read More &#9656;</a>
        </div>
      </div>
      <div class="col">
        <div class="cat">
          <div class="cat-fill"></div>
          <div [ngClass]="blog.sections[5].title.style" [appEditableText]="editingMode" [editRoute]="'sections.5.title'"
            [element]="blog.sections[5].title">{{languageService.stringsResource[blog.sections[5].title.content]}}</div>
          <div class="cat-fill"></div>
        </div>
        <a class="twitter-timeline" href="https://twitter.com/MiamiFashionWk?ref_src=twsrc%5Etfw" data-tweet-limit="2">Tweets
          by MiamiFashionWk</a>
        <app-script-hack async src="https://platform.twitter.com/widgets.js" type="text/javascript"></app-script-hack>
      </div>
    </div>
  </div>

</div>
