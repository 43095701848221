import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject, timer } from 'rxjs';
import { Experiences, Link } from '../models';

import { AngularFireDatabase } from '@angular/fire/database';
import { DomSanitizer } from '@angular/platform-browser';
import { ImagesService } from '../images.service';
import { LanguageService } from '../language.service';
import { Router } from '@angular/router';
import { interval } from 'rxjs';
import { takeWhile } from 'rxjs/operators';

@Component({
  selector: 'app-experiences',
  templateUrl: './experiences.component.html',
  styleUrls: ['./experiences.component.css'],
})
export class ExperiencesComponent implements OnInit, AfterViewInit {
  @Input() editingMode: boolean;
  @Input() editablePage;
  @Input() uiChanges: BehaviorSubject<boolean>;

  experiences: Experiences;
  loading = true;
  headerLoading = true;

  rulesAdded = 0;
  currentVideo: any;

  constructor(
    public languageService: LanguageService,
    public imagesService: ImagesService,
    private db: AngularFireDatabase,
    private router: Router,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    this.currentVideo = this.sanitizer.bypassSecurityTrustResourceUrl(
      'https://players.brightcove.net/2339105022001/default_default/index.html?videoId=6047090286001'
    );
    if (this.editablePage != null) {
      this.experiences = this.editablePage as Experiences;
      this.loading = false;
    } else {
      const promisse = this.db.object('pages/experiences').valueChanges();
      promisse.subscribe(raw => {
        this.experiences = raw as Experiences;
        this.loading = false;
      });
    }
  }

  ngAfterViewInit() {
    interval(100)
      .pipe(takeWhile(() => this.headerLoading))
      .subscribe(i => {
        if (this.imagesService.loading || this.loading) {
          return;
        }
        this.imagesService.loadHeader(this.imagesService.imagesResource[this.experiences.img_1.src]);
        this.headerLoading = false;
      });
    if (this.editingMode) {
      this.uiChanges.subscribe(changes => {
        if (changes && this.editingMode) {
          this.imagesService.loadHeader(this.imagesService.imagesResource[this.experiences.img_1.src]);
          this.experiences = this.editablePage as Experiences;
          this.rulesAdded = 0;
        }
      });
    }
  }

  navigate(path: string) {
    if (path != null) {
      if (path.indexOf('http') > -1) {
        window.open(path, '_blank');
      } else {
        this.router.navigateByUrl('/' + path);
      }
    }
  }
}
