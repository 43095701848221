import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';
import { EditorService } from '../dashboard/editor.service';

@Directive({
    selector: '[appEditableText]'
})
export class EditableTextDirective {
    constructor(private el: ElementRef<any>, private renderer: Renderer2, private editor: EditorService) {
        this.editor.editingModeEnabled.subscribe((editing) => {
            if (editing) {
                renderer.addClass(el.nativeElement, 'clickable');
            } else {
                renderer.removeClass(el.nativeElement, 'clickable');
            }
        });
    }

    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input('appEditableText') active: boolean;
    @Input() editRoute: string;
    @Input() element: any;
    @Input() negative = false;

    previousColor: any;
    previousIndex: any;

    @HostListener('click', ['$event'])
    public onClick(event: any): void {
        if (this.active) {
            event.stopPropagation();
            this.editor.startTextEdit.next({ element: this.element, route: this.editRoute });
        }
    }

    @HostListener('mouseenter', ['$event'])
    public onMouseEnter(evente) {
        if (this.active) {
            event.stopPropagation();
            this.el.nativeElement.style.backgroundColor = this.negative ? 'white' : 'black';
            this.previousColor = this.el.nativeElement.style.color;
            this.previousIndex = this.el.nativeElement.style['z-index'];
            this.el.nativeElement.style.color = this.negative ? 'black' : 'white';
            this.el.nativeElement.style['z-index'] = '100';
            this.el.nativeElement.style.cursor = 'ponter';

            if (this.el.nativeElement.innerHTML === '') {
                this.el.nativeElement.innerHTML = 'Add text';
            }
        }
    }

    @HostListener('mouseleave', ['$event'])
    public onMouseLeave(event) {
        if (this.active) {
            event.stopPropagation();
            this.el.nativeElement.style.backgroundColor = null;
            this.el.nativeElement.style.color = this.previousColor;
            this.el.nativeElement.style['z-index'] = this.previousIndex;
            this.el.nativeElement.style.cursor = null;
        }
    }
}
