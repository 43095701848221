<app-loading *ngIf="loading"></app-loading>
<div *ngIf="posts">
  <div class="section header headerImage">
  </div>
  <div class="posts">
      <div class="post" *ngFor="let post of posts">
          <a [href]="post.slug" class="linkTitle" [innerHTML]="post.title.rendered"></a>
          <div class="txt1" [innerHTML]="post.excerpt.rendered"></div>
          <a [href]="post.slug" class="linkRm">READ MORE</a>
      </div>
      <div class="pagination">
          <!-- <button><span>&#9666;</span>PREVIOUS</button> -->
          <!-- 1 -->
          <button (click)="loadData()">LOAD MORE</button>
      </div>
  </div>
</div>
