import { AfterViewInit, Component, Input, OnInit } from '@angular/core';

import { AngularFireDatabase } from '@angular/fire/database';
import { BehaviorSubject } from 'rxjs';
import { CarrouselPage } from '../models';
import { CarrouselserviceService } from '../carrouselservice.service';
import { LanguageService } from '../language.service';

@Component({
  selector: 'app-runways',
  templateUrl: './runways.component.html',
  styleUrls: ['./runways.component.css']
})
export class RunwaysComponent implements OnInit, AfterViewInit {

  @Input() editingMode: boolean;
  @Input() editablePage;
  @Input() uiChanges: BehaviorSubject<boolean>;

  loading = true;

  constructor(private languageService: LanguageService, private db: AngularFireDatabase,
    private carrouselService: CarrouselserviceService) { }

  ngOnInit() {
    if (this.editablePage != null) {
      this.carrouselService.currentCarrousel.next(this.editablePage as CarrouselPage);
    } else {
      const promisse = this.db.object('pages/runways').valueChanges();
      promisse.subscribe((raw) => {
        this.carrouselService.currentCarrousel.next(raw as CarrouselPage);
        this.loading = false;
      });
    }
  }

  ngAfterViewInit() {
    if (this.editingMode) {
      this.uiChanges.subscribe((changes) => {
        if (changes && this.editingMode) {
          this.carrouselService.currentCarrousel.next(this.editablePage as CarrouselPage);
        }
      });
    }
  }

}
