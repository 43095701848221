import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { CarrouselPage, Registration } from '../models';

import { AngularFireDatabase } from '@angular/fire/database';
import { BehaviorSubject } from 'rxjs';
import { CarrouselserviceService } from '../carrouselservice.service';
import { FormsService } from '../forms.service';
import { LanguageService } from '../language.service';

@Component({
  selector: 'app-sponsorsreg',
  templateUrl: './sponsorsreg.component.html',
  styleUrls: ['./sponsorsreg.component.css']
})
export class SponsorsregComponent implements OnInit, AfterViewInit {

  @Input() editingMode: boolean;
  @Input() editablePage;
  @Input() uiChanges: BehaviorSubject<boolean>;

  loading = true;

  constructor(private languageService: LanguageService, private db: AngularFireDatabase, private formsService: FormsService,
    private carrouselService: CarrouselserviceService) { }

  ngOnInit() {
    if (this.editablePage != null) {
      this.formsService.currentForm.next(this.editablePage as Registration);
      this.loading = false;
    } else {
      const promisse = this.db.object('pages/registration_sponsors').valueChanges();
      promisse.subscribe((raw) => {
        this.loading = false;
        this.formsService.currentForm.next(raw as Registration);
      });

      const promisse2 = this.db.object('components/sponsors/carrousel').valueChanges();
      promisse2.subscribe((raw) => {
        this.carrouselService.currentCarrousel.next(raw as CarrouselPage);
        this.loading = false;
      });
    }
  }

  ngAfterViewInit() {
    this.uiChanges.subscribe((changes) => {
      if (changes && this.editingMode) {
        this.formsService.currentForm.next(this.editablePage as Registration);
      }
    });
  }
}
