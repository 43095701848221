import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, interval } from 'rxjs';

import { AngularFireDatabase } from '@angular/fire/database';
import { FixedNavbarService } from '../../fixed-navbar.service';
import { ImagesService } from '../../images.service';
import { LanguageService } from '../../language.service';
import { NavbarService } from '../../navbar.service';
import { Router } from '@angular/router';
import { takeWhile } from 'rxjs/operators';

@Component({
  selector: 'app-designers-landing',
  templateUrl: './designers-landing.component.html',
  styleUrls: ['../../first-faces/votes/first-faces-votes.component.css'],
})
export class DesignersLandingComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() editingMode: boolean;
  @Input() editablePage;
  @Input() uiChanges: BehaviorSubject<boolean>;

  designers: any;
  loading = true;
  imagesLoading = true;

  constructor(
    public languageService: LanguageService,
    public imagesService: ImagesService,
    private db: AngularFireDatabase,
    private router: Router,
    private navSvc: NavbarService,
    private fixedNav: FixedNavbarService
  ) { }

  ngOnInit() {
    if (this.editablePage != null) {
      this.designers = this.editablePage;
    } else {
      const promisse = this.db.object('pages/designers').valueChanges();
      promisse.subscribe(raw => {
        this.loading = false;
        this.designers = raw;
        if (this.designers.old !== undefined && this.designers.old != null) {
          this.designers.old = this.designers.old.sort((a, b) => a.name.localeCompare(b.name));
        }
        if (this.designers.designers !== undefined && this.designers.designers != null) {
          this.designers.designers = this.designers.designers.sort((a, b) => a.name.localeCompare(b.name));
        }
      });
    }
    // this.navSvc.white.next(true);
    this.fixedNav.navbarAbsouluteSubject.next(true);
  }

  ngOnDestroy() {
    // this.navSvc.white.next(false);
    this.fixedNav.navbarAbsouluteSubject.next(false);
  }

  ngAfterViewInit() {
    interval(100)
      .pipe(takeWhile(() => this.imagesLoading))
      .subscribe(i => {
        if (this.imagesService.loading || this.loading) {
          return;
        }
        // this.loadImages();
        this.imagesService.loadHeader(this.imagesService.imagesResource[this.designers.img_1.src]);
        this.imagesLoading = false;
      });
    if (this.editingMode) {
      this.uiChanges.subscribe(changes => {
        if (changes && this.editingMode) {
          // this.loadImages();
          this.imagesService.loadHeader(this.imagesService.imagesResource[this.designers.img_1.src]);
        }
      });
    }
  }

  navigate(path: string) {
    if (path != null) {
      if (path.indexOf('http') > -1) {
        // window.location.href = path;
        window.open(path);
      } else {
        if (this.editingMode) {
          this.router.navigateByUrl('/dashboard/' + path);
        } else {
          this.router.navigateByUrl('/' + path);
        }
      }
    }
  }
}
