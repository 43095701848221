<app-loading *ngIf="loading || imagesLoading || authorizing"></app-loading>
<div *ngIf="!loading && !imagesLoading" [class.editingPadding]="editingMode">
  <div *ngIf="!loading && !authorized && !editingMode">
    <div class="section bgImg" [appEditableImage]="editingMode" [editRoute]="'img_1'" [image]="tvseries.img_1"
      [position]="'translate(-50%, -1400%)'">
    </div>
    <div class="box1">
      <div [ngClass]="tvseries.box0.title.style" [innerHTML]="languageService.stringsResource[tvseries.box0.title.content]"
        [appEditableText]="editingMode" [editRoute]="'box0.title'" [element]="tvseries.box0.title" [negative]="true">
      </div>
      <div class="division"></div>
      <div class="flex-space">
        <div class="txt-space">
          <div [ngClass]="tvseries.box0.body.style" [innerHTML]="languageService.stringsResource[tvseries.box0.body.content]"
            [appEditableText]="editingMode" [editRoute]="'box0.body'" [element]="tvseries.box0.body" [negative]="true"></div>
        </div>
      </div>
      <div class="codebox">
        <form (ngSubmit)="submitCode()">
          <input type="text" placeholder="email" name="email" [(ngModel)]="verificationEmail" autocomplete="off">
          <br>
          <input type="password" placeholder="password" name="code" [(ngModel)]="verificationCode" autocomplete="off">
          <br>
          <button type="submit">LOGIN</button>
        </form>
      </div>
      <div class="error" [class.active]="codeError != null">
        <div class="error-text">{{codeError}}</div>
      </div>
      <div>
      </div>
    </div>
  </div>
  <div *ngIf="!loading && (authorized || editingMode) && !onVideo">
    <div class="section bgImg" [appEditableImage]="editingMode" [editRoute]="'img_1'" [image]="tvseries.img_1"
      [position]="'translate(-50%, -1400%)'">
    </div>
    <div class="txtCenter">
      <div [ngClass]="tvseries.title.style" class="underline" [appEditableText]="editingMode" [element]="tvseries.title"
        [editRoute]="'title'" class="txtCenter">{{languageService.stringsResource[tvseries.title.content]}}</div>
    </div>
    <div class="flex-main">
      <div class="flex-space show">
        <div class="title-space">
          <div [ngClass]="tvseries.box5.title.style" [innerHTML]="languageService.stringsResource[tvseries.box5.title.content]"
            [appEditableText]="editingMode" [editRoute]="'box5.title'" [element]="tvseries.box5.title"></div>
          <br>
        </div>
        <div class="btn-space">
          <img src="/assets/tvseries/series2.jpg" alt=""> </div>
        <div class="txt-space">
          <div [ngClass]="tvseries.box5.body.style" [innerHTML]="languageService.stringsResource[tvseries.box5.body.content]"
            [appEditableText]="editingMode" [editRoute]="'box5.body'" [element]="tvseries.box5.body"></div>
          <button (click)="watchVideo(1)" class="btn-watch">{{languageService.currentLanguage === 'en' ? 'PLAY ENG' :
            'REPRODUCIR ENG'}}</button>

          <button (click)="watchVideo(2)" class="btn-watch">{{languageService.currentLanguage === 'en' ? 'PLAY ESP' :
            'REPRODUCIR ESP'}} </button>
        </div>
      </div>
      <div class="flex-space show">
        <div class="title-space">
          <div [ngClass]="tvseries.box2.title.style" [innerHTML]="languageService.stringsResource[tvseries.box2.title.content]"
            [appEditableText]="editingMode" [editRoute]="'box2.title'" [element]="tvseries.box2.title"></div>
          <br>
        </div>
        <div class="btn-space">
          <img src="/assets/tvseries/series2.png" alt=""> </div>
        <div class="txt-space">
          <div [ngClass]="tvseries.box2.body.style" [innerHTML]="languageService.stringsResource[tvseries.box2.body.content]"
            [appEditableText]="editingMode" [editRoute]="'box2.body'" [element]="tvseries.box2.body"></div>
          <div class="menu">
            <a>{{languageService.currentLanguage === 'en' ? 'PLAY ENG' : 'REPRODUCIR ENG'}}</a>
            <ul>
              <li>
                <a (click)="watchVideo(3)">PART I</a>
              </li>
              <li>
                <a (click)="watchVideo(4)">PART II</a>
              </li>
              <li>
                <a (click)="watchVideo(5)">PART III</a>
              </li>
            </ul>
          </div>
          <button (click)="watchVideo(6)" class="btn-watch">{{languageService.currentLanguage === 'en' ? 'PLAY ESP' :
            'REPRODUCIR ESP'}}</button>
        </div>
      </div>
    </div>
    <div class="flex-main">
      <div class="flex-space show">
        <div class="title-space">
          <div [ngClass]="tvseries.box4.title.style" [innerHTML]="languageService.stringsResource[tvseries.box4.title.content]"
            [appEditableText]="editingMode" [editRoute]="'box4.title'" [element]="tvseries.box4.title"></div>
          <br>
        </div>
        <div class="btn-space">
          <img src="/assets/tvseries/oldff.jpg" alt=""> </div>
        <div class="txt-space">
          <div [ngClass]="tvseries.box4.body.style" [innerHTML]="languageService.stringsResource[tvseries.box4.body.content]"
            [appEditableText]="editingMode" [editRoute]="'box4.body'" [element]="tvseries.box4.body"></div>
          <button (click)="watchVideo(7)" class="btn-watch">{{languageService.currentLanguage === 'en' ? 'PLAY ENG' :
            'REPRODUCIR ENG'}}</button>
          <button (click)="watchVideo(8)" class="btn-watch">{{languageService.currentLanguage === 'en' ? 'PLAY ESP' :
            'REPRODUCIR ESP'}}</button>
        </div>
      </div>
      <div class="flex-space show">
        <div class="title-space">
          <div [ngClass]="tvseries.box1.title.style" [innerHTML]="languageService.stringsResource[tvseries.box1.title.content]"
            [appEditableText]="editingMode" [editRoute]="'box1.title'" [element]="tvseries.box1.title"></div>
          <br>
        </div>
        <div class="btn-space">
          <img src="/assets/tvseries/series1.png" alt=""> </div>
        <div class="txt-space">
          <div [ngClass]="tvseries.box1.body.style" [innerHTML]="languageService.stringsResource[tvseries.box1.body.content]"
            [appEditableText]="editingMode" [editRoute]="'box1.body'" [element]="tvseries.box1.body"></div>
          <div class="menu">
            <a>{{languageService.currentLanguage === 'en' ? 'PLAY ENG' : 'REPRODUCIR ENG'}}</a>
            <ul>
              <li>
                <a (click)="watchVideo(9)" >PART I</a>
              </li>
              <li>
                <a (click)="watchVideo(10)">PART II</a>
              </li>
              <li>
                <a (click)="watchVideo(11)">PART III</a>
              </li>
            </ul>
          </div>
          <button (click)="watchVideo(12)" class="btn-watch">{{languageService.currentLanguage === 'en' ? 'PLAY ESP' :
            'REPRODUCIR ESP'}}</button>
        </div>
      </div>
    </div>
    <div class="flex-main">
      <div class="flex-space show">
        <div class="title-space">
          <div [ngClass]="tvseries.box6.title.style" [innerHTML]="languageService.stringsResource[tvseries.box6.title.content]"
            [appEditableText]="editingMode" [editRoute]="'box6.title'" [element]="tvseries.box6.title"></div>
          <br>
        </div>
        <div class="btn-space">
          <img src="/assets/tvseries/series3.jpg" alt=""> </div>
        <div class="txt-space">
          <div [ngClass]="tvseries.box6.body.style" [innerHTML]="languageService.stringsResource[tvseries.box6.body.content]"
            [appEditableText]="editingMode" [editRoute]="'box6.body'" [element]="tvseries.box6.body"></div>
          <div class="menu">
            <a>{{languageService.currentLanguage === 'en' ? 'PLAY ENG' : 'REPRODUCIR ENG'}}</a>
            <ul>
              <li>
                <a (click)="watchVideo(13)">CUSTO BARCELONA</a>
              </li>
              <li>
                <a (click)="watchVideo(14)">RENE RUIZ</a>
              </li>
            </ul>
          </div>
          <div class="menu">
            <a>{{languageService.currentLanguage === 'en' ? 'PLAY ESP' : 'REPRODUCIR ESP'}}</a>
            <ul>
                <li>
                  <a (click)="watchVideo(15)">AGATHA RUIZ </a>
                </li>
                <li>
                  <a (click)="watchVideo(16)">ANGEL SANCHEZ</a>
                </li>
                <li>
                  <a (click)="watchVideo(17)">CUSTO BARCELONA</a>
                </li>
                <li>
                  <a (click)="watchVideo(18)">RENE RUIZ</a>
                </li>
              </ul>
          </div>
        </div>
      </div>
      <div class="flex-space show">
        <div class="title-space">
          <div [ngClass]="tvseries.box3.title.style" [innerHTML]="languageService.stringsResource[tvseries.box3.title.content]"
            [appEditableText]="editingMode" [editRoute]="'box3.title'" [element]="tvseries.box3.title"></div>
          <br>
        </div>
        <div class="btn-space">
          <img src="/assets/tvseries/series3.png" alt=""> </div>
        <div class="txt-space">
          <div [ngClass]="tvseries.box3.body.style" [innerHTML]="languageService.stringsResource[tvseries.box3.body.content]"
            [appEditableText]="editingMode" [editRoute]="'box3.body'" [element]="tvseries.box3.body"></div>
            <div class="menu">
                <a>{{languageService.currentLanguage === 'en' ? 'PLAY ENG' : 'REPRODUCIR ENG'}}</a>
                <ul>
                  <li>
                    <a (click)="watchVideo(13)">CUSTO BARCELONA</a>
                  </li>
                  <li>
                    <a (click)="watchVideo(14)">RENE RUIZ</a>
                  </li>
                </ul>
              </div>
    
              <div class="menu">
                <a>{{languageService.currentLanguage === 'en' ? 'PLAY ESP' : 'REPRODUCIR ESP'}}</a>
                <ul>
                    <li>
                      <a (click)="watchVideo(15)">AGATHA RUIZ </a>
                    </li>
                    <li>
                      <a (click)="watchVideo(16)">ANGEL SANCHEZ</a>
                    </li>
                    <li>
                      <a (click)="watchVideo(17)">CUSTO BARCELONA</a>
                    </li>
                    <li>
                      <a (click)="watchVideo(18)">RENE RUIZ</a>
                    </li>
                  </ul>
              </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="authorized && onVideo">
    <div class="txtCenter">
      <div class="p20 title1 underline" style="padding-left: 5%; padding-right: 5%;">TV SHOW</div>
      <!-- <img src="../assets/First-Faces/Video.png" alt=""> -->
      <br>
      <iframe [src]="currentVideo" allowfullscreen webkitallowfullscreen mozallowfullscreen></iframe>
      <br>
      <div class="btn-back title1 underline" (click)="closeVideo()">{{languageService.currentLanguage === 'en' ? 'BACK
        TO ALL TV SERIES' : 'REGRESAR A TV SERIES'}}</div>
    </div>
  </div>
</div>
