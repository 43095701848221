import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';
import { EditorService } from '../dashboard/editor.service';

@Directive({
    selector: '[appEditableEvent]'
})
export class EditableEventDirective {
    constructor(private el: ElementRef, private renderer: Renderer2, private editor: EditorService) {
        this.editor.editingModeEnabled.subscribe((editing) => {
            if (editing) {
                renderer.addClass(el.nativeElement, 'clickable');
            } else {
                renderer.removeClass(el.nativeElement, 'clickable');
            }
        });
    }

    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input('appEditableEvent') active: boolean;
    @Input() editRoute: string;
    @Input() event: any;


    previousColor: any;

    @HostListener('click', ['$event'])
    public onClick(event: any): void {
        if (this.active) {
            event.stopPropagation();
            this.editor.startEventEdit.next({ event: this.event, route: this.editRoute });
        }
    }

    @HostListener('mouseenter', ['$event'])
    public onMouseEnter(evente) {
        if (this.active) {
            this.el.nativeElement.style.backgroundColor = 'black';
            this.previousColor = this.el.nativeElement.style.color;
            // this.previousIndex = this.el.nativeElement.style['z-index'];
            this.el.nativeElement.style.color = 'white';
            // this.el.nativeElement.style['z-index'] = '100';
            this.el.nativeElement.style.cursor = 'ponter';
        }
    }

    @HostListener('mouseleave', ['$event'])
    public onMouseLeave(event) {
        if (this.active) {
            event.stopPropagation();
            this.el.nativeElement.style.backgroundColor = null;
            this.el.nativeElement.style.color = this.previousColor;
            // this.el.nativeElement.style['z-index'] = this.previousIndex;
            this.el.nativeElement.style.cursor = null;
        }
    }
}
