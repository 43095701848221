// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyBdVlxiEX7kqlciaOG0_a9OhtDatELpfyw',
    authDomain: 'miafw-3a.firebaseapp.com',
    databaseURL: 'https://miafw-3a.firebaseio.com',
    projectId: 'miafw-3a',
    storageBucket: 'miafw-3a.appspot.com',
    messagingSenderId: '3266599606'
  },
  brightcove: {
    client: 'a21bd540-5908-4c5d-876d-ee747e4fc59d',
    secret: '9EmutahU4PTrD7DdtfY5DC9whPrJAaePhyrZiS-kgaTIoVCsAUk5WscfOiX_SWsa2FjyPrgwaR4Z9AOSKnKrTQ'
  }
};
