<app-loading *ngIf="loading || imagesLoading"></app-loading>
<div *ngIf="!loading && !imagesLoading" [class.editingPadding]="editingMode">
    <div class="section bgA bgImg" [appEditableImage]="editingMode" [editRoute]="'img_1'" [image]="about.img_1" [position]="'translate(-50%, -1400%)'">
    </div>
    <div class="swap section p20 h50 bgImg">
        <div class="box1" >
            <div [ngClass]="about.box1.title.style" [innerHTML]="languageService.stringsResource[about.box1.title.content]" [appEditableText]="editingMode" [editRoute]="'box1.title'" [element]="about.box1.title" [negative]="true">
            </div>
            <div class="division"></div>
            <iframe [src]="currentVideo"  frameborder="0" allowfullscreen webkitallowfullscreen mozallowfullscreen></iframe>
            <br>
            <div [ngClass]="about.box1.body.style" [innerHTML]="languageService.stringsResource[about.box1.body.content]" [appEditableText]="editingMode" [editRoute]="'box1.body'" [element]="about.box1.body" [negative]="true">
            </div>
        </div>
    </div>
    
</div>