import { AfterViewInit, Component, OnInit } from '@angular/core';

import { AngularFireDatabase } from '@angular/fire/database';
import { ImagesService } from '../images.service';
import { LanguageService } from '../language.service';
import { Sponsors } from '../models';
import { interval } from 'rxjs';
import { takeWhile } from 'rxjs/operators';

@Component({
  selector: 'app-sponsors',
  templateUrl: './sponsors.component.html',
  styleUrls: ['./sponsors.component.css']
})
export class SponsorsComponent implements OnInit, AfterViewInit {

  sponsors: Sponsors;
  loading = true;
  headerLoading = true;

  constructor(private languageService: LanguageService, private imagesService: ImagesService, private db: AngularFireDatabase) { }

  ngOnInit() {
    const promisse = this.db.object('components/sponsors').valueChanges();
    promisse.subscribe((raw) => {
      this.loading = false;
      this.sponsors = raw as Sponsors;
    });
  }
  ngAfterViewInit() {
    interval(100)
      .pipe(takeWhile(() => this.headerLoading))
      .subscribe(i => {
        if (this.imagesService.loading || this.loading) { return; }
        this.imagesService.loadHeader(this.imagesService.imagesResource[this.sponsors.img_1.src]);
        this.headerLoading = false;
      });
  }

}
