<app-loading *ngIf="bigLoading"></app-loading>
<div class="modalScreen" (click)="dismiss()">
  <div class="modalWrap">
    <div class="grid">
      <div class="son" *ngFor="let m of models">
        <div class="image-container"> <img alt="" [appEditableImage]="editingMode"
            [editRoute]="'models.'+m.index+'.img'" [image]="m.img" [defaultImage]="''"
            [lazyLoad]="imagesService.imagesResource[m.img.src]"></div>
        <div class="name-space">{{m.name.toUpperCase()}}</div>
        <div class="instagram" (click)="navigate(m.instagram)">
          <div class="instagram-circle"></div>
        </div>
      </div>
      <div>
        <div class="v">
          <div (click)="navigate('votes')">VOTE</div>
        </div>
        <div class="v2">
          <div class="v2a" (click)="navigate('votes')">FOR YOUR FAVORITE
            MODEL</div>
          <div class="v2b" (click)="navigate('votes')">#FIRSTFACES19</div>
        </div>
      </div>

    </div>
    <div class="close" (click)="dismiss()"></div>
  </div>
</div>
