import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject, interval } from 'rxjs';

import { About } from '../models';
import { AngularFireDatabase } from '@angular/fire/database';
import { DomSanitizer } from '@angular/platform-browser';
import { ImagesService } from '../images.service';
import { LanguageService } from '../language.service';
import { takeWhile } from 'rxjs/operators';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css'],
})
export class AboutComponent implements OnInit, AfterViewInit {
  @Input() editingMode: boolean;
  @Input() editablePage;
  @Input() uiChanges: BehaviorSubject<boolean>;

  about: About;
  loading = true;
  imagesLoading = true;
  currentVideo: any;

  constructor(
    public languageService: LanguageService,
    private sanitizer: DomSanitizer,
    private imagesService: ImagesService,
    private db: AngularFireDatabase
  ) { }

  ngOnInit() {
    this.currentVideo = this.sanitizer.bypassSecurityTrustResourceUrl(
      'https://players.brightcove.net/2339105022001/default_default/index.html?videoId=5717558743001'
    );
    if (this.editablePage != null) {
      this.about = this.editablePage as About;
    } else {
      const promisse = this.db.object('pages/about').valueChanges();
      promisse.subscribe(raw => {
        this.loading = false;
        this.about = raw as About;
      });
    }
  }

  ngAfterViewInit() {
    interval(100)
      .pipe(takeWhile(() => this.imagesLoading))
      .subscribe(i => {
        if (this.imagesService.loading || this.loading) {
          return;
        }
        this.loadImages();
        this.imagesLoading = false;
      });
    if (this.editingMode) {
      this.uiChanges.subscribe(changes => {
        if (changes && this.editingMode) {
          this.loadImages();
        }
      });
    }
  }

  loadImages() {
    const sheet = window.document.styleSheets[0] as CSSStyleSheet;
    if (sheet.cssRules) {
      for (let i = 0; i < sheet.cssRules.length; i++) {
        if (sheet.cssRules[i].cssText.includes('.bgImg')) {
          sheet.deleteRule(i);
        }
      }
    } else {
      for (let i = 0; i < sheet.rules.length; i++) {
        if ((sheet.rules[i] as any).includes('.bgImg')) {
          sheet.removeRule(i);
        }
      }
    }
    if (sheet.cssRules) {
      // eslint-disable-next-line max-len
      sheet.insertRule(
        '.bgImg { background: url(\'' +
        this.imagesService.imagesResource[this.about.img_1.src] +
        // eslint-disable-next-line max-len
        '\') no-repeat center center fixed; -webkit-background-size: cover; -moz-background-size: cover; -o-background-size: cover; background-size: cover; }'
      );
    } else {
      // eslint-disable-next-line max-len
      sheet.addRule(
        '.bgImg { background: url(\'' +
        this.imagesService.imagesResource[this.about.img_1.src] +
        // eslint-disable-next-line max-len
        '\') no-repeat center center fixed; -webkit-background-size: cover; -moz-background-size: cover; -o-background-size: cover; background-size: cover; }'
      );
    }
  }
}
