import { Component, OnInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.css']
})
export class LoadingComponent implements OnInit, OnDestroy {
  photosInterval;
  photos = [];
  photoBank = [];

  constructor() { }

  ngOnInit() {
    this.updatePhotos();
    this.photosInterval = setInterval(() => { this.updatePhotos(); }, 500);
  }

  ngOnDestroy() {
    clearInterval(this.photosInterval);
  }
  populatePhotoBank() {
    for (let i = 0; i < 14; i++) {
      this.photoBank[i] = (i + 1);
    }
  }
  updatePhotos() {
    if (this.photoBank.length === 0) {
      this.populatePhotoBank();
    }
    const rand = Math.floor(Math.random() * this.photoBank.length);
    const top = Math.floor(Math.random() * (75 - 25 + 1)) + 25;
    let right = Math.floor(Math.random() * 15) + 1;
    right *= Math.floor(Math.random() * 2) === 1 ? 1 : -1;
    const photo = {};
    photo['link'] = './assets/Landing/Loader/' + this.photoBank[rand] + '.jpg';
    photo['top'] = top;
    photo['right'] = right;
    this.photos.push(photo);
    this.photoBank.splice(rand, 1);
  }
}
