import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject, interval } from 'rxjs';

import { AngularFireDatabase } from '@angular/fire/database';
import { ImagesService } from '../images.service';
import { LanguageService } from '../language.service';
import { Router } from '@angular/router';
import { Schedule } from '../models';
import { takeWhile } from 'rxjs/operators';

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.css'],
})
export class ScheduleComponent implements OnInit, AfterViewInit {
  @Input() editingMode: boolean;
  @Input() editablePage;
  @Input() uiChanges: BehaviorSubject<boolean>;

  schedule: Schedule;
  loading = true;
  headerLoading = true;

  // eslint-disable-next-line max-len
  constructor(
    private router: Router,
    private languageService: LanguageService,
    private imagesService: ImagesService,
    private db: AngularFireDatabase
  ) { }

  ngOnInit() {
    if (this.editablePage != null) {
      this.schedule = this.editablePage as Schedule;
    } else {
      const promisse = this.db.object('pages/schedule').valueChanges();
      promisse.subscribe(raw => {
        this.loading = false;
        this.schedule = raw as Schedule;
      });
    }
  }

  ngAfterViewInit() {
    interval(100)
      .pipe(takeWhile(() => this.headerLoading))
      .subscribe(i => {
        if (this.imagesService.loading || this.loading) {
          return;
        }
        this.imagesService.loadHeader(this.imagesService.imagesResource[this.schedule.img_1.src]);
        this.headerLoading = false;
      });
    if (this.editingMode) {
      this.uiChanges.subscribe(changes => {
        if (changes && this.editingMode) {
          this.schedule = this.editablePage as Schedule;
          this.imagesService.loadHeader(this.imagesService.imagesResource[this.schedule.img_1.src]);
        }
      });
    }
  }

  navigate() {
    window.open('https://www.eventbrite.com/e/miami-fashion-week-2019-tickets-54735468259?aff=ebdssbdestsearch');
  }
}
