<link rel="stylesheet" href="https://use.typekit.net/kxr5yzh.css" />
<div class="background">
    <app-sidebar></app-sidebar>
    <div class="body-wrapper" *ngIf="categorized && categorized.length > 0">
        <div class="header-news">
            <div class="title">
                <span>MIAFW News</span>
            </div>
            <div class="events-banner">
                <div class="events-list">
                    <p class="-subtitle">Influencers Brunch</p>
                    <p class="-subtitle">Closing Party</p>
                    <p class="-subtitle">Benefit Gala</p>
                    <p class="-subtitle">MIAFW @ Art Week Miami</p>
                    <p class="-subtitle">MIAFW Summit</p>
                    <p class="-subtitle">Designers Dinner</p>
                    <p class="-subtitle">Kick-off Party</p>
                    <p class="-subtitle">Press Conference</p>
                    <p class="-subtitle">100 Hours Nonstop</p>
                </div>
                <div class="events-list">
                    <p class="-subtitle">Influencers Brunch</p>
                    <p class="-subtitle">Closing Party</p>
                    <p class="-subtitle">Benefit Gala</p>
                    <p class="-subtitle">MIAFW @ Art Week Miami</p>
                    <p class="-subtitle">MIAFW Summit</p>
                    <p class="-subtitle">Designers Dinner</p>
                    <p class="-subtitle">Kick-off Party</p>
                    <p class="-subtitle">Press Conference</p>
                    <p class="-subtitle">100 Hours Nonstop</p>
                </div>
                <div class="events-list">
                    <p class="-subtitle">Influencers Brunch</p>
                    <p class="-subtitle">Closing Party</p>
                    <p class="-subtitle">Benefit Gala</p>
                    <p class="-subtitle">MIAFW @ Art Week Miami</p>
                    <p class="-subtitle">MIAFW Summit</p>
                    <p class="-subtitle">Designers Dinner</p>
                    <p class="-subtitle">Kick-off Party</p>
                    <p class="-subtitle">Press Conference</p>
                    <p class="-subtitle">100 Hours Nonstop</p>
                </div>
            </div>
            <div class="first-new">
                <div class="content">
                    <span>{{ categorized[blog.sections[0].category[languageService.currentLanguage]][0].date | date }}</span>
                    <span>{{ categorized[blog.sections[0].category[languageService.currentLanguage]][0].title.rendered }}</span>
                    <div class="goto" (click)="goto(categorized[blog.sections[0].category[languageService.currentLanguage]][0].link)" *ngIf="categorized[blog.sections[0].category[languageService.currentLanguage]][0].link">
                        <span>Go to</span><img src="./../../assets/go-to.svg">
                    </div>
                </div>
            </div>
        </div>
        <div class="latest">
            <span>Latest News</span>
        </div>
        <div class="news" *ngFor="let new of categorized[blog.sections[0].category[languageService.currentLanguage]]; let i = index">
            <div class="date">
                <span>{{ new.date | date }}</span>
                <span>{{ new.date | date }}</span>
            </div>
            <div class="content">
                <div class="info">
                    <div class="info-1">
                        <div class="title-new">
                            <span>{{ new.title.rendered }}</span>
                        </div>
                        <div class="author-new">
                            <span>{{ new.author.rendered }}</span>
                        </div>
                    </div>
                    <div class="info-2" (click)="goto(new.link)" *ngIf="new.link">
                        <span>Go to</span><img src="./../../assets/go-to.svg">
                    </div>
                </div>
                <div class="image" [style.backgroundImage]="'url('+ new.gallery[0] +')'">
                </div>
            </div>
        </div>
    </div>
</div>