import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';
import { EditorService } from '../dashboard/editor.service';


@Directive({
    selector: '[appEditableImage]'
})
export class EditableImageDirective {
    constructor(private el: ElementRef, private renderer: Renderer2, private editor: EditorService) {
        this.editor.editingModeEnabled.subscribe((editing) => {
            if (editing) {
                renderer.addClass(el.nativeElement, 'clickable');
                this.afterDiv = this.renderer.createElement('div');
                this.afterDiv.style.position = 'absolute';
                this.afterDiv.style.top = '0';
                this.afterDiv.style.left = '0';
                this.afterDiv.style.right = '0';
                this.afterDiv.style.bottom = '0';
                this.afterDiv.style.backgroundColor = 'rgba(0,0,0,0.6)';
                this.afterDiv.style.opacity = '0';
                this.afterDiv.style.transition = 'all 0.25s';
                this.afterDiv.style.color = 'white';
                this.afterDiv.style['font-family'] = '\'Century Gothic\', sans-serif';
                this.afterDiv.style['font-weight'] = '200';
                this.afterDiv.style['font-size'] = '1em';
                this.afterDiv.style['text-align'] = 'center';
                this.afterDiv.style['pointer-events'] = 'none';


                this.textDiv = this.renderer.createElement('div');
                this.textDiv.style.position = 'relative';
                this.textDiv.style.left = '50%';
                this.textDiv.style.top = '50%';
                this.textDiv.style.transform = 'translate(-50%, -50%)';
                this.textDiv.style['pointer-events'] = 'none';
                this.textDiv.insertAdjacentHTML('beforeend', 'CHANGE IMAGE');
                this.renderer.appendChild(this.afterDiv, this.textDiv);
                this.renderer.appendChild(el.nativeElement, this.afterDiv);

            } else {
                renderer.removeClass(el.nativeElement, 'clickable');
            }
        });
    }

    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input('appEditableImage') active: boolean;
    @Input() editRoute: string;
    @Input() image: any;
    @Input() position: string;
    @Input() deletable = false;

    previousColor: any;
    afterDiv: any;
    textDiv: any;

    @HostListener('click', ['$event'])
    public onClick(event: any): void {
        if (this.active) {
            event.stopPropagation();
            this.editor.startImageEdit.next({ image: this.image, route: this.editRoute, deletable: this.deletable });
        }
    }

    @HostListener('mouseenter', ['$event'])
    public onMouseEnter(event) {
        if (this.active) {
            if (this.position != null) {
                this.textDiv.style.transform = this.position;
            }
            this.afterDiv.style.opacity = '1';
        }
    }

    @HostListener('mouseleave', ['$event'])
    public onMouseLeave(event) {
        if (this.active) {
            this.afterDiv.style.opacity = '0';
        }
    }
}
