<div *ngIf="!languageService.loading && ! hide">
  <div *ngIf="sponsors as sp" style=" padding: 3%;
  position: relative;
  z-index: 1; background-color: white;">
    <!-- <div class="row1a">
      <a *ngFor="let sponsor of sp.main" target="_blank" [href]="sponsor.url" class="clickable"><img
          [src]="sponsor.img_color" alt=""></a>
    </div> -->
    <!-- <div class="row1ab">
      <a *ngFor="let sponsor of sp.media" target="_blank" [href]="sponsor.url" class="clickable"><img
          [src]="sponsor.img_color" alt=""></a>
    </div> -->
    <!-- <div class="row2a">
      <a *ngFor="let sponsor of sp.official" target="_blank" [href]="sponsor.url" class="clickable"><img
          [src]="sponsor.img_color" alt=""></a>
    </div> -->
    <!-- <div class="row3a"> -->
      <!-- <a *ngFor="let sponsor of sp.other" target="_blank" [href]="sponsor.url" class="clickable"><img
          [src]="sponsor.img_color" alt=""></a> -->
      <!-- <app-carrousel-smallpage></app-carrousel-smallpage> -->
    <!-- </div> -->
  </div>
  <div class="footer">
    <a href="https://www.facebook.com/MiamiFashionWeek/" class="noscore" target="_blank" rel="noopener noreferrer">
      <img src="./assets/facebook-logo.png" alt="">
    </a>
    <a href="https://twitter.com/miamifashionwk" class="noscore" target="_blank" rel="noopener noreferrer">
      <img src="./assets/twitter-logo.png" alt="">
    </a>
    <a href="https://www.instagram.com/miamifashionweek/" class="noscore" target="_blank" rel="noopener noreferrer">
      <img src="./assets/instagram-logo.png" alt="">
    </a>
    <a href="https://www.youtube.com/user/MiamiFashionWeek" class="noscore" target="_blank" rel="noopener noreferrer">
      <img src="./assets/youtube-logo.png" alt="">
    </a>
    <br>
    <a class="link" style="font-size: 1.7em;" href="/about">{{languageService.stringsResource['appct1']}}</a> |
    <a class="link" style="font-size: 1.7em;" href="/contact">{{languageService.stringsResource['appct2']}}</a>
    <br> &copy; {{languageService.stringsResource['appct3']}}
  </div>
</div>
