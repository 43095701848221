import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { LanguageService } from '../language.service';
import { ImagesService } from '../images.service';
import { BehaviorSubject } from 'rxjs';
import { interval } from 'rxjs';
import { takeWhile } from 'rxjs/operators';

@Component({
  selector: 'app-streaming',
  templateUrl: './streaming.component.html',
  styleUrls: ['./streaming.component.css'],
})
export class StreamingComponent implements OnInit {
  constructor(private db: AngularFireDatabase, public languageService: LanguageService, public imagesService: ImagesService) {}

  ngOnInit() {}
}
