import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { interval } from 'rxjs';
import { take } from 'rxjs/operators';
import { ImagesService } from '../images.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-main-modal',
  templateUrl: './main-modal.component.html',
  styleUrls: ['./main-modal.component.css'],
})
export class MainModalComponent implements OnInit {
  firstfaces: any;
  models = [];
  bigLoading = true;

  @Output() onclose: EventEmitter<boolean> = new EventEmitter(null);

  constructor(public imagesService: ImagesService, private db: AngularFireDatabase, private router: Router) {}

  ngOnInit() {
    const promisse = this.db.object('pages/firstfacesvotes2019').valueChanges();
    promisse.subscribe(raw => {
      this.firstfaces = raw;

      if (this.firstfaces.models !== undefined && this.firstfaces.models != null) {
        this.firstfaces.models = this.firstfaces.models.map((m, i) => Object.assign(m, { index: i }));
        for (let i = 0; i < 9; i++) {
          if (this.models.length < 9) {
            const rand = this.firstfaces.models[Math.floor(Math.random() * this.firstfaces.models.length)];
            this.models.push(rand);
          }
        }
        this.bigLoading = false;
      }
    });
  }

  dismiss() {
    this.onclose.emit(false);
  }
  navigate(path: string) {
    if (path != null) {
      if (path.indexOf('http') > -1) {
        // window.location.href = path;
        window.open(path);
      } else {
        this.router.navigateByUrl('/' + path);
      }
    }
  }
}
