import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { take, takeWhile } from 'rxjs/operators';

import { AngularFireDatabase } from '@angular/fire/database';
import { BehaviorSubject } from 'rxjs';
import { ImagesService } from '../images.service';
import { LanguageService } from '../language.service';
import { PostsService } from '../posts.service';
import { Router } from '@angular/router';
import { interval } from 'rxjs';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss', '../scss/app.scss', '../scss/index.scss'],
})
export class NewsComponent implements OnInit, AfterViewInit {
  @Input() editingMode: boolean;
  @Input() editablePage;
  @Input() uiChanges: BehaviorSubject<boolean>;
  @Input() pause: boolean;

  @Output() editPost = new EventEmitter<any>();

  blog: any;
  loading = true;
  loadingCategories = 1;
  imagesLoading = true;

  loadCount = [15, 6, 3, 1, 4];
  latest: Array<any>;
  categorized: Array<Array<any>> = [];
  term: string;
  currentNumberPages: number = 5;
  currentLang;

  news = [
    {
      title: 'Miami Fashion Week June 2020 Handbook',
      author: 'Haute Living',
      date: 1597941943000,
      url: 'https://www.google.com',
      image: ''
    },
    {
      title: 'Miami Fashion Week June 2020 Handbook',
      author: 'Haute Living',
      date: 1597941943000,
      url: 'https://www.google.com',
      image: ''
    },
    {
      title: 'Miami Fashion Week June 2020 Handbook',
      author: 'Haute Living',
      date: 1597941943000,
      url: 'https://www.google.com',
      image: ''
    },
    {
      title: 'Miami Fashion Week June 2020 Handbook',
      author: 'Haute Living',
      date: 1597941943000,
      url: 'https://www.google.com',
      image: ''
    },
    {
      title: 'Miami Fashion Week June 2020 Handbook',
      author: 'Haute Living',
      date: 1597941943000,
      url: 'https://www.google.com',
      image: ''
    }
  ]

  events = [
    {
      name: '100 Hours Nonstop - “Volver a vivir…Unidos!”',
      video: '430219219',
      link: 'https://www.youtube.com/watch?v=ddvRN0eg9_0&list=PLsFCLXOqTbqlJ5ywDww1xrJLyrwq7oGDf',
      linkText: 'Watch now',
      dates: '',
    }, {
      name: 'The Runways',
      video: '425645781',
      link: '',
      linkText: 'Buy tickets',
      dates: '',
    },
    {
      name: 'Benefit Gala',
      video: '425643102',
      link: '',
      linkText: 'Buy tickets',
      dates: '',
    },
    {
      name: 'MIAFW Summit',
      video: '425643292',
      link: '',
      linkText: 'Buy tickets',
      dates: '',
    },
    {
      name: 'Designers Dinner',
      video: '425643164',
      link: '',
      linkText: 'Buy tickets',
      dates: '',
    },
    {
      name: 'MIAFW at Art Week',
      video: '425643080',
      link: '',
      linkText: 'Buy tickets',
      dates: '',
    },
    {
      name: 'First Faces',
      video: '425643207',
      link: '',
      linkText: 'Buy tickets',
      dates: '',
    },
    {
      name: 'Kick-off Party',
      video: '425643237',
      link: '',
      linkText: 'Buy tickets',
      dates: '',
    },
    {
      name: 'Influencers Brunch',
      video: '425643215',
      link: '',
      linkText: 'Buy tickets',
      dates: '',
    },
    {
      name: 'Press Conference',
      video: '425643260',
      link: '',
      linkText: 'Buy tickets',
      dates: '',
    },
    {
      name: 'Closing Party',
      video: '425643129',
      link: '',
      linkText: 'Buy tickets',
      dates: '',
    }
  ];

  constructor(
    public languageService: LanguageService,
    public imagesService: ImagesService,
    private db: AngularFireDatabase,
    private postsService: PostsService,
    private router: Router
  ) {
    languageService.notifier.subscribe(lang => {
      if (this.blog != null && lang != null && !this.pause) {
        this.loadPosts();
      }
    });
  }

  ngOnInit() {
    this.languageService.notifier.subscribe((lang) => {
      this.currentLang = lang;
    });
    if (this.editablePage != null) {
      this.blog = this.editablePage;
    } else {
      const promisse = this.db
        .object('pages/press')
        .valueChanges()
        .pipe(take(1));
      promisse.subscribe(raw => {
        this.blog = raw;
        for (let i = 0; i < this.blog.sections.length; i++) {
          if (this.blog.sections[i].category != null && this.blog.sections[i].category[this.languageService.currentLanguage] != null) {
            debugger
            // eslint-disable-next-line max-len
            this.postsService.getPostsCategory(
              this.languageService.currentLanguage,
              this.blog.sections[i].category[this.languageService.currentLanguage],
              (posts, category) => {

                this.categorized[category] = posts;
                this.loadingCategories -= 1;
              },
              this.loadCount[i]
            );
          }
        }
      });
      this.postsService.getAllPosts(
        this.languageService.currentLanguage,
        posts => {
          this.loading = false;
          this.latest = posts;
        },
        this.loadCount[0]
      );
    }
  }

  goto(url) {
    window.open(url, "_blank");
  }

  ngAfterViewInit() {
    interval(1000)
      .pipe(takeWhile(() => this.imagesLoading))
      .subscribe(i => {
        if (this.imagesService.loading || this.loading) {
          return;
        }
        this.loadImages();
        this.imagesLoading = false;
      });
    if (this.editingMode) {
      this.uiChanges.subscribe(changes => {
        if (changes && this.editingMode) {
          this.loadImages();
        }
      });
    }
    // this.postsService.loadLegacyMedia();
    // this.postsService.loadLegacyPosts();
    // this.postsService.orginicePosts();
    // this.postsService.changeLegacyMediaURL();
  }

  ngOnDestroy() {
    const sheet = window.document.styleSheets[0] as CSSStyleSheet;
    if (sheet.cssRules) {
      for (let i = 0; i < sheet.cssRules.length; i++) {
        if (sheet.cssRules[i].cssText.includes('.headerImage')) {
          sheet.deleteRule(i);
        }
      }
    } else {
      for (let i = 0; i < sheet.rules.length; i++) {
        if ((sheet.rules[i] as any).includes('.headerImage')) {
          sheet.removeRule(i);
        }
      }
    }
  }

  moreArticles() {
    this.currentNumberPages += 5;
  }

  loadPosts() {
    this.postsService.getAllPosts(
      this.languageService.currentLanguage,
      posts => {
        this.loading = false;
        this.latest = posts;
      },
      this.loadCount[0]
    );

    for (let i = 1; i < this.blog.sections.length; i++) {
      if (this.blog.sections[i].category != null && this.blog.sections[i].category[this.languageService.currentLanguage] != null) {
        // eslint-disable-next-line max-len
        this.postsService.getPostsCategory(
          this.languageService.currentLanguage,
          this.blog.sections[i].category[this.languageService.currentLanguage],
          (posts, category) => {
            this.categorized[category] = posts;
            this.loadingCategories -= 1;

          },
          this.loadCount[i]
        );
      }
    }
  }

  loadImages() {
    const sheet = window.document.styleSheets[0] as CSSStyleSheet;
    if (sheet.cssRules) {
      for (let i = 0; i < sheet.cssRules.length; i++) {
        if (sheet.cssRules[i].cssText.includes('.headerImage')) {
          sheet.deleteRule(i);
        }
      }
    } else {
      for (let i = 0; i < sheet.rules.length; i++) {
        if ((sheet.rules[i] as any).includes('.headerImage')) {
          sheet.removeRule(i);
        }
      }
    }
    if (sheet.cssRules) {
      // eslint-disable-next-line max-len
      sheet.insertRule(
        '.headerImage { background: url(\'' +
        this.imagesService.imagesResource[this.blog.img_1.src] +
        '\') no-repeat center center fixed; -webkit-background-size: cover; ' +
        '-moz-background-size: cover; -o-background-size: cover; background-size: cover; margin-bottom: 1%;}'
      );
    } else {
      // eslint-disable-next-line max-len
      sheet.addRule(
        '.headerImage { background: url(\'' +
        this.imagesService.imagesResource[this.blog.img_1.src] +
        '\') no-repeat center center fixed; -webkit-background-size: cover;' +
        ' -moz-background-size: cover; -o-background-size: cover; background-size: cover; margin-bottom: 1%;}'
      );
    }
  }

  encodeURIComponent(s) {
    return encodeURIComponent(s);
  }

  openPost(post: any, event?: any) {
    if (event !== undefined) {
      event.preventDefault();
    }
    if (this.editingMode) {
      this.startEditPost(post, null);
    } else {
      const sl = encodeURIComponent(post.slug)
        .split('?')
        .join('%3F');
      // this.router.navigateByUrl('/' + sl);
      this.router.navigateByUrl('view-post/' + sl);
    }
  }

  loadBGImage(post: any, category: string, index: number) {
    if (post.thumbnail == null) {
      return '';
    }

    const sheet = window.document.styleSheets[0] as CSSStyleSheet;
    if (sheet.cssRules) {
      for (let i = 0; i < sheet.cssRules.length; i++) {
        if (sheet.cssRules[i].cssText.includes('.' + category + index)) {
          return category + index;
        }
      }
    } else {
      for (let i = 0; i < sheet.rules.length; i++) {
        if ((sheet.rules[i] as any).includes('.' + category + index)) {
          return category + index;
        }
      }
    }
    if (sheet.cssRules) {
      // eslint-disable-next-line max-len
      sheet.insertRule('.' + category + index + '{ background-image: url(\'' + post.thumbnail + '\'); }');
    } else {
      // eslint-disable-next-line max-len
      sheet.addRule('.' + category + index + '{ background-image: url(\'' + post.thumbnail + '\'); }');
    }

    return category + index;
  }

  viewMore(category: any) {
    this.router.navigateByUrl('/posts/' + category);
  }

  startEditPost(post: any, event: any) {
    this.editPost.emit(post);
    if (event != null) {
      event.preventDefault();
    }
  }

  navigate(path: string) {
    if (path != null) {
      if (path.indexOf('http') > -1) {
        window.open(path);
      } else {
        this.router.navigateByUrl('/' + path);
      }
    }
  }
}
