import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'notnull',
  pure: false
})
export class NotnullPipe implements PipeTransform {

  transform(array: Array<any>) {
    return array === undefined ? [] : array.filter(_ => (_ !== undefined && _ != null));
  }

}
