<div class="loadingScreen">
  <img src="/assets/Landing/Logo-Black.png" class="logo-loader" alt="" />
  <div class="loader-img-wrapper">
    <img
      *ngFor="let photo of photos"
      [src]="photo['link']"
      [style.top.%]="photo['top']"
      [style.right.%]="photo['right']"
    />
  </div>
</div>
