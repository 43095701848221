<div *ngIf="!loading">
  <div class="section header headerImage">
  </div>
  <div class="section hAuto p20 txtCenter">
    <div [ngClass]="sponsors.mainTitle.style" class="underline">
      <span>{{languageService.stringsResource[sponsors.mainTitle.content]}}</span></div>
    <div class="row1">
      <a *ngFor="let sponsor of sponsors.main" target="_blank" [href]="sponsor.url" class="clickable"><img
          [src]="sponsor.img_color" alt=""></a>
      <a *ngFor="let sponsor of sponsors.media" target="_blank" [href]="sponsor.url" class="clickable"><img
          [src]="sponsor.img_color" alt=""></a>
    </div>
    <div [ngClass]="sponsors.officialTitle.style" class="underline">
      <span>{{languageService.stringsResource[sponsors.officialTitle.content]}}</span></div>
    <div class="row2">
      <a *ngFor="let sponsor of sponsors.official" target="_blank" [href]="sponsor.url" class="clickable"><img
          [src]="sponsor.img_color" alt=""></a>
    </div>
    <!-- <div [ngClass]="sponsors.mediaTitle.style" class="underline">
      <span>{{languageService.stringsResource[sponsors.mediaTitle.content]}}</span></div>
    <div class="row2">
      <a *ngFor="let sponsor of sponsors.media" target="_blank" [href]="sponsor.url" class="clickable"><img
          [src]="sponsor.img_color" alt=""></a>
    </div> -->
    <div [ngClass]="sponsors.otherTitle.style" class="underline">
      <span>{{languageService.stringsResource[sponsors.otherTitle.content]}}</span></div>
    <div class="row2">
      <a *ngFor="let sponsor of sponsors.other" target="_blank" [href]="sponsor.url" class="clickable"><img
          [src]="sponsor.img_color" alt=""></a>
    </div>
  </div>
</div>
