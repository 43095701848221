import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { HttpClient } from '@angular/common/http';
import { take } from 'rxjs/operators';

@Injectable()
export class PostsService {

  constructor(private db: AngularFireDatabase, private http: HttpClient) { }

  public getAllPosts(lang: string, callback: (posts: Array<any>) => void, perPage?: number) {
    if (perPage == null) { perPage = 10; }

    this.db.list('/posts/' + lang, ref => ref.limitToLast(perPage)).valueChanges().pipe(take(1)).subscribe((posts) => {
      const realPosts = [];
      const fastPosts = [];
      if (posts.length === 0) {
        callback([]);
        return;
      }
      for (const post of posts) {
        if ((post as any).thumbnail == null) {
          this.getLegacyMediaThumbnail((post as any).id, (media) => {
            if (media != null) {
              (post as any).thumbnail = media;
            }
            realPosts.unshift(post);
            if (fastPosts.length + realPosts.length === posts.length) {
              callback(fastPosts.concat(realPosts));
            }
          }, (post as any).featured_media);
        } else {
          fastPosts.unshift(post);
          if (fastPosts.length + realPosts.length === posts.length) {
            callback(fastPosts.concat(realPosts));
          }
        }
      }
    });
  }

  public getPostsCategory(lang: string, category: number, callback: (posts: Array<any>, category: number) => void, perPage?: number) {
    if (perPage == null) { perPage = 5; }

    // eslint-disable-next-line max-len
    this.db.list('/posts/' + lang, ref => ref.orderByChild('categories/0').equalTo(category).limitToLast(perPage))
      .valueChanges().pipe(take(1)).subscribe((posts) => {
        if (posts == null || posts.length === 0) {
          callback([], category);
          return;
        }
        const realPosts = [];
        const fastPosts = [];
        for (const post of posts) {
          if ((post as any).thumbnail == null) {
            this.getLegacyMediaThumbnail((post as any).id, (media) => {
              if (media != null) {
                (post as any).thumbnail = media;
              }
              realPosts.unshift(post);
              if (fastPosts.length + realPosts.length === posts.length) {
                callback(fastPosts.concat(realPosts), category);
              }
            }, (post as any).featured_media);
          } else {
            fastPosts.unshift(post);
            if (fastPosts.length + realPosts.length === posts.length) {
              callback(fastPosts.concat(realPosts), category);
            }
          }
        }
      });
  }

  public getPostWithLink(link: string, callback: (post: any) => void) {
    // eslint-disable-next-line max-len
    this.db.list('/posts/en', ref => ref.orderByChild('slug').equalTo(link))
      .valueChanges().pipe(take(1)).subscribe((posts) => {
        if (posts == null || posts === undefined || posts.length === 0) {
          this.db.list('/posts/es', ref => ref.orderByChild('slug').equalTo(link))
            .valueChanges().pipe(take(1)).subscribe((postsES) => {
              if (postsES == null || postsES === undefined || postsES.length === 0) {
                callback(null);
              } else {
                callback(postsES[0]);
              }
            });
        } else {
          callback(posts[0]);
        }
      });
  }

  public getLegacyMedia(id: number, callback: (media: Array<any>) => void, fallbackID?: string) {
    (id);
    this.db.list('/legacyMedia', ref => ref.orderByChild('post').equalTo(id))
      .valueChanges().pipe(take(1)).subscribe((media) => {
        //    (media);
        if (media == null || media === undefined || media.length === 0) {
          // eslint-disable-next-line max-len
          if (fallbackID != null) {
            this.db.list('/legacyMedia', ref => ref.orderByChild('id').equalTo(fallbackID))
              .valueChanges().pipe(take(1)).subscribe((fallbackMedia) => {
                if (fallbackMedia == null || fallbackMedia === undefined || fallbackMedia.length === 0) {
                  callback(null);
                } else {
                  callback(fallbackMedia);
                }
              });
          } else {
            callback(null);
          }
        } else {
          callback(media);
        }
      });
  }

  public getLegacyMediaThumbnail(id: number, callback: (media: string) => void, fallbackID?: string) {
    this.db.list('/legacyMedia', ref => ref.orderByChild('post').equalTo(id))
      .valueChanges().pipe(take(1)).subscribe((media) => {
        if (media == null || media === undefined || media.length === 0) {
          if (fallbackID != null) {
            // eslint-disable-next-line max-len
            this.db.list('/legacyMedia', ref => ref.orderByChild('id').equalTo(fallbackID))
              .valueChanges().pipe(take(1)).subscribe((fallbackMedia) => {
                if (fallbackMedia == null || fallbackMedia === undefined || fallbackMedia.length === 0) {
                  ('No Media found with ID: ' + id);
                  callback(null);
                } else {
                  // eslint-disable-next-line max-len
                  callback(((fallbackMedia[0] as any).source_url as string));
                }
              });
          } else {
            ('No Media found for post: ' + id);
            callback(null);
          }
        } else {
          // eslint-disable-next-line max-len
          callback(((media[0] as any).source_url as string));
        }
      });
  }

  private loadLegacyMedia() {
    const callback = (data: Array<any>, page: number) => {
      if (data.length === 0) {
        ('DONE LOADING');
        // this.db.object('/').update({legacyMedia: mediaLegacy});
      } else {
        // mediaLegacy.concat(data);
        const updates = {};
        for (let i = 0; i < data.length; i++) {
          const d = data[i];
          updates[((page - 1) * 100) + (i + 9556) + ''] = d;
        }
        this.db.object('/legacyMedia').update(updates);
        this.loadChunkMedia(page + 1, callback);
      }
    };
    // 45
    this.loadChunkMedia(45, callback);
  }

  private loadChunkMedia(page: number, callback: (media: Array<any>, currentPage: number) => void) {
    this.http.get<Array<any>>('http://52.200.248.177/es/wp-json/wp/v2/media?per_page=100&page=' + page).subscribe(
      data => {
        callback(data, page);
        ('Loaded Media Page ES: ' + page);
      },
      err => {
        console.log('Error occured.');
      }
    );
  }

  private loadLegacyPosts() {
    const callback = (data: Array<any>, page: number) => {
      if (data.length === 0) {
        console.log('DONE LOADING');
      } else {
        const updates = {};
        for (let i = 0; i < data.length; i++) {
          const d = data[i];
          updates[((page - 1) * 100) + i + ''] = d;
        }
        this.db.object('/posts/es').update(updates);
        this.loadChunkPosts(page + 1, callback);
      }
    };
    // this.db.object('/posts/').remove();
    // this.db.object('/posts2/').remove();
    this.loadChunkPosts(1, callback);
  }

  private loadChunkPosts(page: number, callback: (posts: Array<any>, currentPage: number) => void) {
    this.http.get<Array<any>>('http://52.200.248.177/es/wp-json/wp/v2/posts?per_page=100&page=' + page).subscribe(
      // this.http.get<Array<any>>('http://52.200.248.177/wp-json/wp/v2/posts?per_page=100&page=' + page).subscribe(
      data => {
        callback(data, page);
        ('Loaded Posts Page: ' + page);
      },
      err => {
        console.log('Error occured.');
      }
    );
  }

  private orginicePosts() {
    this.db.list('/posts/es')
      .valueChanges().pipe(take(1)).subscribe((posts) => {
        if (posts == null || posts === undefined || posts.length === 0) {
          // Paila
        } else {
          this.db.object('/posts').update({ es: posts.reverse() });
          ('Done');
        }
      });
  }

  private changeLegacyMediaURL() {
    this.db.list<any>('/legacyMediaTest').valueChanges().pipe(take(1)).subscribe((media) => {
      if (media == null || media === undefined || media.length === 0) {
        // Paila
        ('No Data');
      } else {
        let i = 0;
        for (const m of media) {
          // if (m.source_url !== undefined && m.source_url !== null) {
          // eslint-disable-next-line max-len
          //   m.source_url = m.source_url.split('http://52.200.248.177/wp-content/uploads/').join('https://s3-us-west-2.amazonaws.com/miamifashionweek/wp-content/');
          // }
          const update = {};
          update[`${i}`] = m;
          this.db.object('/legacyMedia').update(update);
          i++;
          //    (m);
        }
        //    (mediaLegacy);
        ('Done');
      }
    });
  }

}
