<app-loading *ngIf="loading || imagesLoading || loadingCategories > 0"></app-loading>
<link rel="stylesheet" href="https://use.typekit.net/kxr5yzh.css" />
<div class="flex">
    <app-sidebar></app-sidebar>
    <div *ngIf="!loading && !imagesLoading && loadingCategories <= 0" class="body-wrapper"
        [class.editingPadding]="editingMode" [class.noscroll]="pause">
        <div class="section header headerImage events-banner" [appScrollableHeader]="true"
            [appEditableImage]="editingMode" [editRoute]="'img_1'" [image]="blog.img_1">
            <div class="events-list">
                <p class="-subtitle"><img src="./../../assets/image1.svg"></p>
                <p class="-subtitle"><img src="./../../assets/image5.svg"></p>
                <p class="-subtitle"><img src="./../../assets/image6.svg"></p>
                <p class="-subtitle"><img src="./../../assets/image1.svg"></p>
                <p class="-subtitle"><img src="./../../assets/image5.svg"></p>
                <p class="-subtitle"><img src="./../../assets/image6.svg"></p>
            </div>
            <div class="events-list">
                <p class="-subtitle"><img src="./../../assets/image1.svg"></p>
                <p class="-subtitle"><img src="./../../assets/image5.svg"></p>
                <p class="-subtitle"><img src="./../../assets/image6.svg"></p>
                <p class="-subtitle"><img src="./../../assets/image1.svg"></p>
                <p class="-subtitle"><img src="./../../assets/image5.svg"></p>
                <p class="-subtitle"><img src="./../../assets/image6.svg"></p>
            </div>
            <div class="events-list">
                <p class="-subtitle"><img src="./../../assets/image1.svg"></p>
                <p class="-subtitle"><img src="./../../assets/image5.svg"></p>
                <p class="-subtitle"><img src="./../../assets/image6.svg"></p>
                <p class="-subtitle"><img src="./../../assets/image1.svg"></p>
                <p class="-subtitle"><img src="./../../assets/image5.svg"></p>
                <p class="-subtitle"><img src="./../../assets/image6.svg"></p>
            </div>
        </div>
        <div class="blog-wrapper">
            <h1 class="blog-title" [ngClass]="blog.sections[0].title.style" [appEditableText]="editingMode"
                [editRoute]="'sections.0.title'" [element]="blog.sections[0].title">
                {{ languageService.stringsResource[blog.sections[0].title.content] }}
            </h1>
            <div class="events-banner">
                <div class="events-list">
                    <p class="-subtitle"><img src="./../../assets/image1.svg"></p>
                    <p class="-subtitle"><img src="./../../assets/image2.svg"></p>
                    <p class="-subtitle"><img src="./../../assets/image3.svg"></p>
                    <p class="-subtitle"><img src="./../../assets/image1.svg"></p>
                    <p class="-subtitle"><img src="./../../assets/image2.svg"></p>
                    <p class="-subtitle"><img src="./../../assets/image3.svg"></p>
                </div>
                <div class="events-list">
                    <p class="-subtitle"><img src="./../../assets/image1.svg"></p>
                    <p class="-subtitle"><img src="./../../assets/image2.svg"></p>
                    <p class="-subtitle"><img src="./../../assets/image3.svg"></p>
                    <p class="-subtitle"><img src="./../../assets/image1.svg"></p>
                    <p class="-subtitle"><img src="./../../assets/image2.svg"></p>
                    <p class="-subtitle"><img src="./../../assets/image3.svg"></p>
                </div>
                <div class="events-list">
                    <p class="-subtitle"><img src="./../../assets/image1.svg"></p>
                    <p class="-subtitle"><img src="./../../assets/image2.svg"></p>
                    <p class="-subtitle"><img src="./../../assets/image3.svg"></p>
                    <p class="-subtitle"><img src="./../../assets/image1.svg"></p>
                    <p class="-subtitle"><img src="./../../assets/image2.svg"></p>
                    <p class="-subtitle"><img src="./../../assets/image3.svg"></p>
                </div>
            </div>
            <div class="blog-title-2">
                <span>Latest press releases</span>
            </div>
            <div class="posts-wrapper">
                <ng-container
                    *ngFor="let style of categorized[blog.sections[0].category[languageService.currentLanguage]]; let i = index">
                    <div class="post" *ngIf="i < currentNumberPages" (click)="openPost(style, $event)">

                        <div class="author">
                            <p class="post-author">
                                {{style.author.rendered}}
                            </p>

                            <p class="date">
                                20 june 2020
                            </p>
                        </div>
                        <div class="title">
                            <h1 class="post-title">
                                {{style.title.rendered}}
                            </h1>

                            <h1 class="download" (click)="downloadPdf(style); $event.stopPropagation()">
                                Download <img src="./../../assets/download.svg">
                            </h1>
                        </div>

                    </div>
                </ng-container>
            </div>
            <div class="more" (click)="moreArticles()"
                *ngIf="this.currentNumberPages < this.categorized[this.blog.sections[0].category[this.languageService.currentLanguage]].length;">
                <span>Watch more</span>
            </div>
            <!-- <button class="btn-more" (click)="moreArticles()"
                *ngIf="this.currentNumberPages < this.categorized[this.blog.sections[0].category[this.languageService.currentLanguage]].length;">
                <span>More</span>
            </button> -->
        </div>
    </div>
</div>