import { AfterViewInit, Component, OnInit } from '@angular/core';
import { CarrouselPage, Sponsors } from '../models';
import { NavigationEnd, Router } from '@angular/router';
import { filter, takeWhile } from 'rxjs/operators';

import { AngularFireDatabase } from '@angular/fire/database';
import { CarrouselImageService } from '../carruselpage-small/carruselimage.service';
import { ImagesService } from '../images.service';
import { LanguageService } from '../language.service';
import { interval } from 'rxjs';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
})
export class FooterComponent implements OnInit, AfterViewInit {
  sponsors: Sponsors;
  loading = true;
  headerLoading = true;
  obj: any;
  hide = true;

  constructor(
    private carrouselService: CarrouselImageService,
    public languageService: LanguageService,
    private imagesService: ImagesService,
    private db: AngularFireDatabase,
    private router: Router
  ) { }

  ngOnInit() {
    const promisse = this.db.object('components/sponsors').valueChanges();
    promisse.subscribe(raw => {
      this.loading = false;
      this.sponsors = raw as Sponsors;
      this.obj = { images: [], path: 'footer' };
      this.sponsors.other.forEach(el => {
        this.obj.images.push({ src: el.img_color, url: el.url });
      });
      this.carrouselService.currentCarrousel.next(this.obj as CarrouselPage);
    });


    this.router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe((val) => {
      if (val['url'] && val['url'] !== undefined && val['url'] !== null && val['url'] === '/') {
        this.hide = true;
      } else {
        this.hide = false;
      }
    });
  }

  ngAfterViewInit() {
    interval(100)
      .pipe(takeWhile(() => this.headerLoading))
      .subscribe(i => {
        if (this.imagesService.loading || this.loading) {
          return;
        }
        this.imagesService.loadHeader(this.imagesService.imagesResource[this.sponsors.img_1.src]);
        this.headerLoading = false;
      });
  }
}
