import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';
import { EditorService } from '../dashboard/editor.service';
import { timer } from 'rxjs';

@Directive({
    selector: '[appEditableDay]'
})
export class EditableDayDirective {
    constructor(private el: ElementRef, private renderer: Renderer2, private editor: EditorService) {
        this.editor.editingModeEnabled.subscribe((editing) => {
            if (editing) {
                renderer.addClass(el.nativeElement, 'clickable');
                this.addEvent = this.renderer.createElement('div');
                this.addEvent.insertAdjacentHTML('beforeend', 'ADD EVENT');
                renderer.addClass(this.addEvent, 'addEvent');

                this.renderer.listen(this.addEvent, 'click', (evt) => {
                    evt.stopPropagation();
                    this.editor.startEventEdit.next({
                        route: this.editRoute + '.events.' + (this.day.events != null ? this.day.events.length : 0)
                    });
                });

                this.renderer.listen(this.addEvent, 'mouseenter', (evt) => {
                    this.onMouseLeave(evt);
                });

                this.renderer.listen(this.addEvent, 'mouseleave', (evt) => {
                    this.onMouseEnter(evt);
                });

                timer(2000).subscribe(() => {
                    if (this.el.nativeElement.style.opacity !== '0') {
                        this.renderer.appendChild(this.el.nativeElement, this.addEvent);
                    }
                });
            } else {
                renderer.removeClass(el.nativeElement, 'clickable');
            }
        });
    }

    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input('appEditableDay') active: boolean;
    @Input() editRoute: string;
    @Input() day: any;
    @Input() defaultStyle: string;


    addEvent: any;

    previousColor: any;

    @HostListener('click', ['$event'])
    public onClick(event: any): void {
        if (this.active) {
            event.stopPropagation();
            this.editor.startDayEdit.next({ day: this.day, route: this.editRoute, defaultStyle: this.defaultStyle });
        }
    }

    @HostListener('mouseenter', ['$event'])
    public onMouseEnter(evente) {
        if (this.active) {
            this.el.nativeElement.style.backgroundColor = 'black';
            this.previousColor = this.el.nativeElement.style.color;
            // this.previousIndex = this.el.nativeElement.style['z-index'];
            this.el.nativeElement.style.color = 'white';
            // this.el.nativeElement.style['z-index'] = '100';
            this.el.nativeElement.style.cursor = 'ponter';
            this.renderer.appendChild(this.el.nativeElement, this.addEvent);
        }
    }

    @HostListener('mouseleave', ['$event'])
    public onMouseLeave(event) {
        if (this.active) {
            event.stopPropagation();
            this.el.nativeElement.style.backgroundColor = null;
            this.el.nativeElement.style.color = 'black';
            // this.el.nativeElement.style['z-index'] = this.previousIndex;
            this.el.nativeElement.style.cursor = null;
        }
    }
}
