import { AfterViewInit, Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { LanguageService } from '../language.service';
import Player from '@vimeo/player';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['../scss/app.scss', '../scss/index.scss'],
})
export class HomeComponent implements OnInit, AfterViewInit, OnDestroy {


  menuCol: string;
  currentLang;

  menuOpen = false;
  morePosts = false;
  designedToSurpriseVideo = -1;
  videoSpace = false;
  currentVideoSpace = '';
  events = [
    {
      name: '100 Hours Nonstop - “Volver a vivir…Unidos!”',
      video: '430219219',
      link: 'https://www.youtube.com/watch?v=ddvRN0eg9_0&list=PLsFCLXOqTbqlJ5ywDww1xrJLyrwq7oGDf',
      linkText: 'Watch now',
      dates: '',
    }, {
      name: 'The Runways',
      video: '425645781',
      link: '',
      linkText: 'Buy tickets',
      dates: '',
    },
    {
      name: 'Benefit Gala',
      video: '425643102',
      link: '',
      linkText: 'Buy tickets',
      dates: '',
    },
    {
      name: 'MIAFW Summit',
      video: '425643292',
      link: '',
      linkText: 'Buy tickets',
      dates: '',
    },
    {
      name: 'Designers Dinner',
      video: '425643164',
      link: '',
      linkText: 'Buy tickets',
      dates: '',
    },
    {
      name: 'MIAFW at Art Week',
      video: '425643080',
      link: '',
      linkText: 'Buy tickets',
      dates: '',
    },
    {
      name: 'First Faces',
      video: '425643207',
      link: '',
      linkText: 'Buy tickets',
      dates: '',
    },
    {
      name: 'Kick-off Party',
      video: '425643237',
      link: '',
      linkText: 'Buy tickets',
      dates: '',
    },
    {
      name: 'Influencers Brunch',
      video: '425643215',
      link: '',
      linkText: 'Buy tickets',
      dates: '',
    },
    {
      name: 'Press Conference',
      video: '425643260',
      link: '',
      linkText: 'Buy tickets',
      dates: '',
    },
    {
      name: 'Closing Party',
      video: '425643129',
      link: '',
      linkText: 'Buy tickets',
      dates: '',
    }
  ];

  currentVideo = 0;
  eventsInterval;

  carrouselChangedByUser = false;
  currentWidth;
  currentEventWitdh;
  maxScrollLeft;
  currentScrollLeft;

  @ViewChild('runwayPlayer') playerContainer;
  private player: Player;


  constructor(
    public languageService: LanguageService,
    public router: Router,
  ) { }


  ngOnInit() {
    this.currentWidth = window.innerWidth;
    this.setupCarrousel();

    this.eventsInterval = setInterval(() => { this.updateCurrentVideo(); }, 15000);

    this.languageService.notifier.subscribe((lang) => {
      this.currentLang = lang;
    });
  }


  ngAfterViewInit() {
    this.player = new Player(this.playerContainer.nativeElement);
    this.player.loadVideo(430171700);
  }

  ngOnDestroy() {
    clearInterval(this.eventsInterval);
  }

  @HostListener('window:resize', ['$event'])
  windowOnResize(event) {
    this.currentWidth = window.innerWidth;
    this.setupCarrousel();
  }

  setupCarrousel() {
    if (this.currentWidth >= 900) {
      this.currentEventWitdh = this.currentWidth * 0.27;
      this.menuCol = 'events';
      this.maxScrollLeft = this.currentEventWitdh * 9;

    } else {
      this.currentEventWitdh = this.currentWidth * 0.69;
      this.menuCol = 'home';
      this.maxScrollLeft = this.currentEventWitdh * 10;
    }
    this.currentScrollLeft = this.currentVideo * this.currentEventWitdh;
  }


  resetVideoInterval() {
    clearInterval(this.eventsInterval);
    this.eventsInterval = setInterval(() => { this.updateCurrentVideo(); }, 15000);
  }

  updateCurrentVideo() {
    this.currentVideo === (this.events.length - 1) ? this.currentVideo = 0 : this.currentVideo++;
    if (!this.carrouselChangedByUser) {
      this.scrollRight();
    }
  }

  scrollRight() {
    if (this.currentScrollLeft < this.maxScrollLeft) {
      this.currentScrollLeft += this.currentEventWitdh;
    } else if (this.carrouselChangedByUser || (!this.carrouselChangedByUser && this.currentVideo === 0)) {
      this.currentScrollLeft = 0;
    }
    document.getElementById('events-carrousel').scrollLeft = this.currentScrollLeft;
  }
  scrollLeft() {
    if (this.currentScrollLeft > 0) {
      this.currentScrollLeft -= this.currentEventWitdh;
    } else {
      this.currentScrollLeft = this.maxScrollLeft;
    }
    document.getElementById('events-carrousel').scrollLeft = this.currentScrollLeft;
  }

  playVideo(index) {
    this.player = new Player(this.playerContainer.nativeElement);
    this.designedToSurpriseVideo = index;
    this.player.loadVideo(this.designedToSurpriseVideo).then(() => {
      setTimeout(() => {
        this.player.play();
      }, 1000);
    });
  }

  navigate(path: string) {
    if (path != null) {
      if (path.indexOf('http') > -1) {
        window.open(path);
      } else {
        this.router.navigateByUrl('/' + path);
      }
    }
  }
}
