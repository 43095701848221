<app-loading *ngIf="loading || imagesLoading"></app-loading>
<div *ngIf="!loading && !imagesLoading" [class.editingPadding]="editingMode">
  <div class="section bgImg" [appEditableImage]="editingMode" [editRoute]="'img_1'" [image]="events.img_1" [position]="'translate(-50%, -1400%)'">
  </div>
  <div class="session-wrapper">
    <div class="steps-guide">
      <div class="session-step" *ngFor="let sli of events.slider; let i = index " [class.selectItem]="i === selectedIndex"
        [class.unselectItem]="i !== selectedIndex" [ngClass]="'bgImgSl'+i" [appEditableImage]="editingMode" [editRoute]="'slider.'+i"
        [image]="sli">
        <div class="title" [ngClass]="sli.title.style" [appEditableText]="editingMode" [editRoute]="'sli.title'"
          [element]="sli.title" [negative]="true"> {{languageService.stringsResource[sli.title.content]}}</div>
        <div class="content" [ngClass]="sli.body.style" style="text-align: justify;" [appEditableText]="editingMode"
          [editRoute]="'sli.body'" [element]="sli.body" [negative]="true">
          {{languageService.stringsResource[sli.body.content]}}</div>
        <div><button [ngClass]="sli.button.style" [class.hidden]="editingMode ? false : sli.button.hidden"
            [appEditableButton]="editingMode" [editRoute]="'sli.button'" (click)="!editingMode && navigate(sli.button.url)"
            [innerHTML]="languageService.stringsResource[sli.button.content]" [link]="sli.button" [hidden]="sli.button.content"></button></div>
      </div>
    </div>
    <div class="steps-action">
      <div class="navigator" *ngFor="let sli of events.slider; let i = index " [class.selectNavigator]=" i === selectedIndex"
        (click)="goIndexNavigator(i)">
      </div>
    </div>
  </div>
</div>
