import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { LanguageService } from "../language.service";
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from "@angular/fire/firestore";
import { ActivatedRoute } from "@angular/router";
import { NavbarService } from "../navbar.service";
@Component({
  selector: "app-carrouselpage",
  templateUrl: "./carrouselpage.component.html",
  styleUrls: ["./carrouselpage.component.scss"],
})
export class CarrouselpageComponent implements OnInit, AfterViewInit {
  loading = true;
  currentLanguage: string = "en";
  @ViewChild("myCarousel", { static: false }) myCarousel;

  constructor(
    public languageService: LanguageService,
    private sanitazer: DomSanitizer,
    private activeRoute: ActivatedRoute,
    private db: AngularFirestore,
    private nonav: NavbarService
  ) {
    this.nonav.noNav.next(true);
  }
  event: any;
  events = [
    {
      id: "benefit-gala",
      title: {
        en: "MIAFW Benefit Gala",
        es: "Gala de beneficio MIAFW",
      },
      subTitle: {
        en: "hosted by Antonio Banderas",
        es: "Gala de beneficio MIAFW",
      },
      first_carrousel: [
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04008_jpg?alt=media&token=6a1b63a3-55a4-4ea7-9e87-72f10be1ae5c",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04269_jpg?alt=media&token=1f6b62eb-ef7c-4d51-a845-276e7e20dc0a",
      ],
      section_1: {
        title: {
          en: "A night to remember",
          es: "A night to remember",
        },
        subTitle: {
          en:
            "Honorary President Antonio Banderas hosts his fourth exclusive charity gala.",
          es:
            "Honorary President Antonio Banderas hosts his fourth exclusive charity gala.",
        },
        image:
          "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04525_JPG?alt=media&token=eb1d8875-0754-418a-b103-1e1e41dca259",
        stats: { attends: 1000, livesImpact: 9000, specialEvents: 20 },
      },
      section_2: {
        video: "https://www.youtube.com/embed/SK6_rYSXHiQ",
        text: {
          en:
            "Honorary President Antonio Banderas hosts his fourth exclusive charity gala. This annual signature event spotlights a special performance by a guests recording artists and is recognized as the most celebrity-filled glamorous night in Miami. In addition to the enchanting gala, a silent and live auction takes place throughout the night and proceeds garnered will benefit the Nicklaus Children’s Hospital Foundation and Lagrimas y Favores, the latter founded by Antonio Banderas.",
          es:
            "Honorary President Antonio Banderas hosts his fourth exclusive charity gala. This annual signature event spotlights a special performance by a guests recording artists and is recognized as the most celebrity-filled glamorous night in Miami. In addition to the enchanting gala, a silent and live auction takes place throughout the night and proceeds garnered will benefit the Nicklaus Children’s Hospital Foundation and Lagrimas y Favores, the latter founded by Antonio Banderas.",
        },
      },
      section_3: {
        item_1: {
          title: {
            en: "Auction",
            es: "Auction",
          },
          text: {
            en:
              " In addition to the enchanting gala, a silent and live auction takes place throughout the night and proceeds garnered will benefit the Nicklaus Children’s Hospital Foundation and Lagrimas y Favores, the latter founded by Antonio Banderas.",
            es:
              " In addition to the enchanting gala, a silent and live auction takes place throughout the night and proceeds garnered will benefit the Nicklaus Children’s Hospital Foundation and Lagrimas y Favores, the latter founded by Antonio Banderas.",
          },

          img:
            "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        },
        item_2: {
          title: {
            en: "The result",
            es: "The result",
          },
          text: {
            en:
              " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque nulla purus, maximus ut dolor sit amet, euismod vestibulum ligula. Aliquam erat volutpat.",
            es:
              " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque nulla purus, maximus ut dolor sit amet, euismod vestibulum ligula. Aliquam erat volutpat.",
          },
          img:
            "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        },
      },
      second_carrousel: [
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04008_jpg?alt=media&token=6a1b63a3-55a4-4ea7-9e87-72f10be1ae5c",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04269_jpg?alt=media&token=1f6b62eb-ef7c-4d51-a845-276e7e20dc0a",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04008_jpg?alt=media&token=6a1b63a3-55a4-4ea7-9e87-72f10be1ae5c",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04269_jpg?alt=media&token=1f6b62eb-ef7c-4d51-a845-276e7e20dc0a",
      ],
      section_4: {
        image:
          "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04525_JPG?alt=media&token=eb1d8875-0754-418a-b103-1e1e41dca259",
        title: {
          es: "Experience the Gala",
          en: "Experience the Gala",
        },
        subTitle: {
          es:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, donec posuere finibus.",
          en:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, donec posuere finibus.",
        },
      },
      gallery: [
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04008_jpg?alt=media&token=6a1b63a3-55a4-4ea7-9e87-72f10be1ae5c",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04269_jpg?alt=media&token=1f6b62eb-ef7c-4d51-a845-276e7e20dc0a",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04008_jpg?alt=media&token=6a1b63a3-55a4-4ea7-9e87-72f10be1ae5c",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04269_jpg?alt=media&token=1f6b62eb-ef7c-4d51-a845-276e7e20dc0a",
      ],
    },

    {
      id: "miami-week-summit",
      title: {
        en: "miami-week-summit",
        es: "miami-week-summit",
      },
      subTitle: {
        en: "hosted by Antonio Banderas",
        es: "Gala de beneficio MIAFW",
      },
      first_carrousel: [
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04008_jpg?alt=media&token=6a1b63a3-55a4-4ea7-9e87-72f10be1ae5c",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04269_jpg?alt=media&token=1f6b62eb-ef7c-4d51-a845-276e7e20dc0a",
      ],
      section_1: {
        title: {
          en: "A night to remember",
          es: "A night to remember",
        },
        subTitle: {
          en:
            "Honorary President Antonio Banderas hosts his fourth exclusive charity gala.",
          es:
            "Honorary President Antonio Banderas hosts his fourth exclusive charity gala.",
        },
        image:
          "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04525_JPG?alt=media&token=eb1d8875-0754-418a-b103-1e1e41dca259",
        stats: { attends: 1000, livesImpact: 9000, specialEvents: 20 },
      },
      section_2: {
        video: "https://www.youtube.com/embed/SK6_rYSXHiQ",
        text: {
          en:
            "Honorary President Antonio Banderas hosts his fourth exclusive charity gala. This annual signature event spotlights a special performance by a guests recording artists and is recognized as the most celebrity-filled glamorous night in Miami. In addition to the enchanting gala, a silent and live auction takes place throughout the night and proceeds garnered will benefit the Nicklaus Children’s Hospital Foundation and Lagrimas y Favores, the latter founded by Antonio Banderas.",
          es:
            "Honorary President Antonio Banderas hosts his fourth exclusive charity gala. This annual signature event spotlights a special performance by a guests recording artists and is recognized as the most celebrity-filled glamorous night in Miami. In addition to the enchanting gala, a silent and live auction takes place throughout the night and proceeds garnered will benefit the Nicklaus Children’s Hospital Foundation and Lagrimas y Favores, the latter founded by Antonio Banderas.",
        },
      },
      section_3: {
        item_1: {
          title: {
            en: "Auction",
            es: "Auction",
          },
          text: {
            en:
              " In addition to the enchanting gala, a silent and live auction takes place throughout the night and proceeds garnered will benefit the Nicklaus Children’s Hospital Foundation and Lagrimas y Favores, the latter founded by Antonio Banderas.",
            es:
              " In addition to the enchanting gala, a silent and live auction takes place throughout the night and proceeds garnered will benefit the Nicklaus Children’s Hospital Foundation and Lagrimas y Favores, the latter founded by Antonio Banderas.",
          },

          img:
            "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        },
        item_2: {
          title: {
            en: "The result",
            es: "The result",
          },
          text: {
            en:
              " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque nulla purus, maximus ut dolor sit amet, euismod vestibulum ligula. Aliquam erat volutpat.",
            es:
              " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque nulla purus, maximus ut dolor sit amet, euismod vestibulum ligula. Aliquam erat volutpat.",
          },
          img:
            "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        },
      },
      second_carrousel: [
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04008_jpg?alt=media&token=6a1b63a3-55a4-4ea7-9e87-72f10be1ae5c",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04269_jpg?alt=media&token=1f6b62eb-ef7c-4d51-a845-276e7e20dc0a",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04008_jpg?alt=media&token=6a1b63a3-55a4-4ea7-9e87-72f10be1ae5c",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04269_jpg?alt=media&token=1f6b62eb-ef7c-4d51-a845-276e7e20dc0a",
      ],
      section_4: {
        image:
          "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04525_JPG?alt=media&token=eb1d8875-0754-418a-b103-1e1e41dca259",
        title: {
          es: "Experience the Gala",
          en: "Experience the Gala",
        },
        subTitle: {
          es:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, donec posuere finibus.",
          en:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, donec posuere finibus.",
        },
      },
      gallery: [
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04008_jpg?alt=media&token=6a1b63a3-55a4-4ea7-9e87-72f10be1ae5c",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04269_jpg?alt=media&token=1f6b62eb-ef7c-4d51-a845-276e7e20dc0a",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04008_jpg?alt=media&token=6a1b63a3-55a4-4ea7-9e87-72f10be1ae5c",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04269_jpg?alt=media&token=1f6b62eb-ef7c-4d51-a845-276e7e20dc0a",
      ],
    },

    {
      id: "firstfaces",
      title: {
        en: "firstfaces",
        es: "firstfaces",
      },
      subTitle: {
        en: "hosted by Antonio Banderas",
        es: "Gala de beneficio MIAFW",
      },
      first_carrousel: [
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04008_jpg?alt=media&token=6a1b63a3-55a4-4ea7-9e87-72f10be1ae5c",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04269_jpg?alt=media&token=1f6b62eb-ef7c-4d51-a845-276e7e20dc0a",
      ],
      section_1: {
        title: {
          en: "A night to remember",
          es: "A night to remember",
        },
        subTitle: {
          en:
            "Honorary President Antonio Banderas hosts his fourth exclusive charity gala.",
          es:
            "Honorary President Antonio Banderas hosts his fourth exclusive charity gala.",
        },
        image:
          "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04525_JPG?alt=media&token=eb1d8875-0754-418a-b103-1e1e41dca259",
        stats: { attends: 1000, livesImpact: 9000, specialEvents: 20 },
      },
      section_2: {
        video: "https://www.youtube.com/embed/SK6_rYSXHiQ",
        text: {
          en:
            "Honorary President Antonio Banderas hosts his fourth exclusive charity gala. This annual signature event spotlights a special performance by a guests recording artists and is recognized as the most celebrity-filled glamorous night in Miami. In addition to the enchanting gala, a silent and live auction takes place throughout the night and proceeds garnered will benefit the Nicklaus Children’s Hospital Foundation and Lagrimas y Favores, the latter founded by Antonio Banderas.",
          es:
            "Honorary President Antonio Banderas hosts his fourth exclusive charity gala. This annual signature event spotlights a special performance by a guests recording artists and is recognized as the most celebrity-filled glamorous night in Miami. In addition to the enchanting gala, a silent and live auction takes place throughout the night and proceeds garnered will benefit the Nicklaus Children’s Hospital Foundation and Lagrimas y Favores, the latter founded by Antonio Banderas.",
        },
      },
      section_3: {
        item_1: {
          title: {
            en: "Auction",
            es: "Auction",
          },
          text: {
            en:
              " In addition to the enchanting gala, a silent and live auction takes place throughout the night and proceeds garnered will benefit the Nicklaus Children’s Hospital Foundation and Lagrimas y Favores, the latter founded by Antonio Banderas.",
            es:
              " In addition to the enchanting gala, a silent and live auction takes place throughout the night and proceeds garnered will benefit the Nicklaus Children’s Hospital Foundation and Lagrimas y Favores, the latter founded by Antonio Banderas.",
          },

          img:
            "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        },
        item_2: {
          title: {
            en: "The result",
            es: "The result",
          },
          text: {
            en:
              " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque nulla purus, maximus ut dolor sit amet, euismod vestibulum ligula. Aliquam erat volutpat.",
            es:
              " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque nulla purus, maximus ut dolor sit amet, euismod vestibulum ligula. Aliquam erat volutpat.",
          },
          img:
            "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        },
      },
      second_carrousel: [
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04008_jpg?alt=media&token=6a1b63a3-55a4-4ea7-9e87-72f10be1ae5c",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04269_jpg?alt=media&token=1f6b62eb-ef7c-4d51-a845-276e7e20dc0a",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04008_jpg?alt=media&token=6a1b63a3-55a4-4ea7-9e87-72f10be1ae5c",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04269_jpg?alt=media&token=1f6b62eb-ef7c-4d51-a845-276e7e20dc0a",
      ],
      section_4: {
        image:
          "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04525_JPG?alt=media&token=eb1d8875-0754-418a-b103-1e1e41dca259",
        title: {
          es: "Experience the Gala",
          en: "Experience the Gala",
        },
        subTitle: {
          es:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, donec posuere finibus.",
          en:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, donec posuere finibus.",
        },
      },
      gallery: [
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04008_jpg?alt=media&token=6a1b63a3-55a4-4ea7-9e87-72f10be1ae5c",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04269_jpg?alt=media&token=1f6b62eb-ef7c-4d51-a845-276e7e20dc0a",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04008_jpg?alt=media&token=6a1b63a3-55a4-4ea7-9e87-72f10be1ae5c",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04269_jpg?alt=media&token=1f6b62eb-ef7c-4d51-a845-276e7e20dc0a",
      ],
    },

    {
      id: "experiences",
      title: {
        en: "Experiences",
        es: "Experiences",
      },
      subTitle: {
        en: "hosted by Antonio Banderas",
        es: "Gala de beneficio MIAFW",
      },
      first_carrousel: [
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04008_jpg?alt=media&token=6a1b63a3-55a4-4ea7-9e87-72f10be1ae5c",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04269_jpg?alt=media&token=1f6b62eb-ef7c-4d51-a845-276e7e20dc0a",
      ],
      section_1: {
        title: {
          en: "A night to remember",
          es: "A night to remember",
        },
        subTitle: {
          en:
            "Honorary President Antonio Banderas hosts his fourth exclusive charity gala.",
          es:
            "Honorary President Antonio Banderas hosts his fourth exclusive charity gala.",
        },
        image:
          "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04525_JPG?alt=media&token=eb1d8875-0754-418a-b103-1e1e41dca259",
        stats: { attends: 1000, livesImpact: 9000, specialEvents: 20 },
      },
      section_2: {
        video: "https://www.youtube.com/embed/SK6_rYSXHiQ",
        text: {
          en:
            "Honorary President Antonio Banderas hosts his fourth exclusive charity gala. This annual signature event spotlights a special performance by a guests recording artists and is recognized as the most celebrity-filled glamorous night in Miami. In addition to the enchanting gala, a silent and live auction takes place throughout the night and proceeds garnered will benefit the Nicklaus Children’s Hospital Foundation and Lagrimas y Favores, the latter founded by Antonio Banderas.",
          es:
            "Honorary President Antonio Banderas hosts his fourth exclusive charity gala. This annual signature event spotlights a special performance by a guests recording artists and is recognized as the most celebrity-filled glamorous night in Miami. In addition to the enchanting gala, a silent and live auction takes place throughout the night and proceeds garnered will benefit the Nicklaus Children’s Hospital Foundation and Lagrimas y Favores, the latter founded by Antonio Banderas.",
        },
      },
      section_3: {
        item_1: {
          title: {
            en: "Auction",
            es: "Auction",
          },
          text: {
            en:
              " In addition to the enchanting gala, a silent and live auction takes place throughout the night and proceeds garnered will benefit the Nicklaus Children’s Hospital Foundation and Lagrimas y Favores, the latter founded by Antonio Banderas.",
            es:
              " In addition to the enchanting gala, a silent and live auction takes place throughout the night and proceeds garnered will benefit the Nicklaus Children’s Hospital Foundation and Lagrimas y Favores, the latter founded by Antonio Banderas.",
          },

          img:
            "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        },
        item_2: {
          title: {
            en: "The result",
            es: "The result",
          },
          text: {
            en:
              " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque nulla purus, maximus ut dolor sit amet, euismod vestibulum ligula. Aliquam erat volutpat.",
            es:
              " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque nulla purus, maximus ut dolor sit amet, euismod vestibulum ligula. Aliquam erat volutpat.",
          },
          img:
            "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        },
      },
      second_carrousel: [
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04008_jpg?alt=media&token=6a1b63a3-55a4-4ea7-9e87-72f10be1ae5c",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04269_jpg?alt=media&token=1f6b62eb-ef7c-4d51-a845-276e7e20dc0a",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04008_jpg?alt=media&token=6a1b63a3-55a4-4ea7-9e87-72f10be1ae5c",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04269_jpg?alt=media&token=1f6b62eb-ef7c-4d51-a845-276e7e20dc0a",
      ],
      section_4: {
        image:
          "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04525_JPG?alt=media&token=eb1d8875-0754-418a-b103-1e1e41dca259",
        title: {
          es: "Experience the Gala",
          en: "Experience the Gala",
        },
        subTitle: {
          es:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, donec posuere finibus.",
          en:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, donec posuere finibus.",
        },
      },
      gallery: [
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04008_jpg?alt=media&token=6a1b63a3-55a4-4ea7-9e87-72f10be1ae5c",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04269_jpg?alt=media&token=1f6b62eb-ef7c-4d51-a845-276e7e20dc0a",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04008_jpg?alt=media&token=6a1b63a3-55a4-4ea7-9e87-72f10be1ae5c",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04269_jpg?alt=media&token=1f6b62eb-ef7c-4d51-a845-276e7e20dc0a",
      ],
    },

    {
      id: "artweek",
      title: {
        en: "artweek",
        es: "artweek",
      },
      subTitle: {
        en: "hosted by Antonio Banderas",
        es: "Gala de beneficio MIAFW",
      },
      first_carrousel: [
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04008_jpg?alt=media&token=6a1b63a3-55a4-4ea7-9e87-72f10be1ae5c",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04269_jpg?alt=media&token=1f6b62eb-ef7c-4d51-a845-276e7e20dc0a",
      ],
      section_1: {
        title: {
          en: "A night to remember",
          es: "A night to remember",
        },
        subTitle: {
          en:
            "Honorary President Antonio Banderas hosts his fourth exclusive charity gala.",
          es:
            "Honorary President Antonio Banderas hosts his fourth exclusive charity gala.",
        },
        image:
          "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04525_JPG?alt=media&token=eb1d8875-0754-418a-b103-1e1e41dca259",
        stats: { attends: 1000, livesImpact: 9000, specialEvents: 20 },
      },
      section_2: {
        video: "https://www.youtube.com/embed/SK6_rYSXHiQ",
        text: {
          en:
            "Honorary President Antonio Banderas hosts his fourth exclusive charity gala. This annual signature event spotlights a special performance by a guests recording artists and is recognized as the most celebrity-filled glamorous night in Miami. In addition to the enchanting gala, a silent and live auction takes place throughout the night and proceeds garnered will benefit the Nicklaus Children’s Hospital Foundation and Lagrimas y Favores, the latter founded by Antonio Banderas.",
          es:
            "Honorary President Antonio Banderas hosts his fourth exclusive charity gala. This annual signature event spotlights a special performance by a guests recording artists and is recognized as the most celebrity-filled glamorous night in Miami. In addition to the enchanting gala, a silent and live auction takes place throughout the night and proceeds garnered will benefit the Nicklaus Children’s Hospital Foundation and Lagrimas y Favores, the latter founded by Antonio Banderas.",
        },
      },
      section_3: {
        item_1: {
          title: {
            en: "Auction",
            es: "Auction",
          },
          text: {
            en:
              " In addition to the enchanting gala, a silent and live auction takes place throughout the night and proceeds garnered will benefit the Nicklaus Children’s Hospital Foundation and Lagrimas y Favores, the latter founded by Antonio Banderas.",
            es:
              " In addition to the enchanting gala, a silent and live auction takes place throughout the night and proceeds garnered will benefit the Nicklaus Children’s Hospital Foundation and Lagrimas y Favores, the latter founded by Antonio Banderas.",
          },

          img:
            "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        },
        item_2: {
          title: {
            en: "The result",
            es: "The result",
          },
          text: {
            en:
              " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque nulla purus, maximus ut dolor sit amet, euismod vestibulum ligula. Aliquam erat volutpat.",
            es:
              " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque nulla purus, maximus ut dolor sit amet, euismod vestibulum ligula. Aliquam erat volutpat.",
          },
          img:
            "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        },
      },
      second_carrousel: [
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04008_jpg?alt=media&token=6a1b63a3-55a4-4ea7-9e87-72f10be1ae5c",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04269_jpg?alt=media&token=1f6b62eb-ef7c-4d51-a845-276e7e20dc0a",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04008_jpg?alt=media&token=6a1b63a3-55a4-4ea7-9e87-72f10be1ae5c",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04269_jpg?alt=media&token=1f6b62eb-ef7c-4d51-a845-276e7e20dc0a",
      ],
      section_4: {
        image:
          "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04525_JPG?alt=media&token=eb1d8875-0754-418a-b103-1e1e41dca259",
        title: {
          es: "Experience the Gala",
          en: "Experience the Gala",
        },
        subTitle: {
          es:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, donec posuere finibus.",
          en:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, donec posuere finibus.",
        },
      },
      gallery: [
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04008_jpg?alt=media&token=6a1b63a3-55a4-4ea7-9e87-72f10be1ae5c",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04269_jpg?alt=media&token=1f6b62eb-ef7c-4d51-a845-276e7e20dc0a",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04008_jpg?alt=media&token=6a1b63a3-55a4-4ea7-9e87-72f10be1ae5c",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04269_jpg?alt=media&token=1f6b62eb-ef7c-4d51-a845-276e7e20dc0a",
      ],
    },

    {
      id: "influencers-brunch",
      title: {
        en: "influencers-brunch",
        es: "influencers-brunch",
      },
      subTitle: {
        en: "hosted by Antonio Banderas",
        es: "Gala de beneficio MIAFW",
      },
      first_carrousel: [
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04008_jpg?alt=media&token=6a1b63a3-55a4-4ea7-9e87-72f10be1ae5c",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04269_jpg?alt=media&token=1f6b62eb-ef7c-4d51-a845-276e7e20dc0a",
      ],
      section_1: {
        title: {
          en: "A night to remember",
          es: "A night to remember",
        },
        subTitle: {
          en:
            "Honorary President Antonio Banderas hosts his fourth exclusive charity gala.",
          es:
            "Honorary President Antonio Banderas hosts his fourth exclusive charity gala.",
        },
        image:
          "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04525_JPG?alt=media&token=eb1d8875-0754-418a-b103-1e1e41dca259",
        stats: { attends: 1000, livesImpact: 9000, specialEvents: 20 },
      },
      section_2: {
        video: "https://www.youtube.com/embed/SK6_rYSXHiQ",
        text: {
          en:
            "Honorary President Antonio Banderas hosts his fourth exclusive charity gala. This annual signature event spotlights a special performance by a guests recording artists and is recognized as the most celebrity-filled glamorous night in Miami. In addition to the enchanting gala, a silent and live auction takes place throughout the night and proceeds garnered will benefit the Nicklaus Children’s Hospital Foundation and Lagrimas y Favores, the latter founded by Antonio Banderas.",
          es:
            "Honorary President Antonio Banderas hosts his fourth exclusive charity gala. This annual signature event spotlights a special performance by a guests recording artists and is recognized as the most celebrity-filled glamorous night in Miami. In addition to the enchanting gala, a silent and live auction takes place throughout the night and proceeds garnered will benefit the Nicklaus Children’s Hospital Foundation and Lagrimas y Favores, the latter founded by Antonio Banderas.",
        },
      },
      section_3: {
        item_1: {
          title: {
            en: "Auction",
            es: "Auction",
          },
          text: {
            en:
              " In addition to the enchanting gala, a silent and live auction takes place throughout the night and proceeds garnered will benefit the Nicklaus Children’s Hospital Foundation and Lagrimas y Favores, the latter founded by Antonio Banderas.",
            es:
              " In addition to the enchanting gala, a silent and live auction takes place throughout the night and proceeds garnered will benefit the Nicklaus Children’s Hospital Foundation and Lagrimas y Favores, the latter founded by Antonio Banderas.",
          },

          img:
            "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        },
        item_2: {
          title: {
            en: "The result",
            es: "The result",
          },
          text: {
            en:
              " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque nulla purus, maximus ut dolor sit amet, euismod vestibulum ligula. Aliquam erat volutpat.",
            es:
              " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque nulla purus, maximus ut dolor sit amet, euismod vestibulum ligula. Aliquam erat volutpat.",
          },
          img:
            "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        },
      },
      second_carrousel: [
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04008_jpg?alt=media&token=6a1b63a3-55a4-4ea7-9e87-72f10be1ae5c",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04269_jpg?alt=media&token=1f6b62eb-ef7c-4d51-a845-276e7e20dc0a",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04008_jpg?alt=media&token=6a1b63a3-55a4-4ea7-9e87-72f10be1ae5c",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04269_jpg?alt=media&token=1f6b62eb-ef7c-4d51-a845-276e7e20dc0a",
      ],
      section_4: {
        image:
          "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04525_JPG?alt=media&token=eb1d8875-0754-418a-b103-1e1e41dca259",
        title: {
          es: "Experience the Gala",
          en: "Experience the Gala",
        },
        subTitle: {
          es:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, donec posuere finibus.",
          en:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, donec posuere finibus.",
        },
      },
      gallery: [
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04008_jpg?alt=media&token=6a1b63a3-55a4-4ea7-9e87-72f10be1ae5c",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04269_jpg?alt=media&token=1f6b62eb-ef7c-4d51-a845-276e7e20dc0a",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04008_jpg?alt=media&token=6a1b63a3-55a4-4ea7-9e87-72f10be1ae5c",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04269_jpg?alt=media&token=1f6b62eb-ef7c-4d51-a845-276e7e20dc0a",
      ],
    },

    {
      id: "closing-party",
      title: {
        en: "closing-party",
        es: "closing-party",
      },
      subTitle: {
        en: "hosted by Antonio Banderas",
        es: "Gala de beneficio MIAFW",
      },
      first_carrousel: [
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04008_jpg?alt=media&token=6a1b63a3-55a4-4ea7-9e87-72f10be1ae5c",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04269_jpg?alt=media&token=1f6b62eb-ef7c-4d51-a845-276e7e20dc0a",
      ],
      section_1: {
        title: {
          en: "A night to remember",
          es: "A night to remember",
        },
        subTitle: {
          en:
            "Honorary President Antonio Banderas hosts his fourth exclusive charity gala.",
          es:
            "Honorary President Antonio Banderas hosts his fourth exclusive charity gala.",
        },
        image:
          "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04525_JPG?alt=media&token=eb1d8875-0754-418a-b103-1e1e41dca259",
        stats: { attends: 1000, livesImpact: 9000, specialEvents: 20 },
      },
      section_2: {
        video: "https://www.youtube.com/embed/SK6_rYSXHiQ",
        text: {
          en:
            "Honorary President Antonio Banderas hosts his fourth exclusive charity gala. This annual signature event spotlights a special performance by a guests recording artists and is recognized as the most celebrity-filled glamorous night in Miami. In addition to the enchanting gala, a silent and live auction takes place throughout the night and proceeds garnered will benefit the Nicklaus Children’s Hospital Foundation and Lagrimas y Favores, the latter founded by Antonio Banderas.",
          es:
            "Honorary President Antonio Banderas hosts his fourth exclusive charity gala. This annual signature event spotlights a special performance by a guests recording artists and is recognized as the most celebrity-filled glamorous night in Miami. In addition to the enchanting gala, a silent and live auction takes place throughout the night and proceeds garnered will benefit the Nicklaus Children’s Hospital Foundation and Lagrimas y Favores, the latter founded by Antonio Banderas.",
        },
      },
      section_3: {
        item_1: {
          title: {
            en: "Auction",
            es: "Auction",
          },
          text: {
            en:
              " In addition to the enchanting gala, a silent and live auction takes place throughout the night and proceeds garnered will benefit the Nicklaus Children’s Hospital Foundation and Lagrimas y Favores, the latter founded by Antonio Banderas.",
            es:
              " In addition to the enchanting gala, a silent and live auction takes place throughout the night and proceeds garnered will benefit the Nicklaus Children’s Hospital Foundation and Lagrimas y Favores, the latter founded by Antonio Banderas.",
          },

          img:
            "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        },
        item_2: {
          title: {
            en: "The result",
            es: "The result",
          },
          text: {
            en:
              " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque nulla purus, maximus ut dolor sit amet, euismod vestibulum ligula. Aliquam erat volutpat.",
            es:
              " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque nulla purus, maximus ut dolor sit amet, euismod vestibulum ligula. Aliquam erat volutpat.",
          },
          img:
            "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        },
      },
      second_carrousel: [
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04008_jpg?alt=media&token=6a1b63a3-55a4-4ea7-9e87-72f10be1ae5c",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04269_jpg?alt=media&token=1f6b62eb-ef7c-4d51-a845-276e7e20dc0a",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04008_jpg?alt=media&token=6a1b63a3-55a4-4ea7-9e87-72f10be1ae5c",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04269_jpg?alt=media&token=1f6b62eb-ef7c-4d51-a845-276e7e20dc0a",
      ],
      section_4: {
        image:
          "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04525_JPG?alt=media&token=eb1d8875-0754-418a-b103-1e1e41dca259",
        title: {
          es: "Experience the Gala",
          en: "Experience the Gala",
        },
        subTitle: {
          es:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, donec posuere finibus.",
          en:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, donec posuere finibus.",
        },
      },
      gallery: [
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04008_jpg?alt=media&token=6a1b63a3-55a4-4ea7-9e87-72f10be1ae5c",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04269_jpg?alt=media&token=1f6b62eb-ef7c-4d51-a845-276e7e20dc0a",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04008_jpg?alt=media&token=6a1b63a3-55a4-4ea7-9e87-72f10be1ae5c",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04269_jpg?alt=media&token=1f6b62eb-ef7c-4d51-a845-276e7e20dc0a",
      ],
    },

    {
      id: "designers-dinner",
      title: {
        en: "designers-dinner",
        es: "designers-dinner",
      },
      subTitle: {
        en: "hosted by Antonio Banderas",
        es: "Gala de beneficio MIAFW",
      },
      first_carrousel: [
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04008_jpg?alt=media&token=6a1b63a3-55a4-4ea7-9e87-72f10be1ae5c",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04269_jpg?alt=media&token=1f6b62eb-ef7c-4d51-a845-276e7e20dc0a",
      ],
      section_1: {
        title: {
          en: "A night to remember",
          es: "A night to remember",
        },
        subTitle: {
          en:
            "Honorary President Antonio Banderas hosts his fourth exclusive charity gala.",
          es:
            "Honorary President Antonio Banderas hosts his fourth exclusive charity gala.",
        },
        image:
          "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04525_JPG?alt=media&token=eb1d8875-0754-418a-b103-1e1e41dca259",
        stats: { attends: 1000, livesImpact: 9000, specialEvents: 20 },
      },
      section_2: {
        video: "https://www.youtube.com/embed/SK6_rYSXHiQ",
        text: {
          en:
            "Honorary President Antonio Banderas hosts his fourth exclusive charity gala. This annual signature event spotlights a special performance by a guests recording artists and is recognized as the most celebrity-filled glamorous night in Miami. In addition to the enchanting gala, a silent and live auction takes place throughout the night and proceeds garnered will benefit the Nicklaus Children’s Hospital Foundation and Lagrimas y Favores, the latter founded by Antonio Banderas.",
          es:
            "Honorary President Antonio Banderas hosts his fourth exclusive charity gala. This annual signature event spotlights a special performance by a guests recording artists and is recognized as the most celebrity-filled glamorous night in Miami. In addition to the enchanting gala, a silent and live auction takes place throughout the night and proceeds garnered will benefit the Nicklaus Children’s Hospital Foundation and Lagrimas y Favores, the latter founded by Antonio Banderas.",
        },
      },
      section_3: {
        item_1: {
          title: {
            en: "Auction",
            es: "Auction",
          },
          text: {
            en:
              " In addition to the enchanting gala, a silent and live auction takes place throughout the night and proceeds garnered will benefit the Nicklaus Children’s Hospital Foundation and Lagrimas y Favores, the latter founded by Antonio Banderas.",
            es:
              " In addition to the enchanting gala, a silent and live auction takes place throughout the night and proceeds garnered will benefit the Nicklaus Children’s Hospital Foundation and Lagrimas y Favores, the latter founded by Antonio Banderas.",
          },

          img:
            "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        },
        item_2: {
          title: {
            en: "The result",
            es: "The result",
          },
          text: {
            en:
              " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque nulla purus, maximus ut dolor sit amet, euismod vestibulum ligula. Aliquam erat volutpat.",
            es:
              " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque nulla purus, maximus ut dolor sit amet, euismod vestibulum ligula. Aliquam erat volutpat.",
          },
          img:
            "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        },
      },
      second_carrousel: [
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04008_jpg?alt=media&token=6a1b63a3-55a4-4ea7-9e87-72f10be1ae5c",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04269_jpg?alt=media&token=1f6b62eb-ef7c-4d51-a845-276e7e20dc0a",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04008_jpg?alt=media&token=6a1b63a3-55a4-4ea7-9e87-72f10be1ae5c",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04269_jpg?alt=media&token=1f6b62eb-ef7c-4d51-a845-276e7e20dc0a",
      ],
      section_4: {
        image:
          "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04525_JPG?alt=media&token=eb1d8875-0754-418a-b103-1e1e41dca259",
        title: {
          es: "Experience the Gala",
          en: "Experience the Gala",
        },
        subTitle: {
          es:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, donec posuere finibus.",
          en:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, donec posuere finibus.",
        },
      },
      gallery: [
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04008_jpg?alt=media&token=6a1b63a3-55a4-4ea7-9e87-72f10be1ae5c",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04269_jpg?alt=media&token=1f6b62eb-ef7c-4d51-a845-276e7e20dc0a",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04008_jpg?alt=media&token=6a1b63a3-55a4-4ea7-9e87-72f10be1ae5c",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04269_jpg?alt=media&token=1f6b62eb-ef7c-4d51-a845-276e7e20dc0a",
      ],
    },

    {
      id: "kick-off-party",
      title: {
        en: "kick-off-party",
        es: "kick-off-party",
      },
      subTitle: {
        en: "hosted by Antonio Banderas",
        es: "Gala de beneficio MIAFW",
      },
      first_carrousel: [
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04008_jpg?alt=media&token=6a1b63a3-55a4-4ea7-9e87-72f10be1ae5c",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04269_jpg?alt=media&token=1f6b62eb-ef7c-4d51-a845-276e7e20dc0a",
      ],
      section_1: {
        title: {
          en: "A night to remember",
          es: "A night to remember",
        },
        subTitle: {
          en:
            "Honorary President Antonio Banderas hosts his fourth exclusive charity gala.",
          es:
            "Honorary President Antonio Banderas hosts his fourth exclusive charity gala.",
        },
        image:
          "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04525_JPG?alt=media&token=eb1d8875-0754-418a-b103-1e1e41dca259",
        stats: { attends: 1000, livesImpact: 9000, specialEvents: 20 },
      },
      section_2: {
        video: "https://www.youtube.com/embed/SK6_rYSXHiQ",
        text: {
          en:
            "Honorary President Antonio Banderas hosts his fourth exclusive charity gala. This annual signature event spotlights a special performance by a guests recording artists and is recognized as the most celebrity-filled glamorous night in Miami. In addition to the enchanting gala, a silent and live auction takes place throughout the night and proceeds garnered will benefit the Nicklaus Children’s Hospital Foundation and Lagrimas y Favores, the latter founded by Antonio Banderas.",
          es:
            "Honorary President Antonio Banderas hosts his fourth exclusive charity gala. This annual signature event spotlights a special performance by a guests recording artists and is recognized as the most celebrity-filled glamorous night in Miami. In addition to the enchanting gala, a silent and live auction takes place throughout the night and proceeds garnered will benefit the Nicklaus Children’s Hospital Foundation and Lagrimas y Favores, the latter founded by Antonio Banderas.",
        },
      },
      section_3: {
        item_1: {
          title: {
            en: "Auction",
            es: "Auction",
          },
          text: {
            en:
              " In addition to the enchanting gala, a silent and live auction takes place throughout the night and proceeds garnered will benefit the Nicklaus Children’s Hospital Foundation and Lagrimas y Favores, the latter founded by Antonio Banderas.",
            es:
              " In addition to the enchanting gala, a silent and live auction takes place throughout the night and proceeds garnered will benefit the Nicklaus Children’s Hospital Foundation and Lagrimas y Favores, the latter founded by Antonio Banderas.",
          },

          img:
            "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        },
        item_2: {
          title: {
            en: "The result",
            es: "The result",
          },
          text: {
            en:
              " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque nulla purus, maximus ut dolor sit amet, euismod vestibulum ligula. Aliquam erat volutpat.",
            es:
              " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque nulla purus, maximus ut dolor sit amet, euismod vestibulum ligula. Aliquam erat volutpat.",
          },
          img:
            "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        },
      },
      second_carrousel: [
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04008_jpg?alt=media&token=6a1b63a3-55a4-4ea7-9e87-72f10be1ae5c",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04269_jpg?alt=media&token=1f6b62eb-ef7c-4d51-a845-276e7e20dc0a",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04008_jpg?alt=media&token=6a1b63a3-55a4-4ea7-9e87-72f10be1ae5c",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04269_jpg?alt=media&token=1f6b62eb-ef7c-4d51-a845-276e7e20dc0a",
      ],
      section_4: {
        image:
          "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04525_JPG?alt=media&token=eb1d8875-0754-418a-b103-1e1e41dca259",
        title: {
          es: "Experience the Gala",
          en: "Experience the Gala",
        },
        subTitle: {
          es:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, donec posuere finibus.",
          en:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, donec posuere finibus.",
        },
      },
      gallery: [
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04008_jpg?alt=media&token=6a1b63a3-55a4-4ea7-9e87-72f10be1ae5c",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04269_jpg?alt=media&token=1f6b62eb-ef7c-4d51-a845-276e7e20dc0a",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04008_jpg?alt=media&token=6a1b63a3-55a4-4ea7-9e87-72f10be1ae5c",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04269_jpg?alt=media&token=1f6b62eb-ef7c-4d51-a845-276e7e20dc0a",
      ],
    },

    {
      id: "press-conference",
      title: {
        en: "press-conference",
        es: "press-conference",
      },
      subTitle: {
        en: "hosted by Antonio Banderas",
        es: "Gala de beneficio MIAFW",
      },
      first_carrousel: [
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04008_jpg?alt=media&token=6a1b63a3-55a4-4ea7-9e87-72f10be1ae5c",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04269_jpg?alt=media&token=1f6b62eb-ef7c-4d51-a845-276e7e20dc0a",
      ],
      section_1: {
        title: {
          en: "A night to remember",
          es: "A night to remember",
        },
        subTitle: {
          en:
            "Honorary President Antonio Banderas hosts his fourth exclusive charity gala.",
          es:
            "Honorary President Antonio Banderas hosts his fourth exclusive charity gala.",
        },
        image:
          "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04525_JPG?alt=media&token=eb1d8875-0754-418a-b103-1e1e41dca259",
        stats: { attends: 1000, livesImpact: 9000, specialEvents: 20 },
      },
      section_2: {
        video: "https://www.youtube.com/embed/SK6_rYSXHiQ",
        text: {
          en:
            "Honorary President Antonio Banderas hosts his fourth exclusive charity gala. This annual signature event spotlights a special performance by a guests recording artists and is recognized as the most celebrity-filled glamorous night in Miami. In addition to the enchanting gala, a silent and live auction takes place throughout the night and proceeds garnered will benefit the Nicklaus Children’s Hospital Foundation and Lagrimas y Favores, the latter founded by Antonio Banderas.",
          es:
            "Honorary President Antonio Banderas hosts his fourth exclusive charity gala. This annual signature event spotlights a special performance by a guests recording artists and is recognized as the most celebrity-filled glamorous night in Miami. In addition to the enchanting gala, a silent and live auction takes place throughout the night and proceeds garnered will benefit the Nicklaus Children’s Hospital Foundation and Lagrimas y Favores, the latter founded by Antonio Banderas.",
        },
      },
      section_3: {
        item_1: {
          title: {
            en: "Auction",
            es: "Auction",
          },
          text: {
            en:
              " In addition to the enchanting gala, a silent and live auction takes place throughout the night and proceeds garnered will benefit the Nicklaus Children’s Hospital Foundation and Lagrimas y Favores, the latter founded by Antonio Banderas.",
            es:
              " In addition to the enchanting gala, a silent and live auction takes place throughout the night and proceeds garnered will benefit the Nicklaus Children’s Hospital Foundation and Lagrimas y Favores, the latter founded by Antonio Banderas.",
          },

          img:
            "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        },
        item_2: {
          title: {
            en: "The result",
            es: "The result",
          },
          text: {
            en:
              " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque nulla purus, maximus ut dolor sit amet, euismod vestibulum ligula. Aliquam erat volutpat.",
            es:
              " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque nulla purus, maximus ut dolor sit amet, euismod vestibulum ligula. Aliquam erat volutpat.",
          },
          img:
            "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        },
      },
      second_carrousel: [
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04008_jpg?alt=media&token=6a1b63a3-55a4-4ea7-9e87-72f10be1ae5c",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04269_jpg?alt=media&token=1f6b62eb-ef7c-4d51-a845-276e7e20dc0a",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04008_jpg?alt=media&token=6a1b63a3-55a4-4ea7-9e87-72f10be1ae5c",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04269_jpg?alt=media&token=1f6b62eb-ef7c-4d51-a845-276e7e20dc0a",
      ],
      section_4: {
        image:
          "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04525_JPG?alt=media&token=eb1d8875-0754-418a-b103-1e1e41dca259",
        title: {
          es: "Experience the Gala",
          en: "Experience the Gala",
        },
        subTitle: {
          es:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, donec posuere finibus.",
          en:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, donec posuere finibus.",
        },
      },
      gallery: [
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04008_jpg?alt=media&token=6a1b63a3-55a4-4ea7-9e87-72f10be1ae5c",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04269_jpg?alt=media&token=1f6b62eb-ef7c-4d51-a845-276e7e20dc0a",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04008_jpg?alt=media&token=6a1b63a3-55a4-4ea7-9e87-72f10be1ae5c",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04269_jpg?alt=media&token=1f6b62eb-ef7c-4d51-a845-276e7e20dc0a",
      ],
    },

    {
      id: "designers-info",
      title: {
        en: "designers-info",
        es: "designers-info",
      },
      subTitle: {
        en: "hosted by Antonio Banderas",
        es: "Gala de beneficio MIAFW",
      },
      first_carrousel: [
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04008_jpg?alt=media&token=6a1b63a3-55a4-4ea7-9e87-72f10be1ae5c",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04269_jpg?alt=media&token=1f6b62eb-ef7c-4d51-a845-276e7e20dc0a",
      ],
      section_1: {
        title: {
          en: "A night to remember",
          es: "A night to remember",
        },
        subTitle: {
          en:
            "Honorary President Antonio Banderas hosts his fourth exclusive charity gala.",
          es:
            "Honorary President Antonio Banderas hosts his fourth exclusive charity gala.",
        },
        image:
          "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04525_JPG?alt=media&token=eb1d8875-0754-418a-b103-1e1e41dca259",
        stats: { attends: 1000, livesImpact: 9000, specialEvents: 20 },
      },
      section_2: {
        video: "https://www.youtube.com/embed/SK6_rYSXHiQ",
        text: {
          en:
            "Honorary President Antonio Banderas hosts his fourth exclusive charity gala. This annual signature event spotlights a special performance by a guests recording artists and is recognized as the most celebrity-filled glamorous night in Miami. In addition to the enchanting gala, a silent and live auction takes place throughout the night and proceeds garnered will benefit the Nicklaus Children’s Hospital Foundation and Lagrimas y Favores, the latter founded by Antonio Banderas.",
          es:
            "Honorary President Antonio Banderas hosts his fourth exclusive charity gala. This annual signature event spotlights a special performance by a guests recording artists and is recognized as the most celebrity-filled glamorous night in Miami. In addition to the enchanting gala, a silent and live auction takes place throughout the night and proceeds garnered will benefit the Nicklaus Children’s Hospital Foundation and Lagrimas y Favores, the latter founded by Antonio Banderas.",
        },
      },
      section_3: {
        item_1: {
          title: {
            en: "Auction",
            es: "Auction",
          },
          text: {
            en:
              " In addition to the enchanting gala, a silent and live auction takes place throughout the night and proceeds garnered will benefit the Nicklaus Children’s Hospital Foundation and Lagrimas y Favores, the latter founded by Antonio Banderas.",
            es:
              " In addition to the enchanting gala, a silent and live auction takes place throughout the night and proceeds garnered will benefit the Nicklaus Children’s Hospital Foundation and Lagrimas y Favores, the latter founded by Antonio Banderas.",
          },

          img:
            "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        },
        item_2: {
          title: {
            en: "The result",
            es: "The result",
          },
          text: {
            en:
              " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque nulla purus, maximus ut dolor sit amet, euismod vestibulum ligula. Aliquam erat volutpat.",
            es:
              " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque nulla purus, maximus ut dolor sit amet, euismod vestibulum ligula. Aliquam erat volutpat.",
          },
          img:
            "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        },
      },
      second_carrousel: [
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04008_jpg?alt=media&token=6a1b63a3-55a4-4ea7-9e87-72f10be1ae5c",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04269_jpg?alt=media&token=1f6b62eb-ef7c-4d51-a845-276e7e20dc0a",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04008_jpg?alt=media&token=6a1b63a3-55a4-4ea7-9e87-72f10be1ae5c",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04269_jpg?alt=media&token=1f6b62eb-ef7c-4d51-a845-276e7e20dc0a",
      ],
      section_4: {
        image:
          "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04525_JPG?alt=media&token=eb1d8875-0754-418a-b103-1e1e41dca259",
        title: {
          es: "Experience the Gala",
          en: "Experience the Gala",
        },
        subTitle: {
          es:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, donec posuere finibus.",
          en:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, donec posuere finibus.",
        },
      },
      gallery: [
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04008_jpg?alt=media&token=6a1b63a3-55a4-4ea7-9e87-72f10be1ae5c",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04269_jpg?alt=media&token=1f6b62eb-ef7c-4d51-a845-276e7e20dc0a",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04398_jpg?alt=media&token=829c5426-b07e-4f35-bdff-8f13ef1ea0de",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04008_jpg?alt=media&token=6a1b63a3-55a4-4ea7-9e87-72f10be1ae5c",
        "https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/uploads%2Fprocessed_DSC04269_jpg?alt=media&token=1f6b62eb-ef7c-4d51-a845-276e7e20dc0a",
      ],
    },
  ];

  @ViewChildren("carr") carrouselQuery: QueryList<ElementRef>;

  ngOnInit() {
    this.languageService.notifier.subscribe(
      (lang) => (this.currentLanguage = lang)
    );

    this.activeRoute.params.subscribe((resp) => {
      if (resp.id) {
        this.db
          .doc(`Events/${resp.id}`)
          .get()
          .subscribe((event) => (this.event = event.data()));
      }
    });
  }

  ngAfterViewInit() {}

  loadSanitize(src: string) {
    return this.sanitazer.bypassSecurityTrustResourceUrl(src);
  }

  scrollLeft() {
    this.myCarousel.prev();
  }
  scrollRight() {
    this.myCarousel.next();
  }
}
