import { Component, OnInit, Input, AfterViewInit, OnDestroy, EventEmitter, Output } from '@angular/core';
import { LanguageService } from '../../language.service';
import { AngularFireDatabase } from '@angular/fire/database';
import { ImagesService } from '../../images.service';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { NavbarService } from '../../navbar.service';
import { FixedNavbarService } from '../../fixed-navbar.service';
import { interval } from 'rxjs';
import { take, takeWhile } from 'rxjs/operators';

@Component({
  selector: 'app-first-faces-votes',
  templateUrl: './first-faces-votes.component.html',
  styleUrls: ['./first-faces-votes.component.css'],
})
export class FirstFacesVotesComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() editingMode: boolean;
  @Input() editablePage;
  @Input() uiChanges: BehaviorSubject<boolean>;

  @Output() deleteModelEvent = new EventEmitter();

  firstfaces: any;
  loading = true;
  imagesLoading = true;
  fakeLoad = true;

  selectedModel = -1;

  votingActive = true;
  voteAlert = false;
  term: string;

  constructor(
    public languageService: LanguageService,
    public imagesService: ImagesService,
    private db: AngularFireDatabase,
    private router: Router,
    private navSvc: NavbarService,
    private fixedNav: FixedNavbarService
  ) {}

  ngOnInit() {
    if (this.editablePage != null) {
      this.firstfaces = this.editablePage;
    } else {
      const promisse = this.db.object('pages/firstfacesvotes2019').valueChanges();
      promisse.subscribe(raw => {
        this.loading = false;
        this.firstfaces = raw;
        interval(1000)
          .pipe(take(1))
          .subscribe(_ => (this.fakeLoad = false));
        if (this.firstfaces.votingEnabled === false && !this.editingMode) {
          this.navigate('firstfaces');
        }
        if (this.firstfaces.models !== undefined && this.firstfaces.models != null) {
          this.firstfaces.models = this.firstfaces.models.map((m, i) => Object.assign(m, { index: i }));
          this.firstfaces.models = this.firstfaces.models.sort((a, b) => a.name.localeCompare(b.name));
        }
      });
    }
    this.navSvc.white.next(true);
    this.fixedNav.navbarAbsouluteSubject.next(true);

    const voted = localStorage.getItem('voted');
    const recovered = localStorage.getItem('recovered');
    if (voted !== null && voted !== undefined) {
      this.votingActive = false;
      this.selectedModel = Number(voted);
      if (recovered === undefined || recovered == null) {
        const votes = this.firstfaces.models[this.selectedModel].votes;
        if (votes === undefined || votes === null) {
          this.firstfaces.models[this.selectedModel].votes = 1;
          this.db.object('pages/firstfacesvotes2019/models/' + this.selectedModel + '/votes').set(1);
        } else {
          this.db.object('pages/firstfacesvotes2019/models/' + this.selectedModel + '/votes').set(votes + 1);
        }
        localStorage.setItem('recovered', this.selectedModel.toString());
      }
    }
  }

  ngOnDestroy() {
    this.navSvc.white.next(false);
    this.fixedNav.navbarAbsouluteSubject.next(false);
  }

  ngAfterViewInit() {
    interval(100)
      .pipe(takeWhile(() => this.imagesLoading))
      .subscribe(i => {
        if (this.imagesService.loading || this.loading) {
          return;
        }
        this.imagesService.loadHeader(this.imagesService.imagesResource[this.firstfaces.img_2.src]);
        this.imagesLoading = false;
      });
    if (this.editingMode) {
      this.uiChanges.subscribe(changes => {
        if (changes && this.editingMode) {
          this.firstfaces = this.editablePage;
          if (this.firstfaces.models !== undefined && this.firstfaces.models != null) {
            this.firstfaces.models = this.firstfaces.models.map((m, i) => Object.assign(m, { index: i }));
            this.firstfaces.models = this.firstfaces.models.sort((a, b) => a.name.localeCompare(b.name));
          }
          this.imagesService.loadHeader(this.imagesService.imagesResource[this.firstfaces.img_2.src]);
        }
      });
    }
  }

  navigate(path: string) {
    if (path != null) {
      if (path.indexOf('http') > -1) {
        // window.location.href = path;
        window.open(path);
      } else {
        this.router.navigateByUrl('/' + path);
      }
    }
  }

  vote() {
    if (this.votingActive && this.selectedModel > -1 && this.selectedModel < this.firstfaces.models.length) {
      localStorage.setItem('voted', this.selectedModel.toString());
      localStorage.setItem('recovered', this.selectedModel.toString());
      this.votingActive = false;
      const votes = this.firstfaces.models[this.selectedModel].votes;
      if (votes === undefined || votes === null) {
        this.firstfaces.models[this.selectedModel].votes = 1;
        this.db.object('pages/firstfacesvotes2019/models/' + this.selectedModel + '/votes').set(1);
      } else {
        this.db.object('pages/firstfacesvotes2019/models/' + this.selectedModel + '/votes').set(votes + 1);
      }
      this.voteAlert = true;
    }
  }

  deleteModel(index: number) {
    if (this.editingMode) {
      this.deleteModelEvent.emit(index);
    }
  }
}
