import { Injectable } from '@angular/core';
import { BehaviorSubject, Subscription, interval } from 'rxjs';
import { takeWhile } from 'rxjs/operators';

@Injectable()
export class CarrouselSmalleService {
  public carrousel: BehaviorSubject<any> = new BehaviorSubject(null);
  private carrouselDiv: any;

  private animationTime: number;
  private animationLenght = 1;
  private animationOffset: number;
  private sinosoidalPartition = 0.015832638063426723701292502067246310140954462097001365437;
  public leftArrowDisabled = true;
  public rightArrowDisabled = false;
  private scrollTimer: Subscription = null;

  constructor() {
    this.carrousel.subscribe(div => {
      this.carrouselDiv = div;
      if (div != null && this.scrollTimer == null) {
        this.scrollTimer = interval(5000)
          .pipe(takeWhile(() => this.carrouselDiv != null))
          .subscribe(() => {
            this.scrollRight();
          });
      }
    });
  }

  public scrollLeft() {
    if (this.scrollTimer != null) {
      this.scrollTimer.unsubscribe();
      this.scrollTimer = null;
    }
    this.animationTime = 0;
    let totalOffset = 0;
    const targetOffset = -this.carrouselDiv.offsetWidth;
    const stepOffset = targetOffset * this.sinosoidalPartition;
    interval(10)
      .pipe(takeWhile(() => this.animationTime < this.animationLenght))
      .subscribe(i => {
        const offset = Math.round(stepOffset * Math.sin(((Math.PI / 2) * this.animationTime) / this.animationLenght));
        totalOffset = totalOffset + offset;
        if (targetOffset > totalOffset) {
          if (this.carrouselDiv.scrollBy !== undefined) {
            this.carrouselDiv.scrollBy(offset + (targetOffset - totalOffset), 0);
          } else {
            this.carrouselDiv.scrollLeft = this.carrouselDiv.scrollLeft + (offset + (targetOffset - totalOffset));
          }
        } else {
          if (this.carrouselDiv.scrollBy !== undefined) {
            this.carrouselDiv.scrollBy(offset, 0);
          } else {
            this.carrouselDiv.scrollLeft = this.carrouselDiv.scrollLeft + offset;
          }
        }
        this.animationTime = this.animationTime + 0.01;
        this.leftArrowDisabled = this.carrouselDiv.scrollLeft === 0;
        this.rightArrowDisabled = false;
      });
    this.scrollTimer = interval(5000)
      .pipe(takeWhile(() => this.carrouselDiv != null))
      .subscribe(() => {
        this.scrollRight();
      });
  }
  public scrollRight() {
    if (this.scrollTimer != null) {
      this.scrollTimer.unsubscribe();
      this.scrollTimer = null;
    }
    this.animationTime = 0;
    let totalOffset = 0;
    if (this.carrouselDiv === null || this.carrouselDiv === undefined) {
      return;
    }
    let targetOffset = this.carrouselDiv.offsetWidth;
    if (this.rightArrowDisabled) {
      targetOffset = -this.carrouselDiv.scrollWidth;
    }
    const stepOffset = targetOffset * this.sinosoidalPartition;

    interval(10)
      .pipe(takeWhile(() => this.animationTime < this.animationLenght))
      .subscribe(i => {
        const offset = Math.round(stepOffset * Math.sin(((Math.PI / 2) * this.animationTime) / this.animationLenght));
        totalOffset = totalOffset + offset;
        if (targetOffset < totalOffset) {
          if (this.carrouselDiv.scrollBy !== undefined) {
            this.carrouselDiv.scrollBy(offset + (targetOffset - totalOffset), 0);
          } else {
            this.carrouselDiv.scrollLeft = this.carrouselDiv.scrollLeft + (offset + (targetOffset - totalOffset));
          }
        } else {
          if (this.carrouselDiv.scrollBy !== undefined) {
            this.carrouselDiv.scrollBy(offset, 0);
          } else {
            this.carrouselDiv.scrollLeft = this.carrouselDiv.scrollLeft + offset;
          }
        }
        this.animationTime = this.animationTime + 0.01;
        this.rightArrowDisabled = this.carrouselDiv.scrollLeft + targetOffset >= this.carrouselDiv.scrollWidth;
        this.leftArrowDisabled = false;
      });
    this.scrollTimer = interval(5000)
      .pipe(takeWhile(() => this.carrouselDiv != null))
      .subscribe(() => {
        this.scrollRight();
      });
  }
}
