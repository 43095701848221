<!-- <div *ngIf="!loading" [class.editingPadding]="editingMode">
  <div class="section header headerImage" [appEditableImage]="editingMode" [image]="schedule.img_1" [editRoute]="'img_1'">
  </div>
  <div class="section hAuto p20 txtCenter" *ngIf="!schedule.disabled || editingMode">
    <div style="margin-top: 4vh;" [ngClass]="schedule.title.style" [innerHTML]="languageService.stringsResource[schedule.title.content]" [appEditableText]="editingMode" [element]="schedule.title" [editRoute]="'title'"></div>
    <div *ngFor="let day of schedule.days; let dayIndex = index;" class="day">
      <div [ngClass]="day.title.style" [innerHTML]="languageService.stringsResource[day.title.content]" [appEditableDay]="editingMode" [day]="day" [editRoute]="'days.'+dayIndex" [defaultStyle]="'title2'"></div>
      <div *ngFor="let event of day.events; let i = index;" class="event" [appEditableEvent]="editingMode" [event]="event" [editRoute]="'days.'+dayIndex+'.events.'+i">
        <div class="col" [innerHTML]="languageService.stringsResource[event.time]"></div>
        <div class="col" [innerHTML]="languageService.stringsResource[event.title]"></div>
        <div *ngIf="event.tickets.url == null || event.tickets.url === '' || event.tickets.url === undefined" class="col" [innerHTML]="languageService.stringsResource[event.tickets.content]"></div>
        <div *ngIf="event.tickets.url != null && event.tickets.url !== '' && event.tickets.url !== undefined" class="col"><a [href]="event.tickets.url" [innerHTML]="languageService.stringsResource[event.tickets.content]"></a></div>
        <div class="col" [innerHTML]="languageService.stringsResource[event.type]"></div>
      </div>
    </div>
  </div>
  <div class="section hAuto p20 txtCenter" *ngIf="schedule.disabled">
    <div style="margin-top: 15vh; height: 30vh;" class="title1">{{languageService.stringsResource['commingsoon']}}</div>
  </div>
</div> -->

<div class="section header headerImage"></div>
<div class="section hAuto p20">
  <div class="schedule-intro">
    <div class="img-intro-wrapper" style="background-image: url(/assets/schedule/intro-1.jpg)"></div>
    <div class="img-intro-wrapper" style="background-image: url(/assets/schedule/intro-2.jpg)"></div>
    <div class="img-intro-wrapper" style="background-image: url(/assets/schedule/intro-3.jpg)"></div>
    <div class="intro-title">
      <p>MIAFW '19</p>
      <p><span class="hide">MAY 29th</span> <span class="underline-small">PROGRAM</span></p>
      <p><span class="top-text">MAY 29th</span> JUNE 2nd</p>
    </div>
  </div>
  <div class="day-section">
    <div class="img-space" style="background-image: url(/assets/schedule/1.jpg)"></div>
    <div class="txt-space">
      <div class="text-downleft">*Private event.</div>
      <div class="day">
        <div class="title">
          <p class="day-title">WEDNESDAY</p>
          <p>MAY 29th</p>
        </div>
        <div class="events-wrapper">
          <p>12:00 P.M.</p>
          <div>
            <p class="century-bold">PRESS CONFERENCE</p>
            <p>EPIC HOTEL</p>
          </div>
          <div></div>
          <p>6:30 P.M.</p>
          <div>
            <p class="century-bold">KICK-OFF PARTY</p>
            <p>SAKS FIFTH AVENUE BRICKELL</p>
          </div>
          <div></div>
          <p>8:30 P.M.</p>
          <div>
            <p class="century-bold"> DESIGNERS DINNER*</p>
            <p>CHOTTO MATTE RESTAURANT</p>
          </div>
          <div></div>
        </div>
      </div>
      <div class="day">
        <div class="title">
          <p class="day-title">THURSDAY</p>
          <p>MAY 30th</p>
        </div>
        <div class="events-wrapper">
          <p>9:00 A.M - 1:00 P.M,</p>
          <div>
            <p class="century-bold">MIAFW SUMMIT “SUSTAINABILITY &amp; TECHNOLOGY”</p>
            <p>MDC - WOLFSON CAMPUS</p>
          </div>
          <button> </button>
          <p>6:00 P.M.</p>
          <div>
            <p class="century-bold">RENE BY RR RUNWAY</p>
            <p>ICE PALACE</p>
          </div>
          <button> </button>
          <p>7:30 P.M.</p>
          <div>
            <p class="century-bold">ÁGATHA RU&Iacute;Z DE LA PRADA RUNWAY</p>
            <p>ICE PALACE</p>
          </div>
          <button> </button>
        </div>
      </div>
    </div>
  </div>
  <div class="day-section -reverse">
    <div class="img-space" style="background-image: url(/assets/schedule/2.jpg)"></div>
    <div class="txt-space">
      <div class="text-downleft">*Tickets: $1.000 per seat.</div>
      <div class="day">
        <div class="title">
          <p class="day-title">FRIDAY</p>
          <p>MAY 31st</p>
        </div>
        <div class="events-wrapper">
          <p>9:00 A.M.- 1:00 P.M.</p>
          <div>
            <p class="century-bold">MIAFW SUMMIT “SUSTAINABILITY & TECHNOLOGY”</p>
            <p>MDC - WOLFSON CAMPUS</p>
          </div>
          <button> </button>
          <p>5:00 P.M.</p>
          <div>
            <p class="century-bold">DANIELLA BATLLE RUNWAY</p>
            <p>ICE PALACE</p>
          </div>
          <button> </button>
          <p>6:30 P.M.</p>
          <div>
            <p class="century-bold">HERMINAS REEA RUNWAY</p>
            <p>ICE PALACE</p>
          </div>
          <button> </button>
          <p>7:30 P.M.</p>
          <div>
            <p class="century-bold">VERO DÍAZ RUNWAY</p>
            <p>ICE PALACE</p>
          </div>
          <button> </button>
          <p>8:30 P.M.</p>
          <div>
            <p class="century-bold">MIAFW BENEFIT GALA*</p>
            <p>FAENA FORUM</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="day-section">
    <div class="img-space" style="background-image: url(/assets/schedule/3.jpg)"></div>
    <div class="txt-space">
      <div class="text-downleft">*Private event.</div>
      <div class="day">
        <div class="title">
          <p class="day-title">SATURDAY</p>
          <p>JUNE 1st</p>
        </div>
        <div class="events-wrapper">
          <p>11:30 A.M.</p>
          <div>
            <p class="century-bold">INFLUENCERS BRUNCH* <br>

            </p>
            <p> <i> MONICA VARELA JEWELRY EXHIBIT</i></p>
            <p>SEASPICE RESTAURANT</p>
          </div>
          <div></div>
          <p>3:30 P.M.</p>
          <div>
            <p class="century-bold">DASH FASHION SHOW</p>
            <p>ICE PALACE</p>
          </div>
          <button> </button>
          <p>5:00 P.M.</p>
          <div>
            <p class="century-bold">MIAMI FASHION INSTITUTE RUNWAY</p>
            <p>ICE PALACE</p>
          </div>
          <button> </button>
          <p>6:30 P.M.</p>
          <div>
            <p class="century-bold">GLORY ANG RUNWAY</p>
            <p>ICE PALACE</p>
          </div>
          <button> </button>
          <p>8:00 P.M.</p>
          <div>
            <p class="century-bold">FERNANDO ALBERTO RUNWAY</p>
            <p>ICE PALACE</p>
          </div>
          <button> </button>
        </div>
      </div>
    </div>
  </div>
  <div class="day-section -reverse">
    <div class="img-space" style="background-image: url(/assets/schedule/4.JPG)"></div>
    <div class="txt-space">
      <div class="day">
        <div class="title">
          <p class="day-title">SUNDAY</p>
          <p>JUNE 2nd</p>
        </div>
        <div class="events-wrapper">
          <p>5:00 P.M.</p>
          <div>
            <p class="century-bold">JENNY POLANCO RUNWAY <br> ARCADIO DÍAZ RUNWAY</p>
            <p>ICE PALACE</p>
          </div>
          <button> </button>
          <p>6:30 P.M.</p>
          <div>
            <p class="century-bold">JACQUELINE THEN RUNWAY</p>
            <p>ICE PALACE</p>
          </div>
          <button> </button>
          <p>8:00 P.M.</p>
          <div>
            <p class="century-bold">GIANNINA AZAR RUNWAY</p>
            <p>ICE PALACE</p>
          </div>
          <button> </button>
          <p>9:00 P.M.</p>
          <div>
            <p class="century-bold">CLOSING PARTY</p>
            <p>ICE PALACE</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
