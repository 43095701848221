<app-loading *ngIf="loading || authorizing || sending"></app-loading>
<div id="confirmation" class="confirmation" >
  <div class="content">
    <div>REGISTRATION SUCCESSFUL</div>
    <div>We have received your registration, we'll be in contact with you as soon as possible.</div>
  </div>
</div>
<div *ngIf="!loading && !authorized">
  <div class="bgImage">
    <div class="logo"></div>
    <div class="box">
      <div class="title">DESIGNERS REGISTRATION</div>
      <div class="subtitle">YOU MADE IT TO STEP TWO!</div>
      <div class="codebox">
        <div>INSERT CODE</div>
        <form (ngSubmit)="submitCode()">
          <input type="text" name="code" [(ngModel)]="verificationCode">
          <br>
          <button type="submit">SUBMIT</button>
        </form>
      </div>
      <div class="error" [class.active]="codeError != null">
        <div class="error-text">{{codeError}}</div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="!loading && authorized" class="step2">
    <div class="uploading" [class.active]="currentUpload != null">
        <div class="progress-text">{{(currentUpload?.progress | number:'1.0-0')+ '%'}}</div>
        <div class="progress" [ngStyle]="{ 'width': currentUpload?.progress + '%' }"></div>
    </div>
    <div class="error" [class.active]="error != null">
      <div class="error-text">{{error}}</div>
  </div>
  <div class="header">
    <div class="logo"></div>
  </div>
  <div class="image"></div>
  <div class="bigTitle">DESIGNERS REGISTRATION</div>
  <div class="info">
    <div>FOR MIAMI FASHION WEEK 2020 EDITION</div>
    <div>RESORT COLLECTION 30TH MAY - 3RD JUNE 2018</div>
    <div>APPLICATIONS MUST BE RECEIVED BY 31ST DECEMBER 2017</div>
  </div>
  <div class="title">REGISTRATION FORM</div>
  <form #formData='ngForm' (ngSubmit)="sendRegistration(formData)">
    <div class="section">
      <div class="head">
        <div>DESIGNER INFORMATION</div>
      </div>
      <div class="content">
        <div class="col">
          <label for="name">NAME<span> *</span></label>
          <input type="text" name="name" [(ngModel)]="registration.name" required>
          <label for="last">LAST NAME<span> *</span></label>
          <input type="text" name="last" [(ngModel)]="registration.lastname" required>
        </div>
        <div class="col">
          <label for="headshot">DESIGNER HEADSHOT<span> *</span></label>
          <input type="file" name="headshot" id="headshot" style="display: none;" (change)="detectFiles($event)">
          <button [class.done]="registration.headshot != null" type="button" (click)="checkUpload('headshot')">ATTACHMENT 1</button>
          <div class="uploadMenu" [class.active]="uploadMenus['headshot']">
            <div class="option" (click)="download('headshot')">Open File</div>
            <div class="option" (click)="upload('headshot')">Upload New</div>
          </div>
          <label for="logo">BRAND LOGO<span> *</span></label>
          <input type="file" name="logo" id="logo" style="display: none;" (change)="detectFiles($event)">
          <button [class.done]="registration.logo != null" type="button" (click)="checkUpload('logo')">ATTACHMENT 2</button>
          <div class="uploadMenu" [class.active]="uploadMenus['logo']">
            <div class="option" (click)="download('logo')">Open File</div>
            <div class="option" (click)="upload('logo')">Upload New</div>
          </div>
        </div>
        <div class="bigCol">
          <label for="cv">CV/BIO<span> *</span></label>
          <textarea name="cv" id="cv" cols="30" rows="10" [(ngModel)]="registration.cv" required></textarea>
          <div class="hint">MAX. 350 CHARACTERS</div>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="head">
        <div>BRAND INFORMATION</div>
      </div>
      <div class="content">
        <div class="col">
          <label for="brandname">BRAND <br>NAME<span> *</span></label>
          <input type="text" name="brandname" [(ngModel)]="registration.brandname" required>
          <label for="contact">CONTACT <br>NAME<span> *</span></label>
          <input type="text" name="contact" [(ngModel)]="registration.contact" required>
          <label for="email">E-MAIL<span> *</span></label>
          <input class="largeInput" type="text" name="email" [(ngModel)]="registration.email" required>
          <label for="product">PRODUCT<span> *</span></label><br>
          <div class="product">
            <label for="women">WOMEN</label><input type="checkbox" name="product" id="women" value="women" [(ngModel)]="registration.product.women"/><div class="checkbox"></div><br>
            <label for="men">MEN</label><input type="checkbox" name="product" id="men" value="men" [(ngModel)]="registration.product.men"/><div class="checkbox"></div><br>
            <label for="other">OTHER</label><input type="checkbox" name="product" id="other" value="other" [(ngModel)]="registration.product.other"/><div class="checkbox"></div><br>
          </div>
          <label for="facebook">FACEBOOK</label>
          <input class="largeInput" type="text" name="facebook" [(ngModel)]="registration.facebook">
          <label for="instagram">INSTAGRAM</label>
          <input class="largeInput" type="text" name="instagram" [(ngModel)]="registration.instagram">
        </div>
        <div class="col">
          <label for="address">ADDRESS<span> *</span></label>
          <input class="largeInput" type="text" name="address" style="margin-bottom:1vh;" [(ngModel)]="registration.address" required>
          <input class="largeInput" type="text" name="address2" [(ngModel)]="registration.address2">
          <label for="city">CITY<span> *</span></label>
          <input class="largeInput" type="text" name="city" [(ngModel)]="registration.city" required>
          <label for="state">STATE<span> *</span></label>
          <input class="largeInput" type="text" name="state" [(ngModel)]="registration.state" required>
          <label for="country">COUNTRY<span> *</span></label>
          <input class="largeInput" type="text" name="country" [(ngModel)]="registration.country" required>
          <label for="zip">ZIP CODE<span> *</span></label>
          <input class="largeInput" type="text" name="zip" [(ngModel)]="registration.zip" required>
          <label for="twitter">TWITTER</label>
          <input class="largeInput" type="text" name="twitter" [(ngModel)]="registration.twitter">
        </div>
      </div>
    </div>
    <div class="section">
      <div class="head">
        <div>COLLECTION INFORMATION</div>
      </div>
      <div class="content">
        <div class="col">
          <label for="title">TITLE<span> *</span></label>
          <input class="tallInput" type="text" name="title" [(ngModel)]="registration.collection" required>
        </div>
        <div class="col">
          <label for="lookbook">LOOKBOOK/ <br>PHOTOS <span> *</span></label>
          <input type="file" name="lookbook" id="lookbook" style="display: none;" (change)="detectFiles($event)">
          <button [class.done]="registration.lookbook != null" type="button" (click)="checkUpload('lookbook')">ATTACHMENT 3</button>
          <div class="uploadMenu" [class.active]="uploadMenus['lookbook']">
            <div class="option" (click)="download('lookbook')">Open File</div>
            <div class="option" (click)="upload('lookbook')">Upload New</div>
          </div>
          <div class="hint">MAX. 7MB<br>HIGH RES (300 DPI)<br>PAST LOOKBOOKS ARE ACEPTED</div>
        </div>
        <div class="bigCol">
          <label for="description">DESCRIPTION<span> *</span></label>
          <textarea name="description" id="description" cols="30" rows="10" [(ngModel)]="registration.description" required></textarea>
          <div class="hint">MAX. 350 CHARACTERS</div>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="head">
        <div>PRESS INFORMATION</div>
      </div>
      <div class="content">
        <div class="col">
          <label for="pressname">CONTACT <br>NAME <span> *</span></label>
          <input type="text" name="pressname" [(ngModel)]="registration.pressname" required>
        </div>
        <div class="col">
          <label class="moveup" for="pressemail">E-MAIL<span> *</span></label>
          <input class="moveup largeInput" type="text" name="pressemail" [(ngModel)]="registration.pressemail" required>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="head">
        <div>PLEASE REMEMBER</div>
      </div>
      <div class="content">
        <div class="bigCol">
          <div class="remainder" [class.done]="registration.headshot != null">ATTACHMENT 1: DESIGNER HEADSHOT *</div>
          <div class="remainder" [class.done]="registration.logo != null">ATTACHMENT 2: BRAND LOGO *</div>
          <div class="remainder" [class.done]="registration.lookbook != null">ATTACHMENT 3: LOOKBOOK / PHOTOS *</div>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="content">
        <div class="bigCol">
          <input class="chkxs" type="checkbox" name="tos" id="tos" value="true" [(ngModel)]="tos"/><div class="checkbox"></div>
          <div class="tos">I have read and accept the <a href="">Terms and Conditions</a></div>
          <button class="submit" type="submit">SUBMIT</button>
        </div>
      </div>
    </div>
  </form>
</div>