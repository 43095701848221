<app-loading *ngIf="loading || imagesService.loading"></app-loading>
<div class="flex-space p20" *ngIf="!loading && designer && !imagesService.loading">
  <div class="img-space">
    <img [src]="imagesService.imagesResource[designer.img.src]" alt="" [appEditableImage]="editingMode" [editRoute]="'img'" [image]="designer.img">
  </div>
  <div class="text-space">
    <div class="underline">
      <div class="title1">{{designer.name}}</div>
    </div>
    <div [ngClass]="designer.bio.style" [innerHTML]="languageService.stringsResource[designer.bio.content]" [appEditableText]="editingMode" [editRoute]="'bio'" [element]="designer.bio"></div>
    <button class="btn-next" (click)="nextDesigner()">NEXT DESIGNER</button>
  </div>
</div>
<img *ngIf="!loading && designer" src="../assets/instagram-logo.png" alt="" class="instagram clickable" (click)="!editingMode && navigate(designer.instagram)" [appEditableButton]="editingMode" [editRoute]="'instagram'" [link]="designer.instagram">
