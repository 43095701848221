import { Component, OnInit, Input } from '@angular/core';
import { LanguageService } from '../language.service';
import { ImagesService } from '../images.service';
import { FormsService } from '../forms.service';
import { Router } from '@angular/router';
import { FormGroup, FormControl } from '@angular/forms';
import { UploadService } from '../uploads/shared/upload.service';
import { Upload } from '../uploads/shared/upload';
import { Registration } from '../models';
import { Question } from '../question-base';
import { HttpClient } from '@angular/common/http';
import { interval } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { RegistrationsComponent } from '../dashboard/registrations/registrations.component';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.css'],
})
export class RegistrationComponent implements OnInit {
  @Input() editingMode: boolean;

  form: FormGroup;

  loading = true;
  subscribed = false;
  registration: Registration;
  currentUpload: Upload;
  currentUploadKey: string;
  submited = false;
  error = '';
  uploads = {};

  constructor(
    private languageService: LanguageService,
    private imagesService: ImagesService,
    private formsService: FormsService,
    private upSvc: UploadService,
    private router: Router,
    private http: HttpClient
  ) {
    if (!this.subscribed) {
      this.subscribed = true;
      this.formsService.currentForm.subscribe(form => {
        this.registration = form;
        this.loading = true;
        interval(100)
          .pipe(takeWhile(() => this.loading))
          .subscribe(i => {
            this.reloadComponent();
          });
      });
    }
  }

  ngOnInit() { }

  validate() {
    if (!this.form.valid) {
      this.error = this.languageService.stringsResource['formError'];
    } else {
      const registration = this.form.value;
      if (!this.registration.path.startsWith('sponsors')) {

        for (const key in this.uploads) {
          if (this.uploads[key] == null) {
            this.error = this.languageService.stringsResource['uploadError'];
            return;
          } else {
            registration[key] = this.uploads[key].url;
          }
        }
      }
      registration['step2'] = false;
      registration['applicationDate'] = new Date().toISOString();
      this.formsService.saveRegistration(registration, this.registration.path);
      this.submited = true;
    }
  }

  upload(key: string) {
    this.error = '';
    this.currentUploadKey = key;
    (<HTMLInputElement>document.getElementById(key)).click();
  }

  detectFiles(event) {
    if (event.target == null || event.target.files == null || event.target.files.length === 0) {
      return;
    }
    const file = event.target.files.item(0);
    this.currentUpload = new Upload(file);
    this.uploads[this.currentUploadKey] = this.currentUpload;
    this.upSvc.pushUpload(this.currentUpload, url => {
      this.currentUpload = null;
    });
  }

  reloadComponent() {
    if (this.imagesService.imagesResource == null) {
      return;
    }
    this.imagesService.loadHeader(this.imagesService.imagesResource[this.registration.img_1.src]);
    this.loading = false;

    const formObject = {};

    if (this.registration.leftForm != null) {
      for (let i = 0; i < this.registration.leftForm.length; i++) {
        const input = this.registration.leftForm[i] as Question;
        if (input.type === 'text' || input.type === 'email' || input.type === 'textarea' || input.type === 'number') {
          formObject[input.key] = new FormControl({ value: '', disabled: this.editingMode });
        } else if (input.type === 'upload') {
          formObject[input.key] = new FormControl({ value: null, disabled: this.editingMode });
          this.uploads[input.key] = null;
        } else if (input.type === 'bool') {
          formObject[input.key] = new FormControl({ value: false, disabled: this.editingMode });
        }
      }
    }

    if (this.registration.rightForm != null) {
      for (let i = 0; i < this.registration.rightForm.length; i++) {
        const input = this.registration.rightForm[i];
        if (input.type === 'text' || input.type === 'email' || input.type === 'textarea' || input.type === 'number') {
          formObject[input.key] = new FormControl({ value: '', disabled: this.editingMode });
        } else if (input.type === 'upload') {
          formObject[input.key] = new FormControl({ value: null, disabled: this.editingMode });
          this.uploads[input.key] = null;
        } else if (input.type === 'bool') {
          formObject[input.key] = new FormControl({ value: false, disabled: this.editingMode });
        }
      }
    }

    this.form = new FormGroup(formObject);
  }

  navigate(path: string) {
    if (path != null) {
      if (path.indexOf('http') > -1) {
        window.location.href = path;
      } else {
        this.router.navigateByUrl('/' + path);
      }
    }
  }
}
