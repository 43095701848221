import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthguardService } from "./auth/authguard.service";
import { HomeComponent } from "./home/home.component";
import { AboutComponent } from "./about/about.component";
import { ScheduleComponent } from "./schedule/schedule.component";
import { RunwaysComponent } from "./runways/runways.component";
import { AbanderasComponent } from "./abanderas/abanderas.component";
import { MiafwbashComponent } from "./miafwbash/miafwbash.component";
import { TicketsComponent } from "./tickets/tickets.component";
import { SponsorsComponent } from "./sponsors/sponsors.component";
import { DesignersComponent } from "./designers/designers.component";
import { ModelsComponent } from "./models/models.component";
import { SponsorsregComponent } from "./sponsorsreg/sponsorsreg.component";
import { GalleryComponent } from "./gallery/gallery.component";
import { PressregComponent } from "./pressreg/pressreg.component";
import { VideoGalleryComponent } from "./gallery/videogallery.component";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";
import { SecondStepComponent } from "./designers/second-step/second-step.component";
import { BlogComponent } from "./blog/blog.component";
import { PressComponent } from "./press/press.component";
import { VolunteersComponent } from "./volunteers/volunteers.component";
import { PostComponent } from "./blog/post/post.component";
import { BlogListComponent } from "./blog/list/list.component";
import { Gallery2017Component } from "./gallery2017/gallery2017.component";
import { FirstFacesComponent } from "./first-faces/first-faces.component";
import { FirstFacesVotesComponent } from "./first-faces/votes/first-faces-votes.component";
import { FirstFacesTVComponent } from "./first-faces/tv/first-faces-tv.component";
import { DesignersLandingComponent } from "./designers/landing/designers-landing.component";
import { ExperiencesComponent } from "./experiences/experiences.component";
import { TvSeriesComponent } from "./tvseries/tvseries.component";
import { DesignersProfileComponent } from "./designers/profile/designers-profile.component";
import { ArtWeekComponent } from "./art-week/art-week.component";
import { EventsComponent } from "./events/events.component";
import { TvContentComponent } from "./tvcontent/tvcontent.component";
import { ContactComponent } from "./contact/contact.component";
import { ThankYouComponent } from "./thank-you/thank-you.component";
import { BuyExperienceComponent } from "./buy-experience/buy-experience.component";
import { BuyGalaComponent } from "./buy-gala/buy-gala.component";
import { BuyRunwaysComponent } from "./buy-runways/buy-runways.component";
import { BuySummitComponent } from "./buy-summit/buy-summit.component";
import { NewsComponent } from "./news/news.component";
import { CarrouselpageComponent } from "./carrouselpage/carrouselpage.component";
import { LoadingComponent } from "./components/loading/loading.component";

export const routes: Routes = [
  { path: "", component: HomeComponent },
  { path: "about", component: AboutComponent },
  { path: "firstfaces", component: FirstFacesComponent },
  // { path: 'votes', component: FirstFacesVotesComponent },
  { path: "contact", component: ContactComponent },
  { path: "firstfacesTV", component: FirstFacesTVComponent },
  { path: "events", component: EventsComponent },
  { path: "tvcontent", component: TvContentComponent },
  { path: "schedule", component: ScheduleComponent },
  { path: "runways", component: RunwaysComponent },
  { path: "artweek", component: ArtWeekComponent },
  { path: "event/:id", component: CarrouselpageComponent },
  { path: "bash", component: MiafwbashComponent },
  { path: "tickets", component: TicketsComponent },
  { path: "experiences", component: ExperiencesComponent },
  { path: "tvseries", component: TvSeriesComponent },
  { path: "sponsors", component: SponsorsComponent },
  { path: "designers", component: DesignersComponent },
  { path: "designers-info", component: DesignersLandingComponent },
  { path: "designer/:year/:id", component: DesignersProfileComponent },
  { path: "models", component: ModelsComponent },
  { path: "new-sponsors", component: SponsorsregComponent },
  { path: "press", component: PressregComponent },
  { path: "volunteers", component: VolunteersComponent },
  { path: "gallery/:path", component: GalleryComponent },
  { path: "gallery/:path/:index", component: GalleryComponent },
  { path: "gallery", redirectTo: "gallery/all" },
  { path: "gallery2017", component: Gallery2017Component },
  { path: "gallery2018", redirectTo: "gallery/open|0" },
  { path: "video2018", redirectTo: "video/open|3" },
  { path: "video/:path", component: VideoGalleryComponent },
  { path: "video/:path/:index", component: VideoGalleryComponent },
  { path: "video", component: VideoGalleryComponent },
  { path: "designer/2step/:id", component: SecondStepComponent },
  { path: "blog", component: BlogComponent },
  { path: "pressblog", component: PressComponent },
  { path: "news", component: NewsComponent },
  {
    path: "auth",
    loadChildren: () => import("./auth/auth.module").then((m) => m.AuthModule),
  },
  {
    path: "dashboard",
    loadChildren: () =>
      import("./dashboard/dashboard.module").then((m) => m.DashboardModule),
    canActivate: [AuthguardService],
  },
  { path: "404", component: PageNotFoundComponent },
  { path: "experiencetickets", component: BuyExperienceComponent },
  { path: "galatickets", component: BuyGalaComponent },
  { path: "runwaystickets", component: BuyRunwaysComponent },
  { path: "summittickets", component: BuySummitComponent },
  { path: "thankyou", component: ThankYouComponent },
  { path: "posts/:category", component: BlogListComponent },
  { path: ":post", component: PostComponent, pathMatch: "full" },
  { path: "view-post/:post", component: PostComponent, pathMatch: "full" },
  { path: "news", component: NewsComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: "legacy" })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
