import { Gallery, VideoGallery } from '../models';

import { AngularFireDatabase } from '@angular/fire/database';
import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { take } from 'rxjs/operators';

@Injectable()
export class GalleryService {
  gallerySubject: BehaviorSubject<Gallery> = new BehaviorSubject(null);
  videoGallerySubject: BehaviorSubject<VideoGallery> = new BehaviorSubject(null);
  loading: boolean;

  constructor(private db: AngularFireDatabase) { }

  loadGallery(path?: string) {
    this.loading = true;
    if (path == null) {
      this.db
        .object('/components/gallery')
        .valueChanges()
        .subscribe(raw => {
          const gallery = raw as Gallery;
          gallery.path = '/components/gallery';
          this.gallerySubject.next(gallery);
          this.loading = false;
        });
    } else {
      this.db
        .object(path)
        .valueChanges()
        .pipe(take(1))
        .subscribe(raw => {
          const gallery = raw as Gallery;
          gallery.path = path;
          if (gallery.img_1 == null) {
            gallery.img_1 = { src: 'gallery1' };
            this.gallerySubject.next(gallery);
            this.loading = false;
          } else {
            this.gallerySubject.next(gallery);
            this.loading = false;
          }
        });
    }
  }

  loadVideoGallery(path?: string) {
    this.loading = true;
    if (path == null) {
      this.db
        .object('/components/videogallery')
        .valueChanges()
        .subscribe(raw => {
          const gallery = raw as VideoGallery;
          gallery.path = '/components/videogallery';
          this.videoGallerySubject.next(gallery);
          this.loading = false;
        });
    } else {
      this.db
        .object(path)
        .valueChanges()
        .pipe(take(1))
        .subscribe(raw => {
          if (raw == null) {
            this.db
              .object('/components/videogallery')
              .valueChanges()
              .subscribe(raw2 => {
                const gallery = raw2 as VideoGallery;
                gallery.path = '/components/videogallery';
                const index = Number(path);

                if (index !== undefined) {
                  const newGallery = gallery.galleries[index];
                  this.videoGallerySubject.next(newGallery);
                  this.loading = false;
                }
              });
          } else {
            const gallery = raw as VideoGallery;
            gallery.path = path;
            if (gallery.img_1 == null) {
              gallery.img_1 = { src: 'gallery1' };
              this.videoGallerySubject.next(gallery);
              this.loading = false;
            } else {
              this.videoGallerySubject.next(gallery);
              this.loading = false;
            }
          }
        });
    }
  }

  navigateSubGallery(gal: Gallery) {
    this.gallerySubject.next(gal);
  }

  saveGallery(gal: Gallery, callback: () => void) {
    const updated = Object.assign({}, gal);
    delete updated.path;
    this.db
      .object(gal.path)
      .update(updated)
      .then(() => {
        callback();
      });
  }

  navigateSubVideoGallery(gal: VideoGallery) {
    this.videoGallerySubject.next(gal);
  }

  saveVideoGallery(gal: VideoGallery, callback: () => void) {
    const updated = Object.assign({}, gal);
    delete updated.path;
    this.db
      .object(gal.path)
      .update(updated)
      .then(() => {
        callback();
      });
  }
}
