<app-loading *ngIf="loading || imagesLoading"></app-loading>
<div *ngIf="!loading && !imagesLoading" [class.editingPadding]="editingMode">
  <div class="section bgImg" [appEditableImage]="editingMode" [editRoute]="'img_1'" [image]="firstfaces.img_1"
    [position]="'translate(-50%, -1400%)'">
  </div>
  <div class="box1">
    <div [ngClass]="firstfaces.box1.title.style"
      [innerHTML]="languageService.stringsResource[firstfaces.box1.title.content]" [appEditableText]="editingMode"
      [editRoute]="'box1.title'" [element]="firstfaces.box1.title" [negative]="true">
    </div>
    <div [ngClass]="firstfaces.box1.subtitle.style"
      [innerHTML]="languageService.stringsResource[firstfaces.box1.subtitle.content]" [appEditableText]="editingMode"
      [editRoute]="'box1.subtitle'" [element]="firstfaces.box1.subtitle" [negative]="true">
    </div>
    <div class="division"></div>
    <div class="flex-space">
      <div class="txt-space">
        <div [ngClass]="firstfaces.box1.body.style"
          [innerHTML]="languageService.stringsResource[firstfaces.box1.body.content]" [appEditableText]="editingMode"
          [editRoute]="'box1.body'" [element]="firstfaces.box1.body" [negative]="true"></div>
      </div>
      <div class="btn-space" *ngIf="!editingMode && firstfaces.votingEnabled">
        <div class="btn-vote" (click)="navigate('votes')">VOTE FOR
          <br> FIRST FACES 2018</div>
      </div>
    </div>
    <div>
    </div>
  </div>
  <div class="flex-space show">
    <div class="btn-space">
      <img src="/assets/firstfaces/Poster.png" alt=""> </div>
    <div class="txt-space">
      <div [ngClass]="firstfaces.box2.title.style"
        [innerHTML]="languageService.stringsResource[firstfaces.box2.title.content]" [appEditableText]="editingMode"
        [editRoute]="'box2.title'" [element]="firstfaces.box2.title"></div>
      <br>
      <div [ngClass]="firstfaces.box2.body.style"
        [innerHTML]="languageService.stringsResource[firstfaces.box2.body.content]" [appEditableText]="editingMode"
        [editRoute]="'box2.body'" [element]="firstfaces.box2.body"></div>
      <button (click)="navigate('firstfacesTV')" class="btn-watch">WATCH SHOW</button>
    </div>
  </div>
  <div class="title1 txtCenter">FIRST FACES 2019</div>
  <div class="ff-space" *ngIf="firstfaces.models2019">
    <div class="ff ff-eighteen" *ngFor="let model of firstfaces.models2019; let i = index;">
      <!-- <img [src]="imagesService.imagesResource[model.img.src]" alt="" [appEditableImage]="editingMode"
        [editRoute]="'models2019.'+i+'.img'" [image]="model.img"> -->
      <div class="ctn" [class]="'models2019-'+i" style="position: absolute; min-width: 100%;  background-size: cover;
      background-position: center;" [appEditableImage]="editingMode" [editRoute]="'models2019.'+i+'.img'"
        [image]="model.img">
      </div>
      <div class="name-space" [appEditableText]="editingMode" [editRoute]="'models2019.'+i+'.name'"
        [element]="model.name">
        {{languageService.stringsResource[model.name.content]}}</div>
    </div>
  </div>
  <div class="title1 txtCenter">FIRST FACES 2018</div>
  <div class="ff-space" *ngIf="firstfaces.models2018">
    <div class="ff ff-eighteen" *ngFor="let model of firstfaces.models2018; let i = index;">
      <!-- <img [src]="imagesService.imagesResource[model.img.src]" alt="" [appEditableImage]="editingMode"
        [editRoute]="'models2018.'+i+'.img'" [image]="model.img"> -->
      <div class="ctn" [class]="'models2018-'+i" style="position: absolute; min-width: 100%;  background-size: cover;
      background-position: center;" [appEditableImage]="editingMode" [editRoute]="'models2018.'+i+'.img'"
        [image]="model.img">
      </div>
      <div class="name-space" [appEditableText]="editingMode" [editRoute]="'models2018.'+i+'.name'"
        [element]="model.name">
        {{languageService.stringsResource[model.name.content]}}</div>
    </div>
  </div>
  <div *ngIf="!seventeenVisible" class="title1 txtCenter" style="cursor: pointer; margin-top: 5%;"
    (click)="seventeenVisible = true">FIRST FACES 2017 &#x25BC;</div>
  <div *ngIf="seventeenVisible" class="title1 txtCenter" style="cursor: pointer; margin-top: 5%;"
    (click)="seventeenVisible = false">FIRST FACES 2017 &#x25B2;</div>
  <div class="ff-space" *ngIf="firstfaces.models2017" style="display: none;" [class.visible]="seventeenVisible">
    <div class="ff" *ngFor="let model of firstfaces.models2017; let i = index;">
      <img [src]="imagesService.imagesResource[model.img.src]" alt="" [appEditableImage]="editingMode"
        [editRoute]="'models2017.'+i+'.img'" [image]="model.img">
      <div class="name-space">{{model.name}}</div>
    </div>
  </div>
</div>
