import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { Element } from '../models';
import { LanguageService } from '../language.service';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.css']
})
export class AlertComponent implements OnInit {

  @Input() title: string;
  @Input() body: string;
  @Input() acceptTitle = 'Aceptar';
  @Input() cancelTitle;

  @Output() accepted = new EventEmitter();

  constructor() {
  }

  ngOnInit() {
  }

  accept() {
    this.accepted.emit(false);
  }
}
