<!-- <app-loading *ngIf="loading"></app-loading> -->
<div class="body-wrapper">
  <app-sidebar></app-sidebar>
  <ng-container *ngIf="event">
    <div class="titles">
      <span>{{ event.title[currentLanguage] }}</span>
      <span>{{ event.subTitle[currentLanguage] }}</span>
    </div>

    <div class="first-carrousel">
      <carousel
        [arrows]="false"
        [cellWidth]="500"
        [height]="400"
        [autoplay]="true"
      >
        <div
          class="carousel-cell"
          *ngFor="let image of event.first_carrousel; let i = index"
        >
          <img [src]="loadSanitize(image)" alt="" />
        </div>
      </carousel>
    </div>

    <div class="row-1">
      <div class="header-title">
        <span>{{ event.section_1.title[currentLanguage] }}</span>
        <span>{{ event.section_1.subTitle[currentLanguage] }}</span>
      </div>

      <div *ngIf="event.section_1.image" class="header-image">
        <img [src]="event.section_1.image" alt="" />
      </div>

      <div class="stats">
        <div class="item" *ngIf="event.section_1.stats.attends">
          <div class="num">{{ event.section_1.stats.attends }}</div>
          <span>Attendees</span>
        </div>
        <div class="item" *ngIf="event.section_1.stats.livesImpact">
          <div class="num">{{ event.section_1.stats.livesImpact }}</div>
          <span>Lives Impact</span>
        </div>
        <div class="item" *ngIf="event.section_1.stats.specialEvents">
          <div class="num">{{ event.section_1.stats.specialEvents }}</div>
          <span>Special Events</span>
        </div>
      </div>
    </div>

    <div class="row-2">
      <div class="video" *ngIf="event.section_2.video">
        <iframe
          [src]="loadSanitize(event.section_2.video)"
          frameborder="0"
          allowfullscreen
          webkitallowfullscreen
          mozallowfullscreen
        ></iframe>
      </div>
      <div class="smalltext">
        <p>{{ event.section_2.text[currentLanguage] }}</p>
      </div>
    </div>

    <div class="row-3">
      <div class="item-1">
        <div
          class="title"
          [innerHTML]="event.section_3.item_1.title[currentLanguage]"
        ></div>

        <div
          class="smalltext"
          [innerHTML]="event.section_3.item_1.text[currentLanguage]"
        ></div>

        <div *ngIf="event.section_3.item_1.img" class="image">
          <img [src]="event.section_3.item_1.img" alt="" />
        </div>
      </div>

      <div class="item-2">
        <div
          class="title"
          [innerHTML]="event.section_3.item_2.title[currentLanguage]"
        ></div>

        <div
          class="smalltext"
          [innerHTML]="event.section_3.item_2.text[currentLanguage]"
        ></div>

        <div *ngIf="event.section_3.item_2.img" class="image">
          <img [src]="event.section_3.item_2.img" alt="" />
        </div>
      </div>
    </div>

    <div class="carrousel-2">
      <div class="title-arrows">
        <div class="titles">
          <span>Relive the event</span>
          <span>Private show by Roberto Cavalli</span>
        </div>
        <div class="arrows">
          <div class="arrow-left clickable" (click)="scrollLeft()">
            <img src="\assets\arrow-icon.png" alt="" />
          </div>

          <div class="arrow-right clickable" (click)="scrollRight()">
            <img src="\assets\arrow-icon.png" alt="" />
          </div>
        </div>
      </div>
      <div *ngIf="event.second_carrousel" class="carrousel">
        <carousel [arrows]="false" #myCarousel [cellWidth]="400" [height]="400">
          <div
            class="carousel-cell"
            *ngFor="let image of event.second_carrousel; let i = index"
          >
            <img [src]="loadSanitize(image)" alt="" />
          </div>
        </carousel>
      </div>
    </div>

    <div class="row-4">
      <div
        *ngIf="event.section_4.image"
        class="img"
        [ngStyle]="{
          'background-image': ' url(' + event.section_4.image + ')'
        }"
      >
        <div class="text">
          <span>{{ event.section_4.title[currentLanguage] }}</span>
          <span>{{ event.section_4.subTitle[currentLanguage] }}</span>
        </div>
      </div>
    </div>

    <div class="row-5">
      <span>Gallery</span>
      <div class="grid">
        <img
          *ngFor="let image of event.gallery; let i = index"
          class="clickable"
          alt=""
          [src]="loadSanitize(image)"
        />
      </div>
    </div>
  </ng-container>
</div>
