import { Component, OnInit, OnDestroy, Inject, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularFireDatabase } from '@angular/fire/database';
import { PostsService } from '../../posts.service';
import { NavbarService } from '../../navbar.service';
import { LanguageService } from '../../language.service';
import { take } from 'rxjs/internal/operators/take';
import { DOCUMENT } from "@angular/common";
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-post',
  templateUrl: './post-new.component.html',
  styleUrls: ['./post-new.component.scss', '../../scss/app.scss', '../../scss/index.scss'],
})
export class PostComponent implements OnInit, OnDestroy {
  postLink: string;
  postData: any;
  loading = true;
  blog: any;
  loadCount = [5, 6, 3, 1, 4];
  latest: Array<any>;
  categorized: Array<Array<any>> = [];
  loadingCategories = 1;
  pdfSrc = 'https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/sample.pdf?alt=media&token=e4fe6669-8406-4b66-9262-a4b4c75a6d2c'

  selectedIndex = -1;
  selectedImage = null;
  press = false;
  currentLang;

  windowScrolled: boolean;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private db: AngularFireDatabase,
    private postsService: PostsService,
    private navbarService: NavbarService,
    private languageService: LanguageService,
    @Inject(DOCUMENT) private document: Document,
    private http: HttpClient
  ) {
    languageService.notifier.subscribe(lang => {
      if (this.postLink != null && this.postData != null && lang != null) {
        this.loadPost();
      }
    });
  }

  ngOnInit() {
    this.navbarService.white.next(false);
    this.languageService.notifier.subscribe((lang) => {
      this.currentLang = lang;
    });
    this.route.paramMap.subscribe(path => {
      this.postLink = decodeURIComponent(path.get('post'));
      if (this.postLink == null || this.postLink === '') {
        this.router.navigateByUrl('/');
      } else {
        // Load section images
        this.loadPost();
      }
    });
  }

  downloadPdf(postData) {
    return this.http.get(postData.pdf, { responseType: 'blob' as 'json' }).subscribe((res: any) => {
      var file = new Blob([res], { type: 'application/pdf' });

      var FileSaver = require('file-saver');
      FileSaver.saveAs(file, postData.title.rendered + ".pdf");

    });
  }

  goToLink(link) {
    window.open(link, "_blank");
  }

  loadPost() {
    const promisse = this.db
      .object('pages/press')
      .valueChanges()
      .pipe(take(1));
    promisse.subscribe(raw => {
      this.blog = raw;
      for (let i = 0; i < this.blog.sections.length; i++) {
        if (this.blog.sections[i].category != null && this.blog.sections[i].category[this.languageService.currentLanguage] != null) {
          // eslint-disable-next-line max-len
          this.postsService.getPostsCategory(
            this.languageService.currentLanguage,
            this.blog.sections[i].category[this.languageService.currentLanguage],
            (posts, category) => {
              this.categorized[category] = posts;
              this.loadingCategories -= 1;
            },
            this.loadCount[i]
          );
        }
      }
    });
    this.postsService.getAllPosts(
      this.languageService.currentLanguage,
      posts => {
        this.loading = false;
        this.latest = posts;
      },
      this.loadCount[0]
    );

    this.postsService.getPostWithLink(this.postLink, post => {
      if (post == null) {
        this.router.navigateByUrl('404');
      } else {
        if (post.content != null) {
          const div = document.createElement('div');
          div.innerHTML = post.content.rendered;
          const divs = div.getElementsByTagName('div');
          while (divs[0]) {
            divs[0].parentNode.removeChild(divs[0]);
          }

          const figures = div.getElementsByTagName('figure');
          while (figures[0]) {
            figures[0].parentNode.removeChild(figures[0]);
          }

          const imgs = div.getElementsByTagName('img');
          while (imgs[0]) {
            imgs[0].parentNode.removeChild(imgs[0]);
          }

          post.content.rendered = div.innerHTML;
        } else if (post.content3A != null) {
          this.press = true;
          post.content = post;
        }

        this.postData = post;
        if (post.format != null && post.gallery == null) {
          this.postsService.getLegacyMedia(post.id, media => {
            if (media != null) {
              const gallery = [];
              for (const m of media) {
                gallery.push(m.source_url);
              }
              this.postData.gallery = gallery;
            } else {
            }
            this.loading = false;
          });
        } if (post.format != null && post.gallery2 == null) {
          this.postsService.getLegacyMedia(post.id, media => {
            if (media != null) {
              const gallery2 = [];
              for (const m of media) {
                gallery2.push(m.source_url);
              }
              this.postData.gallery2 = gallery2;
            } else {
            }
            this.loading = false;
          });
        } else {
          this.loading = false;
        }
      }
    });
  }

  nextImage() {
    this.selectedIndex = this.selectedIndex === this.postData.gallery.length - 1 ? 0 : this.selectedIndex + 1;
    this.selectedImage = this.postData.gallery[this.selectedIndex];
  }

  previousImage() {
    this.selectedIndex = this.selectedIndex === 0 ? this.postData.gallery.length - 1 : this.selectedIndex - 1;
    this.selectedImage = this.postData.gallery[this.selectedIndex];
  }

  ngOnDestroy() {
    this.navbarService.white.next(false);
  }

  loadBGImage(post: any, category: string, index: number) {
    if (post.thumbnail == null) {
      return '';
    }

    const sheet = window.document.styleSheets[0] as CSSStyleSheet;
    if (sheet.cssRules) {
      for (let i = 0; i < sheet.cssRules.length; i++) {
        if (sheet.cssRules[i].cssText.includes('.' + category + index)) {
          return category + index;
        }
      }
    } else {
      for (let i = 0; i < sheet.rules.length; i++) {
        if ((sheet.rules[i] as any).includes('.' + category + index)) {
          return category + index;
        }
      }
    }
    if (sheet.cssRules) {
      // eslint-disable-next-line max-len
      sheet.insertRule('.' + category + index + '{ background-image: url(\'' + post.thumbnail + '\');  background-size: auto 100%; background-position: 50% 50%;}');
    } else {
      // eslint-disable-next-line max-len
      sheet.addRule('.' + category + index + '{ background-image: url(\'' + post.thumbnail + '\'); background-size: auto 100%; background-position: 50% 50%; }');
    }

    return category + index;
  }

  openPost(post: any, event?: any) {
    if (event !== undefined) {
      event.preventDefault();
    }
    const sl = encodeURIComponent(post.slug)
      .split('?')
      .join('%3F');
    this.router.navigateByUrl('/' + sl);
    this.scrollToTop();
  }


  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop > 100) {
      this.windowScrolled = true;
    } else if (this.windowScrolled && window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop < 10) {
      this.windowScrolled = false;
    }
  }
  scrollToTop() {
    (function smoothscroll() {
      const currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
      if (currentScroll > 0) {
        window.requestAnimationFrame(smoothscroll);
        window.scrollTo(0, currentScroll - (currentScroll / 8));
      }
    })();
  }

  navigate(path: string) {
    if (path != null) {
      if (path.indexOf('http') > -1) {
        window.open(path);
      } else {
        this.router.navigateByUrl('/' + path);
      }
    }
  }
}
