import { Component, OnInit, Input, HostListener, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GalleryService } from './gallery.service';
import { LanguageService } from '../language.service';
import { ImagesService } from '../images.service';
import { Gallery } from '../models';
import { Location } from '@angular/common';
import { Upload } from '../uploads/shared/upload';
import { UploadService } from '../uploads/shared/upload.service';
import * as _ from 'lodash';
import { DomSanitizer } from '@angular/platform-browser';
import { interval } from 'rxjs';
import { takeWhile } from 'rxjs/operators';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.css'],
})
export class GalleryComponent implements OnInit, AfterViewInit {
  gallery: Gallery;
  headerLoading = true;
  selectedImage = null;
  currentIndex = -1;
  subGalIndex = null;

  self: any;

  navigationStack: Array<Gallery> = [];

  selectedFiles: FileList;
  currentUpload: Upload;
  currentFilesUploaded = 0;

  @Input() editingMode: boolean;

  rulesAdded = 0;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public imagesService: ImagesService,
    private galleryService: GalleryService,
    public languageService: LanguageService,
    private location: Location,
    private upSvc: UploadService,
    private sanitazer: DomSanitizer
  ) {
    this.galleryService.gallerySubject.subscribe(gal => {
      if (gal == null) {
        return;
      }
      this.gallery = gal as Gallery;
      this.headerLoading = true;
      this.rulesAdded = 0;
      this.initialLoad();
    });
    this.self = this;
  }

  @HostListener('window:popstate', ['$event'])
  private onPopState(event: any): any {
    if (this.navigationStack.length > 0) {
      this.galleryService.gallerySubject.next(this.navigationStack.pop());
    }

    event.preventDefault();
    event.stopPropagation();
  }

  loadSanitize(src: string) {
    return this.sanitazer.bypassSecurityTrustResourceUrl(this.imagesService.imagesResource[src]);
  }

  ngOnInit() {
    if (this.editingMode) {
      // Anything
    } else {
      this.route.paramMap.subscribe(path => {
        const realPath = path.get('path');
        if (realPath == null || realPath === 'all' || realPath === '') {
          // Load everything
          this.galleryService.loadGallery();
        } else if (realPath.startsWith('open|')) {
          this.galleryService.loadGallery();
          const i = realPath.split('|')[1];

          this.subGalIndex = i;
        } else {
          // Load section images
          this.galleryService.loadGallery(realPath.split('|').join('/'));
        }
        const index = path.get('index');
        if (index != null) {
          this.currentIndex = Number(index);
        }
      });
    }
  }

  ngAfterViewInit() {
    const gal = this.galleryService.gallerySubject.getValue();
    if (gal !== null) {
      this.gallery = gal;
      this.initialLoad();
    }
  }

  initialLoad() {
    interval(500)
      .pipe(takeWhile(() => this.headerLoading))
      .subscribe(i => {
        if (this.imagesService.loading || this.galleryService.loading) {
          return;
        }
        if (this.subGalIndex != null) {
          const subgallery = this.gallery.galleries[this.subGalIndex];
          this.navigateGallery(subgallery, '/galleries/' + this.subGalIndex, true);
          this.subGalIndex = null;
        } else {
          this.imagesService.loadHeader(this.imagesService.imagesResource[this.gallery.img_1.src]);
          if (this.currentIndex > -1 && this.currentIndex < this.gallery.images.length) {
            this.openImage(this.currentIndex);
          }
          if (!this.editingMode) {
            document.scrollingElement.scrollTop = 0;
          }
          this.headerLoading = false;
        }
      });
  }

  openImage(index: number) {
    this.currentIndex = index;
    this.selectedImage = this.imagesService.imagesResource[this.gallery.images[index].src];
  }

  closeImage() {
    this.currentIndex = -1;
    this.selectedImage = null;
  }

  previousImage() {
    this.currentIndex = this.currentIndex - 1;
    if (this.currentIndex < 0) {
      this.currentIndex = this.gallery.images.length - 1;
    }
    this.selectedImage = this.imagesService.imagesResource[this.gallery.images[this.currentIndex].src];
  }

  nextImage() {
    this.currentIndex = this.currentIndex + 1;
    if (this.currentIndex >= this.gallery.images.length) {
      this.currentIndex = 0;
    }
    this.selectedImage = this.imagesService.imagesResource[this.gallery.images[this.currentIndex].src];
  }

  navigateGallery(gal: Gallery, path: string, special: boolean = false) {
    if (!special) {
      this.navigationStack.push(this.gallery);
      gal.path = this.gallery.path + path;
    } else {
      this.navigationStack.push(gal);
    }
    this.galleryService.navigateSubGallery(gal);
    if (this.editingMode) {
      this.location.go('/dashboard/gallery');
    } else if (!special) {
      this.location.go('/gallery/');
    }
  }

  galClass(gal: Gallery, index: number) {
    if (gal == null || index == null) {
      return;
    }
    return 'galleryFolder' + index;
  }

  subGalleryImage(gal) {
    return this.sanitazer.bypassSecurityTrustStyle(`url('${this.imagesService.imagesResource[gal.img_1.src]}')`);
  }

  // For uploading (only editing mode)

  uploadImages() {
    if (!this.editingMode) {
      return;
    }
    (<HTMLInputElement>document.getElementById('newImgs')).click();
  }

  detectFiles(event) {
    if (!this.editingMode) {
      return;
    }
    if (event.target == null || event.target.files == null || event.target.files.length === 0) {
      return;
    }
    this.selectedFiles = event.target.files;
    if (this.gallery.images == null) {
      this.gallery.images = [];
    }
    this.uploadMulti();
  }

  uploadMulti() {
    if (!this.editingMode) {
      return;
    }
    const files = this.selectedFiles;
    const filesIndex = _.range(files.length);
    _.each(filesIndex, idx => {
      this.currentUpload = new Upload(files[idx]);
      this.upSvc.pushUpload(this.currentUpload, url => {
        const key = this.imagesService.addImageToResource(url);
        this.gallery.images.unshift({ src: key });
        this.currentFilesUploaded = this.currentFilesUploaded + 1;
        if (this.currentFilesUploaded === this.selectedFiles.length) {
          this.currentUpload = null;
          this.currentFilesUploaded = 0;
          this.selectedFiles = null;
          this.galleryService.saveGallery(this.gallery, () => { });
        }
      });
    });
  }
}
