import { AfterViewInit, Component, Input, OnInit } from '@angular/core';

import { AngularFireDatabase } from '@angular/fire/database';
import { BehaviorSubject } from 'rxjs';
import { FormsService } from '../forms.service';
import { LanguageService } from '../language.service';
import { Registration } from '../models';

@Component({
  selector: 'app-designers',
  templateUrl: './designers.component.html',
  styleUrls: ['./designers.component.css']
})
export class DesignersComponent implements OnInit, AfterViewInit {

  @Input() editingMode: boolean;
  @Input() editablePage;
  @Input() uiChanges: BehaviorSubject<boolean>;

  loading = true;

  constructor(private languageService: LanguageService, private db: AngularFireDatabase, private formsService: FormsService) { }

  ngOnInit() {
    if (this.editablePage != null) {
      this.formsService.currentForm.next(this.editablePage as Registration);
      this.loading = false;
    } else {
      const promisse = this.db.object('pages/registration_designer').valueChanges();
      promisse.subscribe((raw) => {
        this.loading = false;
        this.formsService.currentForm.next(raw as Registration);
      });
    }
  }

  ngAfterViewInit() {
    if (this.editingMode) {
      this.uiChanges.subscribe((changes) => {
        if (changes && this.editingMode) {
          this.formsService.currentForm.next(this.editablePage as Registration);
        }
      });
    }
  }

}
