import * as firebase from 'firebase/app';

import { Observable, timer } from 'rxjs';

import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireStorage } from '@angular/fire/storage';
import { Injectable } from '@angular/core';
import { Upload } from './upload';
import { ngxCsv } from 'ngx-csv/ngx-csv';

@Injectable()
export class UploadService {
  uploads: Observable<Upload[]>;
  private basePath = '/uploads';
  constructor(private db: AngularFireDatabase, private storage: AngularFireStorage) { }
  pushUpload(upload: Upload, callback: (l: string) => void) {
    const realName = upload.file.name
      .split(' ')
      .join('_')
      .split('.')
      .join('_')
      .split('$')
      .join('_')
      .split('[')
      .join('_')
      .split(']')
      .join('_')
      .split('#')
      .join('_')
      .split('/')
      .join('_');
    const uploadTask = this.storage.ref(`${this.basePath}/${realName}`).put(upload.file);
    uploadTask.snapshotChanges().subscribe(
      snapshot => {
        // upload in progress
        upload.progress = ((snapshot as any).bytesTransferred / (snapshot as any).totalBytes) * 100;
      },
      error => {
        // upload failed
        console.log(error);
      },
      () => {
        // upload success
        if (uploadTask.task.snapshot.metadata.contentType.includes('image')) {
          // Wait for proccesed
          upload.progress = 10;
          timer(1000).subscribe(() => {
            upload.progress = upload.progress + 10;
            this.waitForProcess(upload, callback);
          });
        } else {
          this.storage
            .ref(`${this.basePath}/${realName}`)
            .getDownloadURL()
            .subscribe(url => {
              upload.url = url;
              upload.name = realName;
              this.saveFileData(upload);
              callback(url);
            });
        }
      }
    );
  }

  deleteUpload(upload: Upload) {
    this.deleteFileData(upload.$key)
      .then(() => {
        this.deleteFileStorage(upload.name);
      })
      .catch(error => console.log(error));
  }

  downloadFile(url: string, filename: string) {
    // This can be downloaded directly:
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style.display = 'none';
    const xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.onload = (event) => {
      const blob = xhr.response;
      const blobURL = window.URL.createObjectURL(blob);
      a.href = blobURL;
      a.download = filename;
      a.click();
      window.URL.revokeObjectURL(blobURL);
    };
    xhr.open('GET', url);
    xhr.send();
  }

  downloadCSV(csv: any, filename: string, options) {
    // This can be downloaded directly:
    // const a = document.createElement('a');
    // document.body.appendChild(a);
    // a.style.display = 'none';
    // a.href = 'data:application/octet-stream,' + csv;
    // a.download = filename;
    // a.click();

    const data = [
      {
        name: 'Test 1',
        age: 13,
        average: 8.2,
        approved: true,
        description: 'using \'Content here, content here\' ',
      },
      {
        name: 'Test 2',
        age: 11,
        average: 8.2,
        approved: true,
        description: 'using \'Content here, content here\' ',
      },
      {
        name: 'Test 4',
        age: 10,
        average: 8.2,
        approved: true,
        description: 'using \'Content here, content here\' ',
      },
    ];

    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    new ngxCsv(csv, filename, options);
  }
  // Writes the file details to the realtime db
  private saveFileData(upload: Upload) {
    this.db.list(`${this.basePath}/`).push(upload);
  }

  private waitForProcess(upload: Upload, callback: (l: string) => void) {
    const realName = upload.file.name
      .split(' ')
      .join('_')
      .split('.')
      .join('_')
      .split('$')
      .join('_')
      .split('[')
      .join('_')
      .split(']')
      .join('_')
      .split('#')
      .join('_')
      .split('/')
      .join('_');
    this.storage
      .ref(`${this.basePath}/processed_${realName}`)
      .getDownloadURL()
      .subscribe(
        proccesedURL => {
          if (proccesedURL) {
            upload.progress = 100;
            upload.url = proccesedURL;
            upload.name = upload.file.name;
            this.saveFileData(upload);
            callback(proccesedURL);
          } else {
            timer(1000).subscribe(() => {
              upload.progress = Math.min(upload.progress + 10, 97);
              this.waitForProcess(upload, callback);
            });
          }
        },
        () => {
          timer(1000).subscribe(() => {
            upload.progress = Math.min(upload.progress + 10, 97);
            this.waitForProcess(upload, callback);
          });
        }
      );
  }

  // Deletes the file details from the realtime db
  private deleteFileData(key: string) {
    return this.db.list(`${this.basePath}/`).remove(key);
  }
  // Firebase files must have unique names in their respective storage dir
  // So the name serves as a unique key
  private deleteFileStorage(name: string) {
    this.storage.ref(`${this.basePath}/${name}`).delete();
  }
}
