import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { EditorService } from './dashboard/editor.service';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class LanguageService {

  public stringsResource: any;
  public loading = true;
  public currentLanguage: string;

  private multiLanguage = {en: {}, es: {}};
  private multiLingualEnabled = false;

  public notifier: BehaviorSubject<string> = new BehaviorSubject(null);

  constructor(private db: AngularFireDatabase, private editor: EditorService) {
    if (this.currentLanguage == null) {
      this.currentLanguage = 'en';
      this.loadLang();
    }
    this.editor.editingModeEnabled.subscribe((editing) => {
      if (editing) {
        this.loading = true;
        this.loadMultiLanguage();
      } else {
        this.multiLingualEnabled = false;
        this.multiLanguage = {en: {}, es: {}};
        this.loadLang();
      }
    });
  }

  changeLang(lang: string) {  
    if (this.multiLingualEnabled) {
      this.currentLanguage = lang;
      this.stringsResource = this.multiLanguage[lang];
      this.notifier.next(lang);
    } else {
      this.loading = true;
      this.currentLanguage = lang;
      this.loadLang();
    }
  }

  private loadMultiLanguage() {
    this.db.object('strings').valueChanges().subscribe((stringRaw) => {
      this.multiLanguage = stringRaw as any;
      this.multiLingualEnabled = true;
      this.loading = false;
    });
  }

  public multilingualString(key: string) {
    if (!this.multiLingualEnabled) { return; }
    return {en: this.multiLanguage.en[key], es: this.multiLanguage.es[key]};
  }

  public setStringForLanguage(key: string, val: string, lang: string) {
    if (!this.multiLingualEnabled) { return; }
    this.multiLanguage[lang][key] = val;
  }

  public setMultilingualString(key: string, val: any) {
    if (!this.multiLingualEnabled) { return; }
    for (const lang in val) {
      if (val[lang] !== undefined) {
        this.multiLanguage[lang][key] = val[lang];
      }
    }
    this.stringsResource = this.multiLanguage[this.currentLanguage];
  }

  public pushMultilingualString(val: any): string {
    if (!this.multiLingualEnabled) { return; }
    let key = null;
    for (const lang in val) {
      if (val[lang] !== undefined) {
        if (key === null) {
          key = this.db.list('strings/' + lang).push(val[lang]).key;
        } else {
          this.db.object('strings/' + lang + '/' + key).set(val[lang]);
        }
        this.multiLanguage[lang][key] = val[lang];
      }
    }
    this.stringsResource = this.multiLanguage[this.currentLanguage];
    return key;
  }

  private loadLang() {
    const stringsPromisse = this.db.object('strings/' + this.currentLanguage).valueChanges();
    stringsPromisse.subscribe((stringRaw) => {
      this.stringsResource = stringRaw;
      this.loading = false;
    });
    this.notifier.next(this.currentLanguage);
  }

  public saveStringsResource() {
    if (!this.multiLingualEnabled) { return; }
    this.db.object('strings/en').set(this.multiLanguage.en);
    this.db.object('strings/es').set(this.multiLanguage.es);
  }
}
