import { Component, OnInit, Input, AfterViewInit, HostListener } from '@angular/core';
import { LanguageService } from '../language.service';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css', '../scss/app.scss', '../scss/index.scss', '../scss/partials/_navigation.scss']
})

export class SidebarComponent implements OnInit {

  currentVideo = 0;
  eventsInterval;
  carrouselChangedByUser = false;
  currentWidth;
  currentEventWitdh;
  maxScrollLeft;
  currentScrollLeft;
  menuCol: string;
  currentLang
  menuOpen:boolean = false;
  constructor(public languageService: LanguageService,
    public router: Router) {

  }

  ngOnInit() {
    this.currentWidth = window.innerWidth;
    this.languageService.notifier.subscribe((lang) => {
      this.currentLang = lang;
    });
  }

  @HostListener('window:resize', ['$event'])
  windowOnResize(event) {
    this.currentWidth = window.innerWidth;
    this.setupCarrousel();
  }

  setupCarrousel() {
    if (this.currentWidth >= 900) {
      this.currentEventWitdh = this.currentWidth * 0.27;
      this.menuCol = 'events';
      this.maxScrollLeft = this.currentEventWitdh * 9;

    } else {
      this.currentEventWitdh = this.currentWidth * 0.69;
      this.menuCol = 'home';
      this.maxScrollLeft = this.currentEventWitdh * 10;
    }
    this.currentScrollLeft = this.currentVideo * this.currentEventWitdh;
  }

  navigate(path: string) {
    if (path != null) {
      if (path.indexOf('http') > -1) {
        window.open(path);
      } else {
        this.menuOpen = false;
        this.router.navigateByUrl('/' + path);
      }
    }
  }

}
