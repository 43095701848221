import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { AngularFireDatabase } from '@angular/fire/database';
import { FixedNavbarService } from '../fixed-navbar.service';
import { LanguageService } from '../language.service';
import { NavBar } from '../models';
import { NavbarService } from '../navbar.service';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css'],
})
export class NavbarComponent implements OnInit, AfterViewInit {
  @Input() editingMode: boolean;

  navContent: NavBar;
  loading = true;
  absolute = false;
  hide = true;
  white = false;

  constructor(
    private languageService: LanguageService,
    private db: AngularFireDatabase,
    private router: Router,
    private absoluteService: FixedNavbarService,
    private navbarService: NavbarService
  ) { }

  ngOnInit() {
    const navPromisse = this.db.object('components/navbar').valueChanges();
    navPromisse.subscribe((navRaw) => {
      this.loading = false;
      this.navContent = navRaw as NavBar;
      this.navContent.navs[0].menu = this.navContent.navs[0].menu.sort(
        (a, b) => a.order - b.order
      );
      for (let i = 0; i < this.navContent.navs.length; i++) {
        if (this.navContent.navs[i]['hidden']) {
          this.navContent.navs.splice(i, 1);
          i--;
        }
      }
    });

    this.router.events
      .pipe(filter((e) => e instanceof NavigationEnd))
      .subscribe((val) => {
        if (
          val['url'] &&
          val['url'] !== undefined &&
          val['url'] !== null &&
          (val['url'] === '/' ||
            val['url'] === '/pressblog' ||
            val['url'] === '/news' ||
            val['url'].includes('view-post') ||
            val['url'].includes('antonio') ||
            val['url'].includes('artweek') ||
            val['url'].includes('runways') ||
            val['url'].includes('bash'))
        ) {
          this.hide = true;
        } else {
          this.hide = false;
        }
      });
  }

  ngAfterViewInit() {
    if (this.absoluteService.navbarAbsouluteSubject !== undefined) {
      this.absoluteService.navbarAbsouluteSubject.subscribe((val) => {
        this.absolute = val;
      });
    }
    this.navbarService.white.subscribe((nw) => {
      this.white = nw;
    });
  }

  changeLang(lang: string) {
    this.languageService.changeLang(lang);
  }

  navigate(path: string) {
    if (path != null) {
      if (path.indexOf('http') > -1) {
        // window.location.href = path;
        window.open(path);
      } else {
        this.router.navigateByUrl('/' + path);
      }
    }
  }
}
