import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { Observable } from 'rxjs';
import { EditorService } from './dashboard/editor.service';

@Injectable()
export class ImagesService {
  public imagesResource: any;
  public loading = true;
  public currentImage: string;

  private editingEnabled = false;

  constructor(private db: AngularFireDatabase, private editor: EditorService) {
    const imagesPromisse = this.db.object('images').valueChanges();
    imagesPromisse.subscribe(imagesRaw => {
      this.imagesResource = imagesRaw;
      this.loading = false;
    });
    this.editor.editingModeEnabled.subscribe(editing => {
      this.editingEnabled = editing;
    });
  }

  public addImageToResource(link: string) {
    if (!this.editingEnabled) {
      return null;
    }
    const id = this.db.list('images').push(link).key;
    this.imagesResource[id] = link;
    return id;
  }

  loadHeader(header: string) {
    for (let css = 0; css < window.document.styleSheets.length; css++) {
      const sheet = window.document.styleSheets[css] as CSSStyleSheet;

      if (sheet.cssRules) {
        for (let i = 0; i < sheet.cssRules.length; i++) {
          if (sheet.cssRules[i].cssText.includes('.headerImage')) {
            sheet.deleteRule(i);
            i--;
          }
        }
      } else {
        for (let i = 0; i < sheet.rules.length; i++) {
          if ((sheet.rules[i] as any).includes('.headerImage')) {
            sheet.removeRule(i);
            i--;
          }
        }
      }
      if (sheet.cssRules) {
        // eslint-disable-next-line max-len
        sheet.insertRule(
          '.headerImage { background: url(\'' +
            header +
            '\') no-repeat; -webkit-background-size: cover; -moz-background-size: cover; -o-background-size: cover; background-size: cover; margin-bottom: 1%; }'
        );
        sheet.insertRule(
          '.headerImage + .white:before { background: url(\'' +
            header +
            '\') no-repeat; -webkit-background-size: cover; -moz-background-size: cover; -o-background-size: cover; background-size: cover; margin-bottom: 1%; }'
        );
      } else {
        // eslint-disable-next-line max-len
        sheet.addRule(
          '.headerImage { background: url(\'' +
            header +
            '\') no-repeat; -webkit-background-size: cover; -moz-background-size: cover; -o-background-size: cover; background-size: cover; margin-bottom: 1%; }'
        );
        sheet.addRule(
          '.headerImage + .white:before { background: url(\'' +
            header +
            '\') no-repeat; -webkit-background-size: cover; -moz-background-size: cover; -o-background-size: cover; background-size: cover; margin-bottom: 1%; }'
        );
      }
    }
  }
}
