import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AppRoutingModule } from './app-routing.module';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AuthguardService } from './auth/authguard.service';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { LanguageService } from './language.service';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { CarrouselserviceService } from './carrouselservice.service';
import { ImagesService } from './images.service';
import { FormsService } from './forms.service';
import { UploadService } from './uploads/shared/upload.service';
import { GalleryService } from './gallery/gallery.service';
import { SharedModule } from './shared.module';
import { EditorService } from './dashboard/editor.service';
import { HttpClientModule } from '@angular/common/http';
import { SecondStepComponent } from './designers/second-step/second-step.component';
import { NavbarService } from './navbar.service';
import { FormsModule } from '@angular/forms';
import { PasswordService } from './password.service';
import { FixedNavbarService } from './fixed-navbar.service';
import { PostsService } from './posts.service';
import { CarrouselService } from './carrouselpage/carrouse.service';
import { CarrouselSmalleService } from './carruselpage-small/carrousel.service';
import { CarrouselImageService } from './carruselpage-small/carruselimage.service';

@NgModule({
  declarations: [AppComponent, SecondStepComponent],
  imports: [
    AngularFireModule.initializeApp(environment.firebase),
    BrowserModule,
    ServiceWorkerModule.register('/ngsw-worker.js', { enabled: true }),
    AppRoutingModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    SharedModule,
    HttpClientModule,
    FormsModule,
  ],
  providers: [
    AuthguardService,
    LanguageService,
    ImagesService,
    AngularFireAuth,
    CarrouselserviceService,
    CarrouselImageService,
    FormsService,
    UploadService,
    GalleryService,
    EditorService,
    NavbarService,
    PasswordService,
    FixedNavbarService,
    PostsService,
    CarrouselService,
    CarrouselSmalleService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
