import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';
import { EditorService } from '../dashboard/editor.service';

@Directive({
    selector: '[appEditableInput]'
})
export class EditableInputDirective {
    constructor(private el: ElementRef, private renderer: Renderer2, private editor: EditorService) {
        this.editor.editingModeEnabled.subscribe((editing) => {
            if (editing) {
                renderer.addClass(el.nativeElement, 'clickable');
                el.nativeElement.style.width = '70%';
                el.nativeElement.style['margin-left'] = '0.05rem';
                const deleteDiv = this.renderer.createElement('div');
                this.renderer.listen(deleteDiv, 'click', (evt) => {
                    evt.stopPropagation();
                    this.editor.startInputEdit.next({
                        delete: true,
                        question: this.question,
                        route: this.editRoute,
                        left: this.left,
                        leftLength: this.leftLength,
                        rightLength: this.rightLength
                    });
                });
                renderer.appendChild(el.nativeElement, deleteDiv);
                renderer.addClass(deleteDiv, 'delete');

            } else {
                renderer.removeClass(el.nativeElement, 'clickable');
            }
        });
    }

    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input('appEditableInput') active: boolean;
    @Input() editRoute: string;
    @Input() question: any;
    @Input() left: boolean;
    @Input() leftLength: number;
    @Input() rightLength: number;

    previousColor: any;
    previousIndex: any;

    @HostListener('click', ['$event'])
    public onClick(event: any): void {
        if (this.active) {
            event.stopPropagation();
            this.editor.startInputEdit.next({
                question: this.question,
                route: this.editRoute,
                left: this.left,
                leftLength: this.leftLength,
                rightLength: this.rightLength
            });
        }
    }

    @HostListener('mouseenter', ['$event'])
    public onMouseEnter(evente) {
        if (this.active) {
            // this.el.nativeElement.style.backgroundColor = this.negative ? 'white' : 'black';
            // this.previousColor = this.el.nativeElement.style.color;
            // this.previousIndex = this.el.nativeElement.style['z-index'];
            // this.el.nativeElement.style.color = this.negative ? 'black' : 'white';
            // this.el.nativeElement.style['z-index'] = '100';
            this.el.nativeElement.style.cursor = 'ponter';
        }
    }

    @HostListener('mouseleave', ['$event'])
    public onMouseLeave(event) {
        if (this.active) {
            event.stopPropagation();
            // this.el.nativeElement.style.backgroundColor = null;
            // this.el.nativeElement.style.color = this.previousColor;
            // this.el.nativeElement.style['z-index'] = this.previousIndex;
            this.el.nativeElement.style.cursor = null;
        }
    }
}
