import { Component, OnInit, OnDestroy } from '@angular/core';
import { NavbarService } from '../../navbar.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AngularFireDatabase } from '@angular/fire/database';
import { UploadService } from '../../uploads/shared/upload.service';
import { Upload } from '../../uploads/shared/upload';
import { HttpClient } from '@angular/common/http';
import { timer } from 'rxjs';

@Component({
  selector: 'app-second-step',
  templateUrl: './second-step.component.html',
  styleUrls: ['./second-step.component.css']
})
export class SecondStepComponent implements OnInit, OnDestroy {

  loading = true;
  authorizing = false;
  authorized = false;
  verificationCode: string;
  registrationInfoID: string;

  registration: any;

  error: string;
  currentUpload: Upload;
  currentUploadKey: string;

  uploads: Array<Upload> = [];
  uploadMenus = { 'headshot': false, 'logo': false, 'lookbook': false };

  tos = false;

  registrationID = '';
  codeError: string;

  sending = false;

  constructor(private nonav: NavbarService, private route: ActivatedRoute, private db: AngularFireDatabase,
    private upSvc: UploadService, private http: HttpClient, private router: Router) {
    this.nonav.noNav.next(true);
  }

  ngOnInit() {
    this.route.paramMap.subscribe((paramMap) => {
      this.registrationInfoID = paramMap.get('id');
      this.loading = false;
    });
  }

  submitCode() {
    this.codeError = null;
    this.authorizing = true;
    // eslint-disable-next-line max-len
    this.http.post<RegisterID>('https://us-central1-miafw-3a.cloudfunctions.net/loginDesigner', { 'infoID': this.registrationInfoID, 'password': this.verificationCode }).subscribe(
      data => {
        this.registrationID = data.regID;
        this.db.object('registrations/designers/' + this.registrationID).valueChanges().subscribe((data2) => {
          this.registration = data2;
          this.registration.product = {};
          if (this.registration.name !== undefined && this.registration.name != null) {
            this.registration.name = (this.registration.name as string).split(' ')[0];
          }
          this.authorized = true;
          this.authorizing = false;
        });
      },
      err => {
        this.authorizing = false;
        this.codeError = 'The code entered is not valid.';
        timer(1500).subscribe(() => {
          this.codeError = null;
        });
      }
    );
  }

  ngOnDestroy() {
    this.nonav.noNav.next(false);
  }

  sendRegistration(formData) {
    if (!formData.valid) {
      this.error = 'Please check that you filled in all the fields of the registration.';
      timer(5000).subscribe(() => {
        this.error = null;
      });
    } else if (!this.tos) {
      this.error = 'Please agree to the terms and conditions.';
      timer(5000).subscribe(() => {
        this.error = null;
      });
    } else {
      this.registration.step2 = true;
      this.sending = true;
      this.db.object('registrations/designers/' + this.registrationID).set(this.registration).then(() => {
        this.sending = false;
        document.getElementById('confirmation').classList.add('active');
        timer(3000).subscribe(() => {
          this.router.navigateByUrl('/');
        });
      });
    }
  }

  checkUpload(key: string) {
    if (this.uploadMenus[key]) {
      this.uploadMenus[key] = false;
      return;
    }
    if (this.registration[key] != null) {
      this.uploadMenus[key] = true;
    } else {
      this.upload(key);
    }
  }

  download(key: string) {
    this.uploadMenus[key] = false;
    this.upSvc.downloadFile(this.registration[key], key.toUpperCase());
  }

  upload(key: string) {
    this.uploadMenus[key] = false;
    this.error = null;
    this.currentUploadKey = key;
    (<HTMLInputElement>document.getElementById(key)).click();
  }

  detectFiles(event) {
    if (event.target == null || event.target.files == null || event.target.files.length === 0) { return; }
    const file = event.target.files.item(0);
    if ((file.size / 1000) > 7000) {
      this.error = 'The file you selected is too big. Please select a file under 7MB.';
      timer(5000).subscribe(() => {
        this.error = null;
      });
    } else {
      this.currentUpload = new Upload(file);
      this.uploads[this.currentUploadKey] = (this.currentUpload);
      this.upSvc.pushUpload(this.currentUpload, (url) => {
        this.currentUpload = null;
        this.registration[this.currentUploadKey] = url;
        this.currentUploadKey = null;
      });
    }
  }
}


interface RegisterID {
  regID: string;
}
