import { Component, OnInit, Input, AfterViewInit, OnDestroy, HostListener } from '@angular/core';
import { LanguageService } from '../language.service';
import { AngularFireDatabase } from '@angular/fire/database';
import { ImagesService } from '../images.service';
import { BehaviorSubject, interval, timer } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { FixedNavbarService } from '../fixed-navbar.service';
import { NavbarService } from '../navbar.service';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { takeWhile } from 'rxjs/operators';
import * as _ from 'lodash';

@Component({
  selector: 'app-tvseries',
  templateUrl: './tvseries.component.html',
  styleUrls: ['./tvseries.component.css'],
})
export class TvSeriesComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() editingMode: boolean;
  @Input() editablePage;
  @Input() uiChanges: BehaviorSubject<boolean>;

  authorizing = false;
  authorized = true;
  tvseries: any;
  loading = true;
  imagesLoading = true;
  self: any;
  registrationID = '';
  codeError: string;
  registration: any;
  registrationInfoID: string;
  verificationEmail: string;
  verificationCode: string;
  onVideo = false;
  currentVideo: any;

  constructor(
    private route: ActivatedRoute,
    public languageService: LanguageService,
    public imagesService: ImagesService,
    private db: AngularFireDatabase,
    private router: Router,
    private fixedNav: FixedNavbarService,
    private navSvc: NavbarService,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    private location: Location
  ) {
    this.self = this;
  }

  @HostListener('window:popstate', ['$event'])
  private onPopState(event: any): any {
    if (this.onVideo) {
      this.onVideo = false;
    }

    event.preventDefault();
    event.stopPropagation();
  }

  ngOnInit() {
    if (this.editablePage != null) {
      this.tvseries = this.editablePage;
    } else {
      const promisse = this.db.object('pages/tvseries').valueChanges();
      promisse.subscribe(raw => {
        this.loading = false;
        this.tvseries = raw;
           (raw);
      });
    }
    this.fixedNav.navbarAbsouluteSubject.next(true);
  }

  ngOnDestroy() {
    this.fixedNav.navbarAbsouluteSubject.next(false);
    this.navSvc.white.next(false);
  }

  ngAfterViewInit() {
    interval(100)
      .pipe(takeWhile(() => this.imagesLoading))
      .subscribe(i => {
        if (this.imagesService.loading || this.loading) {
          return;
        }
        this.loadImages();
        this.imagesService.loadHeader(this.imagesService.imagesResource[this.tvseries.img_2.src]);
        this.imagesLoading = false;
      });
    if (this.editingMode) {
      this.uiChanges.subscribe(changes => {
        if (changes && this.editingMode) {
          this.loadImages();
          this.imagesService.loadHeader(this.imagesService.imagesResource[this.tvseries.img_2.src]);
        }
      });
    }
  }

  submitCode() {
    this.codeError = null;
    this.authorizing = true;
    this.loading = true;
    // eslint-disable-next-line max-len
    this.http
      .post<AuthResponse>('https://us-central1-miafw-3a.cloudfunctions.net/videoPasswordAuth', {
        email: this.verificationEmail,
        password: this.verificationCode,
      })
      .subscribe(
        data => {
          this.authorized = data.authorized;
          this.authorizing = false;
          this.loading = false;
          if (!this.authorized) {
            this.codeError = 'The password entered is not valid.';
            timer(1500).subscribe(() => {
              this.codeError = null;
            });
          }
        },
        err => {
          this.authorizing = false;
          this.loading = false;
          this.codeError = 'The password entered is not valid.';
          timer(1500).subscribe(() => {
            this.codeError = null;
          });
        }
      );
    // this.authorized = true;
  }

  /* eslint-disable max-len */
  watchVideo(position: number, bc = true) {
    this.onVideo = true;
    this.location.go('/tvseries');
    this.navSvc.white.next(true);
    const base = bc ? '//players.brightcove.net/2339105022001/default_default/index.html?videoId=' : '//player.vimeo.com/video/';
    let video = base;
    switch (position) {
      case 1:
        video = `${base}5507531783001`;
        break;
      case 2:
        video = `${base}5508944626001`;
        break;
      case 3:
        video = `${base}5858917767001`;
        break;
      case 4:
        video = `${base}5858929115001`;
        break;
      case 5:
        video = `${base}5858929116001`;
        break;
      case 6:
        video = `${base}5841616876001`;
        break;
      case 7:
        video = `${base}5543583555001`;
        break;
      case 8:
        video = `${base}5543593274001`;
        break;
      case 9:
        video = `${base}5858937606001`;
        break;
      case 10:
        video = `${base}5858935487001`;
        break;
      case 11:
        video = `${base}5858938505001`;
        break;
      case 12:
        video = `${base}5841626512001`;
        break;
      case 13:
        video = `${base}5858918914001`;
        break;
      case 14:
        video = `${base}5858914326001`;
        break;
      case 15:
        video = `${base}5749301887001`;
        break;
      case 16:
        video = `${base}5749300132001`;
        break;
      case 17:
        video = `${base}5614356684001`;
        break;
      case 18:
        video = `${base}5754347135001`;
        break;
    }
    this.currentVideo = this.sanitizer.bypassSecurityTrustResourceUrl(video);
  }

  closeVideo() {
    this.onVideo = false;
  }

  loadImages() {
    const sheet = window.document.styleSheets[0] as CSSStyleSheet;
    if (sheet.cssRules) {
      for (let i = 0; i < sheet.cssRules.length; i++) {
        if (sheet.cssRules[i].cssText.includes('.bgImg')) {
          sheet.deleteRule(i);
        }
      }
    } else {
      for (let i = 0; i < sheet.rules.length; i++) {
        if ((sheet.rules[i] as any).includes('.bgImg')) {
          sheet.removeRule(i);
        }
      }
    }
    if (sheet.cssRules) {
      // eslint-disable-next-line max-len
      sheet.insertRule(
        '.bgImg { background: url(\'' +
          this.imagesService.imagesResource[this.tvseries.img_1.src] +
          '\') no-repeat center center fixed; -webkit-background-size: cover; -moz-background-size: cover; -o-background-size: cover; background-size: cover; }'
      );
    } else {
      // eslint-disable-next-line max-len
      sheet.addRule(
        '.bgImg { background: url(\'' +
          this.imagesService.imagesResource[this.tvseries.img_1.src] +
          '\') no-repeat center center fixed; -webkit-background-size: cover; -moz-background-size: cover; -o-background-size: cover; background-size: cover; }'
      );
    }
  }
}

interface AuthResponse {
  authorized: boolean;
}
