import { Directive, HostListener, ElementRef, Input, Inject, Renderer2, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { interval } from 'rxjs';

@Directive({
  selector: '[appCountDown]',
})
export class CountDownDirective implements OnInit {
  // eslint-disable-next-line @angular-eslint/no-input-rename
  date: Date;

  isScrolled = false;

  constructor(private elRef: ElementRef, @Inject(DOCUMENT) private document: Document, private renderer: Renderer2) {}

  ngOnInit(): void {
    this.date = new Date(2019, 4, 29, 12, 0);
    this.initialize();
  }
  initialize() {
    setInterval(() => {
      const diff = Math.floor((this.date.getTime() - new Date().getTime()) / 1000);
      this.elRef.nativeElement.innerHTML = this.getTime(diff);
      //   ElementRef..getTime(diff);
    }, 1000);
  }

  getTime(t) {
    let days, hours, minutes, seconds;
    days = Math.floor(t / 86400);
    t -= days * 86400;
    hours = Math.floor(t / 3600) % 24;
    t -= hours * 3600;
    minutes = Math.floor(t / 60) % 60;
    t -= minutes * 60;
    seconds = t % 60;
    return `<div><div class="numCou" style="font-size: 20px;text-align: center;">${days}</div>
    <div class="txtCou" style="font-size: 10px; text-align: center;">Days</div></div>:
    <div><div class="numCou" style="font-size: 20px;text-align: center;">${hours}</div>
    <div class="txtCou" style="font-size: 10px; text-align: center;">Hours</div></div>:
    <div><div class="numCou" style="font-size: 20px;text-align: center;">${minutes}</div>
    <div class="txtCou" style="font-size: 10px; text-align: center;">Minutes</div></div>:
    <div><div class="numCou" style="font-size: 20px;text-align: center;">${seconds}</div>
    <div class="txtCou" style="font-size: 10px;text-align: center;">Seconds</div></div>`;
  }
}
