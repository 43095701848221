import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { User } from '../auth/auth.module';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';

@Injectable()
export class EditorService {

  public editingModeEnabled: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public editingPage: BehaviorSubject<any> = new BehaviorSubject(null);

  // Edit behaviours
  public startButtonEdit: BehaviorSubject<any> = new BehaviorSubject(null);
  public startTextEdit: BehaviorSubject<any> = new BehaviorSubject(null);
  public startImageEdit: BehaviorSubject<any> = new BehaviorSubject(null);
  public startVideoEdit: BehaviorSubject<any> = new BehaviorSubject(null);
  public startInputEdit: BehaviorSubject<any> = new BehaviorSubject(null);
  public startEventEdit: BehaviorSubject<any> = new BehaviorSubject(null);
  public startDayEdit: BehaviorSubject<any> = new BehaviorSubject(null);
  public startPlaylistEdit: BehaviorSubject<any> = new BehaviorSubject(null);
  public startNewPost: BehaviorSubject<any> = new BehaviorSubject(null);

  // Registrations
  public registrationView: BehaviorSubject<any> = new BehaviorSubject(null);


  public userLogged: User;
  loading = true;


  constructor(private af: AngularFireAuth, private db: AngularFireDatabase, private router: Router) {
    if (this.userLogged == null) {
      this.af.authState.pipe(take(1)).subscribe(auth => {
        if (auth) {
          db.object('users/' + auth.uid).valueChanges().subscribe((loggedUser) => {
            if ((loggedUser as User).admin) {
              this.userLogged = loggedUser as User;
              this.loading = false;
            } else {
              // Not allowed
              this.loading = false;
              if (this.editingModeEnabled.getValue()) {
                this.router.navigateByUrl('/auth');
              }
            }
          });
        } else {
          // Not logged
          this.loading = false;
          if (this.editingModeEnabled.getValue()) {
            this.router.navigateByUrl('/auth');
          }
        }
      });
    } else {
      this.loading = false;
    }
  }

  savePage() {
    this.editingPage.getValue().save();
  }

  setNestedData(data: any, dotted: any, value: any) {
    if (typeof dotted === 'string') {
      return this.setNestedData(data, dotted.split('.'), value);
    } else if (dotted.length === 1) {
      if (value !== undefined) {
        if (data === undefined) {
          if (Number(dotted[0]) != null) {
            data = [];
          } else {
            data = {};
          }
        }
        if (dotted[0] === 'nestedArrayEnd') {
          data[data.length] = value;
        } else {
          data[dotted[0]] = value;
        }
        return data;
      } else {
        delete data[dotted[0]];
        return data;
      }
    } else if (dotted.length === 0) {
      return data;
    } else {
      data[dotted[0]] = this.setNestedData(data[dotted[0]], dotted.slice(1), value);
      return data;
    }
  }
}
