import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CarrouselPage, Registration } from '../models';

import { AngularFireDatabase } from '@angular/fire/database';
import { BehaviorSubject } from 'rxjs';
import { FormsService } from '../forms.service';
import { LanguageService } from '../language.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css'],
})
export class ContactComponent implements OnInit, AfterViewInit {
  @Input() editingMode: boolean;
  @Input() editablePage;
  @Input() uiChanges: BehaviorSubject<boolean>;

  loading = true;

  constructor(private languageService: LanguageService, private db: AngularFireDatabase, private formsService: FormsService) { }

  ngOnInit() {
    if (this.editablePage != null) {
      this.formsService.currentForm.next(this.editablePage as Registration);
      this.loading = false;
    } else {
      const promisse = this.db.object('pages/contact').valueChanges();
      promisse.subscribe(raw => {
        this.loading = false;
        this.formsService.currentForm.next(raw as Registration);
      });
    }
  }

  ngAfterViewInit() {
    this.uiChanges.subscribe(changes => {
      if (changes && this.editingMode) {
        this.formsService.currentForm.next(this.editablePage as Registration);
      }
    });
  }
}
