import { Component, Input, OnInit } from '@angular/core';

import { AngularFireDatabase } from '@angular/fire/database';
import { BehaviorSubject } from 'rxjs';
import { CarrouselPage } from '../models';
import { CarrouselserviceService } from '../carrouselservice.service';
import { LanguageService } from '../language.service';

@Component({
  selector: 'app-abanderas',
  templateUrl: './abanderas.component.html',
  styleUrls: ['./abanderas.component.css'],
})
export class AbanderasComponent implements OnInit {
  @Input() editingMode: boolean;
  @Input() editablePage;
  @Input() uiChanges: BehaviorSubject<boolean>;

  loading = true;

  constructor(
    private languageService: LanguageService,
    private db: AngularFireDatabase,
    private carrouselService: CarrouselserviceService
  ) { }

  ngOnInit() {
    if (this.editablePage != null) {
      this.carrouselService.currentCarrousel.next(this.editablePage as CarrouselPage);
    } else {
      const promisse = this.db.object('pages/antonio_banderas').valueChanges();
      promisse.subscribe(raw => {
        this.loading = false;
        this.carrouselService.currentCarrousel.next(raw as CarrouselPage);
      });
    }
  }
}
