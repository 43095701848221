import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';
import { EditorService } from '../dashboard/editor.service';
import { timer } from 'rxjs';


@Directive({
    selector: '[appEditableVideoGallery]'
})
export class EditableVideoGalleryDirective {
    constructor(private el: ElementRef, private renderer: Renderer2, private editor: EditorService) {
        this.editor.editingModeEnabled.subscribe((editing) => {
            if (editing) {
                // renderer.addClass(el.nativeElement, 'clickable');
                this.addGallery = this.renderer.createElement('div');
                const textDiv = this.renderer.createElement('div');
                textDiv.insertAdjacentHTML('beforeend', 'NEW SUBGALLERY');
                renderer.addClass(textDiv, 'galTitle');
                renderer.appendChild(this.addGallery, textDiv);
                renderer.addClass(this.addGallery, 'addGallery');

                this.renderer.listen(this.addGallery, 'click', (evt) => {
                    evt.stopPropagation();
                    const gal: any = {};
                    gal.galleries = [];
                    gal.videos = [];
                    gal.img_1 = {src: 'gallery1'};
                    gal.subtitle = {content: 'EMPTY', style: 'title2'};
                    gal.title = {content: 'EMPTY', style: 'title1'};
                    this.component.navigateGallery(gal, this.editRoute);
                });
                timer(1000).subscribe(() => {
                    if (this.el.nativeElement.style.opacity !== '0') {
                        this.renderer.appendChild(this.el.nativeElement, this.addGallery);
                    }
                    for (let i = 0; i < this.galleries; i++) {
                        const deleteDiv = this.renderer.createElement('div');
                        deleteDiv.insertAdjacentHTML('beforeend', 'DELETE');
                        renderer.addClass(deleteDiv, 'delete');
                        this.renderer.listen(deleteDiv, 'click', (evt) => {
                            evt.stopPropagation();
                            // Delete sub Galerry
                            this.editor.editingPage.getValue().deleteGallery(i);
                        });
                        this.renderer.appendChild(document.getElementById('subGal' + i), deleteDiv);
                    }
                });
            } else {
                renderer.removeClass(el.nativeElement, 'clickable');
            }
        });
    }

    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input('appEditableVideoGallery') active: boolean;
    @Input() editRoute: string;
    @Input() component: any;
    @Input() galleries: number;


    addGallery: any;
}
