import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';

import {AngularFireAuth} from '@angular/fire/auth';

import { Observable } from 'rxjs';
import { take, map, tap } from 'rxjs/operators';

@Injectable()
export class AuthguardService implements CanActivate {
    constructor(private afAuth: AngularFireAuth, private router: Router) {}

  canActivate(): Observable<boolean> {
    return this.afAuth.authState.pipe(
      take(1),
      map(authState => !!authState),
      tap(authenticated => {
        if (!authenticated) {
          this.router.navigate(['/auth']);
          return false;
        }
      }));
  }
}
