import { FormControl, FormGroup, Validators } from '@angular/forms';

import { AngularFireDatabase } from '@angular/fire/database';
import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { Registration } from './models';

@Injectable()
export class FormsService {
  public currentForm: BehaviorSubject<Registration> = new BehaviorSubject(null);

  constructor(private db: AngularFireDatabase) { }

  saveRegistration(registration: any, path: string) {
    if (path !== 'contact') {
      this.db.list(`registrations/${path}`).push(registration);
    } else {
      this.db.list(path).push(registration);
    }
  }
}
