import { AfterViewInit, Component, ElementRef, Input, OnInit, QueryList, ViewChildren } from '@angular/core';

import { CarrouselImageService } from './carruselimage.service';
import { CarrouselPage } from '../models';
import { CarrouselSmalleService } from './carrousel.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ImagesService } from '../images.service';
import { LanguageService } from '../language.service';
import { Router } from '@angular/router';
import { interval } from 'rxjs';
import { takeWhile } from 'rxjs/operators';

@Component({
  selector: 'app-carrousel-smallpage',
  templateUrl: './carruselpage-small.component.html',
  styleUrls: ['./carruselpage-small.component.css'],
})
export class CarrouselpageSmallComponent implements OnInit, AfterViewInit {
  @Input() editingMode: boolean;

  carrousel: CarrouselPage;
  loading = true;
  subscribed = false;
  leftArrowDisabled = false;
  rightArrowDisabled = false;
  show = true;
  currentVideo: any;

  constructor(
    public languageService: LanguageService,
    public imagesService: ImagesService,
    private carrouselService: CarrouselImageService,
    private router: Router,
    private carrouselSVC: CarrouselSmalleService,
    private sanitazer: DomSanitizer
  ) {
    if (!this.subscribed) {
      this.subscribed = true;
      this.carrouselService.currentCarrousel.subscribe(carr => {
        this.carrousel = carr;
        this.loading = true;
        interval(500)
          .pipe(takeWhile(() => this.loading))
          .subscribe(i => {
            this.reloadComponent();
          });
      });
    }
  }

  @ViewChildren('carr') carrouselQuery: QueryList<ElementRef>;

  ngOnInit() { }

  ngAfterViewInit() {
    this.carrouselQuery.changes.subscribe(item => {
      if (this.carrouselQuery.length) {
        this.carrouselSVC.carrousel.next(this.carrouselQuery.first.nativeElement);
      }
    });
  }

  loadSanitize(src: string) {
    if (src.startsWith('https://firebasestorage')) {
      return this.sanitazer.bypassSecurityTrustResourceUrl(src);
    } else {
      return this.sanitazer.bypassSecurityTrustResourceUrl(this.imagesService.imagesResource[src]);
    }
  }

  reloadComponent() {
    if (this.imagesService.imagesResource == null) {
      return;
    }
    if (this.carrousel.images !== undefined) {
      this.carrousel.images = this.carrousel.images.slice(0, Math.min(10, this.carrousel.images.length));
    }
    this.loading = false;
  }

  navigate(path: string) {
    if (path != null) {
      if (path.indexOf('http') > -1) {
        window.location.href = path;
      } else {
        this.router.navigateByUrl('/' + path);
      }
    }
  }

  scrollLeft() {
    this.carrouselSVC.scrollLeft();
  }
  scrollRight() {
    this.carrouselSVC.scrollRight();
  }
}
