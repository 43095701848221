import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { take, takeWhile } from 'rxjs/operators';

import { AngularFireDatabase } from '@angular/fire/database';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ImagesService } from '../images.service';
import { LanguageService } from '../language.service';
import { PostsService } from '../posts.service';
import { Router } from '@angular/router';
import { interval } from 'rxjs';

@Component({
  selector: 'app-press',
  templateUrl: './press-new.component.html',
  styleUrls: ['./press-new.component.scss', '../scss/app.scss', '../scss/index.scss'],
})
export class PressComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() editingMode: boolean;
  @Input() editablePage;
  @Input() uiChanges: BehaviorSubject<boolean>;
  @Input() pause: boolean;

  @Output() editPost = new EventEmitter<any>();

  blog: any;
  loading = true;
  loadingCategories = 1;
  imagesLoading = true;

  loadCount = [15, 6, 3, 1, 4];
  latest: Array<any>;
  categorized: Array<Array<any>> = [];
  term: string;
  currentNumberPages: number = 5;
  currentLang;
  pdfSrc = 'https://firebasestorage.googleapis.com/v0/b/miafw-3a.appspot.com/o/sample.pdf?alt=media&token=e4fe6669-8406-4b66-9262-a4b4c75a6d2c'

  constructor(
    public languageService: LanguageService,
    public imagesService: ImagesService,
    private db: AngularFireDatabase,
    private postsService: PostsService,
    private router: Router,
    private http: HttpClient
  ) {
    languageService.notifier.subscribe(lang => {
      if (this.blog != null && lang != null && !this.pause) {
        this.loadPosts();
      }
    });
  }

  ngOnInit() {
    this.languageService.notifier.subscribe((lang) => {
      this.currentLang = lang;
    });
    if (this.editablePage != null) {
      this.blog = this.editablePage;
    } else {
      const promisse = this.db
        .object('pages/press')
        .valueChanges()
        .pipe(take(1));
      promisse.subscribe(raw => {
        this.blog = raw;
        for (let i = 0; i < this.blog.sections.length; i++) {
          if (this.blog.sections[i].category != null && this.blog.sections[i].category[this.languageService.currentLanguage] != null) {
            // eslint-disable-next-line max-len
            this.postsService.getPostsCategory(
              this.languageService.currentLanguage,
              this.blog.sections[i].category[this.languageService.currentLanguage],
              (posts, category) => {

                this.categorized[category] = posts;
                this.loadingCategories -= 1;
              },
              this.loadCount[i]
            );
          }
        }
      });
      this.postsService.getAllPosts(
        this.languageService.currentLanguage,
        posts => {
          this.loading = false;
          this.latest = posts;
        },
        this.loadCount[0]
      );
    }
  }

  downloadPdf(style) {
    return this.http.get(style.pdf, { responseType: 'blob' as 'json' }).subscribe((res: any) => {
      var file = new Blob([res], { type: 'application/pdf' });

      var FileSaver = require('file-saver');
      FileSaver.saveAs(file, style.title.rendered + ".pdf");

    });
  }

  ngAfterViewInit() {
    interval(1000)
      .pipe(takeWhile(() => this.imagesLoading))
      .subscribe(i => {
        if (this.imagesService.loading || this.loading) {
          return;
        }
        this.loadImages();
        this.imagesLoading = false;
      });
    if (this.editingMode) {
      this.uiChanges.subscribe(changes => {
        if (changes && this.editingMode) {
          this.loadImages();
        }
      });
    }
    // this.postsService.loadLegacyMedia();
    // this.postsService.loadLegacyPosts();
    // this.postsService.orginicePosts();
    // this.postsService.changeLegacyMediaURL();
  }

  ngOnDestroy() {
    const sheet = window.document.styleSheets[0] as CSSStyleSheet;
    if (sheet.cssRules) {
      for (let i = 0; i < sheet.cssRules.length; i++) {
        if (sheet.cssRules[i].cssText.includes('.headerImage')) {
          sheet.deleteRule(i);
        }
      }
    } else {
      for (let i = 0; i < sheet.rules.length; i++) {
        if ((sheet.rules[i] as any).includes('.headerImage')) {
          sheet.removeRule(i);
        }
      }
    }
  }

  moreArticles() {
    this.currentNumberPages += 5;
  }

  loadPosts() {
    this.postsService.getAllPosts(
      this.languageService.currentLanguage,
      posts => {
        this.loading = false;
        this.latest = posts;
      },
      this.loadCount[0]
    );

    for (let i = 1; i < this.blog.sections.length; i++) {
      if (this.blog.sections[i].category != null && this.blog.sections[i].category[this.languageService.currentLanguage] != null) {
        // eslint-disable-next-line max-len
        this.postsService.getPostsCategory(
          this.languageService.currentLanguage,
          this.blog.sections[i].category[this.languageService.currentLanguage],
          (posts, category) => {
            this.categorized[category] = posts;
            this.loadingCategories -= 1;

          },
          this.loadCount[i]
        );
      }
    }
  }

  loadImages() {
    const sheet = window.document.styleSheets[0] as CSSStyleSheet;
    if (sheet.cssRules) {
      for (let i = 0; i < sheet.cssRules.length; i++) {
        if (sheet.cssRules[i].cssText.includes('.headerImage')) {
          sheet.deleteRule(i);
        }
      }
    } else {
      for (let i = 0; i < sheet.rules.length; i++) {
        if ((sheet.rules[i] as any).includes('.headerImage')) {
          sheet.removeRule(i);
        }
      }
    }
    if (sheet.cssRules) {
      // eslint-disable-next-line max-len
      sheet.insertRule(
        '.headerImage { background: url(\'' +
        this.imagesService.imagesResource[this.blog.img_1.src] +
        '\') no-repeat center center fixed; -webkit-background-size: cover; ' +
        '-moz-background-size: cover; -o-background-size: cover; background-size: cover; margin-bottom: 1%;}'
      );
    } else {
      // eslint-disable-next-line max-len
      sheet.addRule(
        '.headerImage { background: url(\'' +
        this.imagesService.imagesResource[this.blog.img_1.src] +
        '\') no-repeat center center fixed; -webkit-background-size: cover;' +
        ' -moz-background-size: cover; -o-background-size: cover; background-size: cover; margin-bottom: 1%;}'
      );
    }
  }

  encodeURIComponent(s) {
    return encodeURIComponent(s);
  }

  openPost(post: any, event?: any) {
    if (event !== undefined) {
      event.preventDefault();
    }
    if (this.editingMode) {
      this.startEditPost(post, null);
    } else {
      const sl = encodeURIComponent(post.slug)
        .split('?')
        .join('%3F');
      // this.router.navigateByUrl('/' + sl);
      this.router.navigateByUrl('view-post/' + sl);
    }
  }

  loadBGImage(post: any, category: string, index: number) {
    if (post.thumbnail == null) {
      return '';
    }

    const sheet = window.document.styleSheets[0] as CSSStyleSheet;
    if (sheet.cssRules) {
      for (let i = 0; i < sheet.cssRules.length; i++) {
        if (sheet.cssRules[i].cssText.includes('.' + category + index)) {
          return category + index;
        }
      }
    } else {
      for (let i = 0; i < sheet.rules.length; i++) {
        if ((sheet.rules[i] as any).includes('.' + category + index)) {
          return category + index;
        }
      }
    }
    if (sheet.cssRules) {
      // eslint-disable-next-line max-len
      sheet.insertRule('.' + category + index + '{ background-image: url(\'' + post.thumbnail + '\'); }');
    } else {
      // eslint-disable-next-line max-len
      sheet.addRule('.' + category + index + '{ background-image: url(\'' + post.thumbnail + '\'); }');
    }

    return category + index;
  }

  viewMore(category: any) {
    this.router.navigateByUrl('/posts/' + category);
  }

  startEditPost(post: any, event: any) {
    this.editPost.emit(post);
    if (event != null) {
      event.preventDefault();
    }
  }

  navigate(path: string) {
    if (path != null) {
      if (path.indexOf('http') > -1) {
        window.open(path);
      } else {
        this.router.navigateByUrl('/' + path);
      }
    }
  }
}
