<app-loading *ngIf="fakeLoad || loading || imagesLoading"></app-loading>
<div *ngIf="!loading && !imagesLoading && (firstfaces.votingEnabled || editingMode)"
  [class.editingPadding]="editingMode" id="vote">
  <div class="section header headerImage" [appEditableImage]="editingMode" [editRoute]="'img_2'"
    [image]="firstfaces.img_2"> </div>


  <div class="txtCenter p20">
    <!-- <div class="p20 underline" [ngClass]="firstfaces.box3.title.style"></div>
    <div class="txt1" [innerHTML]="languageService.stringsResource[firstfaces.box3.body.content]"
      [appEditableText]="editingMode" [editRoute]="'box3.body'" [element]="firstfaces.box3.body"></div> -->
    <!-- <div class="flex-search" *ngIf="(votingActive || editingMode) && firstfaces.models">
      <input type="text" [(ngModel)]="term"><img src="/assets/firstfaces/search.svg" class="search" alt=""></div> -->
  </div>
  <!-- <div class="txtCenter">
    <div class="p20 underline" [ngClass]="firstfaces.box3.title.style"
      [innerHTML]="languageService.stringsResource[firstfaces.box3.title.content]" [appEditableText]="editingMode"
      [editRoute]="'box3.title'" [element]="firstfaces.box3.title"></div>
    <div class="txt1" [innerHTML]="languageService.stringsResource[firstfaces.box3.body.content]"
      [appEditableText]="editingMode" [editRoute]="'box3.body'" [element]="firstfaces.box3.body"></div>
    <div class="flex-search" *ngIf="(votingActive || editingMode) && firstfaces.models">
      <input type="text" [(ngModel)]="term"><img src="/assets/firstfaces/search.svg" class="search" alt=""></div>
  </div>
  <button class="submit" *ngIf="votingActive && firstfaces.models && !editingMode" (click)="vote()">SUBMIT VOTE</button> -->
  <div class="ff-space">
    <app-script-hack async src="https://www.hitsbook.com/static/js/iframeResizer.min.js" type="text/javascript">
    </app-script-hack>
    <app-script-hack async src="https://www.hitsbook.com/static/js/scrollTopOnIframeClick.js" type="text/javascript">
    </app-script-hack>
    <iframe id="hitsbookIframe" style="width: 1px; min-width: 95%; min-height: 800px;"
      src="https://firstfaces19.hitsbook.com/" frameborder="0" width="100%" allowfullscreen="allowfullscreen"></iframe>
    <app-script-hack async src="https://www.hitsbook.com/static/js/iframeResizer.init.js" type="text/javascript">
    </app-script-hack>

    <!-- <div class="ff" *ngFor="let model of firstfaces.models |filter:term">
      <div class="image-container"> <img alt="" [appEditableImage]="editingMode"
          [editRoute]="'models.'+model.index+'.img'" [image]="model.img" [defaultImage]="''"
          [lazyLoad]="imagesService.imagesResource[model.img.src]"></div>
      <div class="name-space">{{model.name.toUpperCase()}}</div>
      <div class="instagram" (click)="navigate(model.instagram)">
        <div class="instagram-circle"></div>
      </div>
      <div class="delete" (click)="deleteModel(model.index)" *ngIf="editingMode">DELETE</div>
      <div class="vote" [class.disabled]="!votingActive" (click)="votingActive && selectedModel = model.index;"
        [class.selected]="selectedModel == model.index"> </div>
    </div> -->
  </div>
  <br><br><br>
  <!-- <div class="txtCenter">
    <div style="font-style: italic; font-size: 1.2em;" class="disclaimer" [appEditableText]="editingMode"
      [editRoute]="'disclaimer'" [element]="firstfaces.disclaimer" [ngClass]="firstfaces.disclaimer.style"
      [innerHTML]="languageService.stringsResource[firstfaces.disclaimer.content]"></div>
  </div>  -->
</div>
<app-alert *ngIf="voteAlert" [title]="'Thank you for voting!'" [body]="'Limit one vote per person'" [acceptTitle]="'OK'"
  (accepted)="voteAlert = false"></app-alert>
