<link rel="stylesheet" href="https://use.typekit.net/kxr5yzh.css" />
<app-loading *ngIf="loading"></app-loading>
<div *ngIf="!loading" class="background">
  <app-sidebar></app-sidebar>
  <!-- <div class="section header headerImage cc"></div> -->
  <div class="section  headerImage -pressHeader">
    <div class="title1" [innerHTML]="postData.title.rendered"></div>
    <div class="subtitle">
      <span>{{ postData.date | date }}</span>
    </div>
    <!-- <div class="divider"></div>
    <div class="author-data">
      <div class="author-img">
        <img id="logo" src="/assets/MIAFW-Logo-black-.png" alt="" />
      </div>
      <div class="info">
        <div
          class="txt2 author-name fnt f-500"
          [innerHTML]="postData.author ? postData.author.rendered : ''"
        ></div>

        <div class="txt0 author-date fnt f-300 -grey">
          {{ postData.date | date: "dd/MM/yy" }}
        </div>
      </div>
    </div> -->
  </div>
  <div class="social">
    <div class="icons">
      <a class="link-icon" href="https://www.instagram.com/miamifashionweek/" target="_blank">
        <img src="./../../../assets/instagram-white.svg">
      </a>
      <a class="link-icon" href="https://www.facebook.com/MiamiFashionWeek/" target="_blank">
        <img src="./../../../assets/facebook-white.svg">
      </a>

    </div>
    <div class="download">
      <span (click)="downloadPdf(postData)">
        Download <img src="./../../assets/download.svg">
      </span>
    </div>
  </div>
  <div class="post" *ngIf="postData">
    <pdf-viewer [src]="postData.pdf" [render-text]="true" style="display: block;"></pdf-viewer>
    <!-- <div class="col-container">
      <div class="col">
        <div class="r-l">
          <div class="social">
            <a href="#" class="socialLinks">
              <div class="icon" id="facebook"></div>
            </a>
            <a href="#" class="socialLinks">
              <div class="icon" id="twitter"></div>
            </a>
            <a href="#" class="socialLinks">
              <div class="icon" id="pinterest"></div>
            </a>
            <a (click)="goToLink(postData.link)" class="socialLinks">
              <div class="icon" id="link"></div>
            </a> 
          </div>
        </div>
      </div>
      <div class="col">
        <ng-container *ngIf="!press">
          <div
            class="txt2 fnt f-300"
            [innerHTML]="postData.content.rendered"
          ></div>
          <div class="gallery" *ngIf="postData.gallery != null">
            <div
              class="item "
              *ngFor="let image of postData.gallery; let i = index"
              (click)="selectedImage = image; selectedIndex = i"
            >
              <img [src]="image" alt="" />
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="press">
          <div class="txt2 fnt f-300" [innerHTML]="postData.content3A"></div>
          <div class="gallery" *ngIf="postData.gallery != null">
            <div
              class="item press-it"
              *ngFor="let image of postData.gallery; let i = index"
              (click)="selectedImage = image; selectedIndex = i"
            >
              <img [src]="image" alt="" />
            </div>
          </div>
          <div
            class="txt1 fnt f-200 -grey"
            [innerHTML]="postData.image1Title"
          ></div>
          <div class="footer-div"></div>
          <div class="txt2 fnt f-300" [innerHTML]="postData.content3A2"></div>

          <div class="gallery" *ngIf="postData.gallery2 != null">
            <div
              class="item press-it"
              *ngFor="let image of postData.gallery2; let i = index"
              (click)="selectedImage = image; selectedIndex = i"
            >
              <img [src]="image" alt="" />
            </div>
          </div>

          <div
            class="txt1 fnt f-200 -grey"
            [innerHTML]="postData.image2Title"
          ></div>
          <div class="footer-div"></div>
          <div class="txt2 fnt f-300" [innerHTML]="postData.content3A3"></div>
        </ng-container>
      </div>
    </div>
    <div class=" related"></div>
    <div class="title-fnt ">Related</div>
    <div class="carrousel-post">
      <div
        class="nv-post"
        *ngFor="
          let style of categorized[
            blog.sections[0].category[languageService.currentLanguage]
          ];
          let i = index
        "
        (click)="openPost(style, $event)"
      >
        <div class="image" [ngClass]="loadBGImage(style, 'latest', i)"></div>
        <div class="txt2 fnt f-500 description">
          {{ style.title.rendered }}
        </div>
      </div>
    </div> -->
  </div>

  <div class="bigImage" [class.active]="selectedImage != null">
    <button class="close clickable" (click)="selectedImage = null">X</button>
    <div class="arrow left"></div>
    <div class="leftClick clickable" (click)="previousImage()"></div>
    <img *ngIf="selectedImage != null" [src]="selectedImage" alt="" />
    <div class="arrow right"></div>
    <div class="rightClick clickable" (click)="nextImage()"></div>
  </div>
</div>