import { Component, OnInit, Input, AfterViewInit, OnDestroy } from '@angular/core';
import { LanguageService } from '../language.service';
import { AngularFireDatabase } from '@angular/fire/database';
import { ImagesService } from '../images.service';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { FixedNavbarService } from '../fixed-navbar.service';
import { NavbarService } from '../navbar.service';
import { interval } from 'rxjs';
import { takeWhile } from 'rxjs/operators';

@Component({
  selector: 'app-first-faces',
  templateUrl: './first-faces.component.html',
  styleUrls: ['./first-faces.component.css'],
})
export class FirstFacesComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() editingMode: boolean;
  @Input() editablePage;
  @Input() uiChanges: BehaviorSubject<boolean>;

  firstfaces: any;
  loading = true;
  imagesLoading = true;
  seventeenVisible = false;

  constructor(
    public languageService: LanguageService,
    public imagesService: ImagesService,
    private db: AngularFireDatabase,
    private router: Router,
    private fixedNav: FixedNavbarService,
    private navSvc: NavbarService
  ) {}

  ngOnInit() {
    if (this.editablePage != null) {
      this.firstfaces = this.editablePage;
    } else {
      const promisse = this.db.object('pages/firstfaces').valueChanges();
      promisse.subscribe(raw => {
        this.loading = false;
        this.firstfaces = raw;
      });
    }
    this.fixedNav.navbarAbsouluteSubject.next(true);
    this.navSvc.white.next(false);
  }

  ngOnDestroy() {
    this.fixedNav.navbarAbsouluteSubject.next(false);
    this.navSvc.white.next(false);
  }

  ngAfterViewInit() {
    interval(100)
      .pipe(takeWhile(() => this.imagesLoading))
      .subscribe(i => {
        if (this.imagesService.loading || this.loading) {
          return;
        }
        this.loadImages();
        this.imagesService.loadHeader(this.imagesService.imagesResource[this.firstfaces.img_2.src]);
        this.imagesLoading = false;
      });
    if (this.editingMode) {
      this.uiChanges.subscribe(changes => {
        if (changes && this.editingMode) {
          this.loadImages();
          this.imagesService.loadHeader(this.imagesService.imagesResource[this.firstfaces.img_2.src]);
        }
      });
    }
  }

  navigate(url: string) {
    this.router.navigateByUrl(url);
  }

  loadImages() {
    const sheet = window.document.styleSheets[0] as CSSStyleSheet;
    if (sheet.cssRules) {
      for (let i = 0; i < sheet.cssRules.length; i++) {
        if (sheet.cssRules[i].cssText.includes('.bgImg')) {
          sheet.deleteRule(i);
        }
      }
    } else {
      for (let i = 0; i < sheet.rules.length; i++) {
        if ((sheet.rules[i] as any).includes('.bgImg')) {
          sheet.removeRule(i);
        }
      }
    }
    if (sheet.cssRules) {
      // eslint-disable-next-line max-len
      sheet.insertRule(
        '.bgImg { background: url(\'' +
          this.imagesService.imagesResource[this.firstfaces.img_1.src] +
          '\') no-repeat center center fixed; -webkit-background-size: ' +
          'cover; -moz-background-size: cover; -o-background-size: cover; background-size: cover; }'
      );

      if (this.firstfaces.models2018) {
        this.firstfaces.models2018.forEach((element, index) => {
          sheet.insertRule(
            '.models2018-' +
              index +
              '{ ' +
              'min-height: 78%;' +
              'top: 50%;' +
              'left: 50%;' +
              '-webkit-transform: translate(-50%, -50%);' +
              'transform: translate(-50%, -50%);' +
              'background: url(\'' +
              this.imagesService.imagesResource[element.img.src] +
              '\');}'
          );
        });
      }

      if (this.firstfaces.models2019) {
        this.firstfaces.models2019.forEach((element, index) => {
          sheet.insertRule(
            '.models2019-' +
              index +
              '{ ' +
              'min-height: 78%;' +
              'top: 50%;' +
              'left: 50%;' +
              '-webkit-transform: translate(-50%, -50%);' +
              'transform: translate(-50%, -50%);' +
              'background: url(\'' +
              this.imagesService.imagesResource[element.img.src] +
              '\');}'
          );
        });
      }
    } else {
      // eslint-disable-next-line max-len
      sheet.addRule(
        '.bgImg { background: url(\'' +
          this.imagesService.imagesResource[this.firstfaces.img_1.src] +
          '\') no-repeat center center fixed; -webkit-background-size: ' +
          ' cover; -moz-background-size: cover; -o-background-size: cover; background-size: cover; }'
      );

      if (this.firstfaces.models2018) {
        this.firstfaces.models2018.forEach((element, index) => {
          sheet.addRule(
            '.models2018-' +
              index +
              '{ ' +
              'min-height: 78%;' +
              'top: 50%;' +
              'left: 50%;' +
              '-webkit-transform: translate(-50%, -50%);' +
              'transform: translate(-50%, -50%);' +
              'background: url(\'' +
              this.imagesService.imagesResource[element.img.src] +
              '\');}'
          );
        });
      }

      if (this.firstfaces.models2019) {
        this.firstfaces.models2019.forEach((element, index) => {
          sheet.addRule(
            '.models2019-' +
              index +
              '{ ' +
              'min-height: 78%;' +
              'top: 50%;' +
              'left: 50%;' +
              '-webkit-transform: translate(-50%, -50%);' +
              'transform: translate(-50%, -50%);' +
              'background: url(\'' +
              this.imagesService.imagesResource[element.img.src] +
              '\');}'
          );
        });
      }
    }
  }

  getImg(id) {
    return this.imagesService.imagesResource[id];
  }
}
