import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

// if ((navigator.userAgent.indexOf('Trident / 7.0') > 0) ||
//   (/MSIE 10/i.test(navigator.userAgent)) ||
//   (/MSIE 9/i.test(navigator.userAgent) ||
//     /rv:11.0/i.test(navigator.userAgent))) {
//   window.location.replace('https://miamifashionweek.com/ie');
// } else {
  platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
// }
