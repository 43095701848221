<app-loading *ngIf="loading || imagesLoading"></app-loading>
<div *ngIf="!loading && !imagesLoading" [class.editingPadding]="editingMode">
  <div
    class="section header headerImage"
    [appEditableImage]="editingMode"
    [editRoute]="'img_1'"
    [image]="designers.img_1"
  ></div>
  <div class="txtCenter" *ngIf="designers.designers2019">
    <div
      class="p20 underline"
      [ngClass]="designers.box3.title.style"
      [innerHTML]="
        languageService.stringsResource[designers.box3.title.content]
      "
      [appEditableText]="editingMode"
      [editRoute]="'box3.title'"
      [element]="designers.box3.title"
    ></div>
  </div>
  <div
    class="ff-space"
    style="flex-direction: row; flex-wrap: wrap;"
    *ngIf="designers.designers2019"
  >
    <div
      class="ff"
      *ngFor="let designer of designers.designers2019; let i = index"
      (click)="navigate('designer/2019/' + designer.index)"
    >
      <div class="image-container">
        <img [src]="imagesService.imagesResource[designer.img.src]" alt="" />
        <!-- <div class="ctn" [ngStyle]="{'background-image': 'Url(' + imagesService.imagesResource[designer.img.src]+ ')'}" [class]="'designers2019-'+i" style="position: absolute; min-width: 100%;   background-size: cover;
        background-position: center;">
        </div> -->
      </div>
      <div class="name-space">{{ designer.name.toUpperCase() }}</div>
    </div>
  </div>

  <div class="txtCenter" *ngIf="designers.designers">
    <div
      class=" underline"
      [ngClass]="designers.box2.title.style"
      [innerHTML]="
        languageService.stringsResource[designers.box2.title.content]
      "
      [appEditableText]="editingMode"
      [editRoute]="'box2.title'"
      [element]="designers.box2.title"
    ></div>
  </div>
  <div
    class="ff-space"
    style="flex-direction: row; flex-wrap: wrap;"
    *ngIf="designers.designers"
  >
    <div
      class="ff"
      *ngFor="let designer of designers.designers; let i = index"
      (click)="navigate('designer/2018/' + designer.index)"
    >
      <div class="image-container">
        <img [src]="imagesService.imagesResource[designer.img.src]" alt="" />
      </div>
      <div class="name-space">{{ designer.name }}</div>
    </div>
  </div>
  <div class="txtCenter">
    <div
      class="underline"
      [class.p20]="!designers.designers"
      [ngClass]="designers.box1.title.style"
      [innerHTML]="
        languageService.stringsResource[designers.box1.title.content]
      "
      [appEditableText]="editingMode"
      [editRoute]="'box1.title'"
      [element]="designers.box1.title"
    ></div>
  </div>
  <div class="ff-space" style="flex-direction: row; flex-wrap: wrap;">
    <div
      class="ff clickable"
      *ngFor="let old of designers.old; let i = index"
      (click)="navigate('designer/o/' + old.index)"
    >
      <div class="image-container">
        <img [src]="imagesService.imagesResource[old.img.src]" alt="" />
      </div>
      <div class="name-space">{{ old.name }}</div>
    </div>
  </div>
</div>
