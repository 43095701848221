import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';

import { AngularFireDatabase } from '@angular/fire/database';
import { LanguageService } from '../../language.service';
import { NavbarService } from '../../navbar.service';
import { PostsService } from '../../posts.service';

@Component({
  selector: 'app-blog-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})
export class BlogListComponent implements OnInit, OnDestroy {

  loading = true;
  posts: Array<any>;
  currentLoad = 0;

  category = 'all';

  constructor(private route: ActivatedRoute, private router: Router, private db: AngularFireDatabase,
    private postsService: PostsService, private navbarService: NavbarService, private languageService: LanguageService) {
    // this.languageService.notifier.subscribe((lang) => {
    //   if (lang != null) {
    //     this.loadData();
    //   }
    // });
  }

  ngOnInit() {
    this.navbarService.white.next(true);

    this.route.paramMap.subscribe((path) => {
      this.category = path.get('category');
      this.loadData();
    });

  }

  ngOnDestroy() {
    this.navbarService.white.next(false);
  }

  loadData() {
    this.currentLoad = this.currentLoad + 20;
    this.loading = true;

    if (this.category == null || this.category === '' || this.category === 'all') {
      // Load all posts
      this.postsService.getAllPosts(this.languageService.currentLanguage, (posts) => {
        this.posts = posts;
        this.loading = false;
      }, this.currentLoad);
    } else {
      const categoryNumber = Number(this.category);
      if (categoryNumber != null && !isNaN(categoryNumber)) {
        this.postsService.getPostsCategory(this.languageService.currentLanguage, categoryNumber, (posts, _) => {

          this.posts = posts;
          this.loading = false;
        }, this.currentLoad);
      } else {
        this.router.navigateByUrl('/404');
      }
    }
  }

}
