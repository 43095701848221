import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireDatabase } from '@angular/fire/database';
import { LanguageService } from '../language.service';
import { ImagesService } from '../images.service';
import { DomSanitizer } from '@angular/platform-browser';
import { take, takeWhile } from 'rxjs/operators';
import { interval } from 'rxjs';

@Component({
  selector: 'app-gallery-2017',
  templateUrl: './gallery2017.component.html',
  styleUrls: ['./gallery2017.component.css']
})
export class Gallery2017Component implements OnInit {

  headerLoading = true;
  gallery: any;

  rulesAdded = {};

  constructor(public imagesService: ImagesService, public languageService: LanguageService,
    private router: Router, private db: AngularFireDatabase, private sanitizer: DomSanitizer) {
  }

  ngOnInit() {
    this.db.object('components/gallery2017').valueChanges()
    .pipe(take(1)).subscribe(g => {
        this.gallery = g;
        this.initialLoad();
    });
  }

  initialLoad() {
    interval(100)
      .pipe(takeWhile(() => this.headerLoading))
      .subscribe(i => {
        if (this.imagesService.loading) { return; }
        this.imagesService.loadHeader(this.imagesService.imagesResource[this.gallery.img_1.src]);
        this.headerLoading = false;
      });
  }

  subGalleryImage(gal) {
    return this.sanitizer.bypassSecurityTrustStyle(`url('${gal.img}')`);
  }

  navigate(link: string) {
    this.router.navigateByUrl(link);
  }
}
