import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class NavbarService {

  public noNav: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public white: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor() { }

}
