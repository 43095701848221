import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { AngularFireDatabase } from '@angular/fire/database';
import { BehaviorSubject } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { FixedNavbarService } from '../fixed-navbar.service';
import { ImagesService } from '../images.service';
import { LanguageService } from '../language.service';
import { NavbarService } from '../navbar.service';
import { Router } from '@angular/router';
import { interval } from 'rxjs';
import { takeWhile } from 'rxjs/operators';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.css'],
})
export class EventsComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() editingMode: boolean;
  @Input() editablePage;
  @Input() uiChanges: BehaviorSubject<boolean>;
  @Input() pause: boolean;
  events: any;
  loading = true;
  imagesLoading = true;
  selectedIndex = 0;

  constructor(
    public languageService: LanguageService,
    public imagesService: ImagesService,
    private db: AngularFireDatabase,
    private router: Router,
    private fixedNav: FixedNavbarService,
    private navSvc: NavbarService,
    private sanitazer: DomSanitizer
  ) { }

  ngOnInit() {
    if (this.editablePage != null) {
      this.events = this.editablePage;
    } else {
      const promisse = this.db.object('pages/events').valueChanges();
      promisse.subscribe(raw => {
        this.loading = false;
        this.events = raw;
      });
    }
    this.fixedNav.navbarAbsouluteSubject.next(true);
    this.navSvc.white.next(false);
  }

  ngOnDestroy() {
    this.fixedNav.navbarAbsouluteSubject.next(false);
    this.navSvc.white.next(false);
  }

  ngAfterViewInit() {
    interval(100)
      .pipe(takeWhile(() => this.imagesLoading))
      .subscribe(i => {
        if (this.imagesService.loading || this.loading) {
          return;
        }
        this.loadImages();
        this.imagesLoading = false;
      });
    if (this.editingMode) {
      this.uiChanges.subscribe(changes => {
        if (changes && this.editingMode) {
          this.loadImages();
        }
      });
    }
  }
  loadSanitize(src: string) {
    if (src.startsWith('https://firebasestorage')) {
      return this.sanitazer.bypassSecurityTrustResourceUrl(src);
    } else {
      return this.sanitazer.bypassSecurityTrustResourceUrl(this.imagesService.imagesResource[src]);
    }
  }

  loadImages() {
    const sheet = window.document.styleSheets[0] as CSSStyleSheet;
    if (sheet.cssRules) {
      for (let i = 0; i < sheet.cssRules.length; i++) {
        if (sheet.cssRules[i].cssText.includes('.bgImg')) {
          sheet.deleteRule(i);
        }
      }
    } else {
      for (let i = 0; i < sheet.rules.length; i++) {
        if ((sheet.rules[i] as any).includes('.bgImg')) {
          sheet.removeRule(i);
        }
      }
    }
    if (sheet.cssRules) {
      /* eslint-disable max-len */
      sheet.insertRule(
        '.bgImg { background: url(\'' +
        this.imagesService.imagesResource[this.events.img_1.src] +
        '\') no-repeat center center fixed; -webkit-background-size: cover; -moz-background-size: cover; -o-background-size: cover; background-size: cover; }'
      );
      this.events['slider'].forEach((element, index) => {
        sheet.insertRule(
          '.bgImgSl' +
          index +
          '{ background: url(\'' +
          this.imagesService.imagesResource[element.src] +
          '\') no-repeat center center; -webkit-background-size: cover; -moz-background-size: cover; -o-background-size: cover; background-size: cover; }'
        );
      });
    } else {
      // eslint-disable-next-line max-len
      sheet.addRule(
        '.bgImg { background: url(\'' +
        this.imagesService.imagesResource[this.events.img_1.src] +
        '\') no-repeat center center fixed; -webkit-background-size: cover; -moz-background-size: cover; -o-background-size: cover; background-size: cover; }'
      );
      this.events['slider'].forEach((element, index) => {
        sheet.insertRule(
          '.bgImgSl' +
          index +
          '{ background: url(\'' +
          this.imagesService.imagesResource[element.src] +
          '\') no-repeat center center; -webkit-background-size: cover; -moz-background-size: cover; -o-background-size: cover; background-size: cover; }'
        );
      });
    }
  }

  goIndexNavigator(index) {
    this.selectedIndex = index;
  }

  navigate(path: string) {
    if (path != null) {
      if (path.indexOf('http') > -1) {
        // window.location.href = path;
        window.open(path);
      } else {
        this.router.navigateByUrl('/' + path);
      }
    }
  }
}
