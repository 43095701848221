<app-loading *ngIf="loading || imagesLoading"></app-loading>
<div *ngIf="!loading && !imagesLoading">
  <div class="section bgImg" [appEditableImage]="editingMode" [editRoute]="'img_1'" [image]="notfound.img_1" [position]="'translate(-50%, -1000%)'">
  </div>
  <div class="box1">
    <div class="title1" [innerHTML]="languageService.stringsResource[notfound.title.content]" [appEditableText]="editingMode"
      [editRoute]="'title'" [element]="notfound.title" [negative]="true">
    </div>
    <div class="division"></div>
    <div class="txt1" [innerHTML]="languageService.stringsResource[notfound.body.content]" [appEditableText]="editingMode"
      [editRoute]="'body'" [element]="notfound.body" [negative]="true">
    </div>
  </div>
</div>