import { Component, OnInit, Input, AfterViewInit, OnDestroy } from '@angular/core';
import { LanguageService } from '../../language.service';
import { AngularFireDatabase } from '@angular/fire/database';
import { ImagesService } from '../../images.service';
import { BehaviorSubject } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { NavbarService } from '../../navbar.service';
import { FixedNavbarService } from '../../fixed-navbar.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-designers-profile',
  templateUrl: './designers-profile.component.html',
  styleUrls: ['./designers-profile.component.css'],
})
export class DesignersProfileComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() editingMode: boolean;
  @Input() editablePage;
  @Input() uiChanges: BehaviorSubject<boolean>;

  designer: any;
  loading = true;
  imagesLoading = true;

  designerID = -1;
  year = '';
  trueRoute = '';

  constructor(
    public languageService: LanguageService,
    public imagesService: ImagesService,
    private db: AngularFireDatabase,
    private router: Router,
    private navSvc: NavbarService,
    private fixedNav: FixedNavbarService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    if (this.editablePage != null) {
      this.designer = this.editablePage;
      this.loading = false;
    } else {
      this.route.paramMap.subscribe(params => {
        this.designerID = Number(params.get('id'));
        this.year = params.get('year');
        let designerRoute = '';
        if (this.year === '2018') {
          designerRoute = '/designers/' + this.designerID;
          this.trueRoute = 'designers';
        }
        if (this.year === '2019') {
          designerRoute = '/designers2019/' + this.designerID;
          this.trueRoute = 'designers2019';
        }
        if (this.year === 'old') {
          this.year = 'old';
          this.trueRoute = 'old';
          designerRoute = '/old/' + this.designerID;
        }
        const promisse = this.db.object('pages/designers' + designerRoute).valueChanges();
        promisse.subscribe(designer => {
          this.designer = designer;
          if (designer === undefined) {
            this.router.navigateByUrl('not-found');
          } else {
            this.loading = false;
          }
        });
      });
    }
    this.navSvc.white.next(true);
    this.fixedNav.navbarAbsouluteSubject.next(true);
  }

  ngOnDestroy() {
    this.navSvc.white.next(false);
    this.fixedNav.navbarAbsouluteSubject.next(false);
  }

  ngAfterViewInit() {}

  navigate(path: string) {
    if (path != null) {
      if (path.indexOf('http') > -1) {
        // window.location.href = path;
        window.open(path);
      } else {
        if (this.editingMode) {
          this.router.navigateByUrl('/dashboard/' + path);
        } else {
          this.router.navigateByUrl('/' + path);
        }
      }
    }
  }

  nextDesigner() {
    this.loading = true;
    this.db
      .list('pages/designers/' + this.trueRoute)
      .valueChanges()
      .pipe(take(1))
      .subscribe(_ => {
        if (_.length > this.designerID + 1) {
          this.navigate(`designer/${this.year}/${this.designerID + 1}`);
        } else {
          this.navigate(`designer/${this.year}/0`);
        }
      });
  }
}
